<template>
<vue-modal no-close="true" ref="modal">
  <template v-slot:header>
    <div class="p-3 bg-dark fw-600 text-white text-center">
      Safer Gambling
    </div>
  </template>
  <template v-slot:default>
    <div v-if="loading">
      <div class="loading-shade"></div>
      <pre-loader></pre-loader>
    </div>

    <template v-if="!showResult">
      <div class="container-survey">
        <div><span class="text-capitilize">{{ userName }}</span>, we would like you to answer the following questionnaire before resuming your account activity</div>
        <div class="survey-item title">
          <div></div>
          <div v-for="(opt) in answerOptions" :key="opt" :class="{'form-field-error': false}" >
              {{ opt }}
          </div>
        </div>

        <!-- Question 1 -->
        <div class="survey-item">
          <div class="lbl-required">Are you comfortable with your general betting levels?</div>
          <div v-for="(opt) in answerOptions" :key="opt" :class="{'form-field-error': false}" >
            <label>
              <input class="radio geoff-radio" type="radio" :value="opt" v-model="model.answer1">
            </label>
          </div>
        </div>

        <!-- Question 2 -->
        <div class="survey-item">
          <div class="lbl-required">Have you considered using any of the Responsible Gambling tools available on our website?</div>
          <div v-for="(opt) in answerOptions" :key="opt" :class="{'form-field-error': false}" >
            <label>
              <input class="radio geoff-radio" type="radio" :value="opt" v-model="model.answer2">
            </label>
          </div>
        </div>

        <!-- Question 3 -->
        <div class="survey-item">
          <div class="lbl-required">Do you feel you could benefit from the responsible gambling tools on our website to research adjusting your deposit limit or taking time out from betting?</div>
          <div v-for="(opt) in answerOptions" :key="opt" :class="{'form-field-error': false}" >
            <label>
              <input class="radio geoff-radio" type="radio" :value="opt" v-model="model.answer3">
            </label>
          </div>
        </div>

        <!-- Question 4 -->
        <div class="survey-item">
          <div class="lbl-required">Does your gambling cause you stress related issues?</div>
          <div v-for="(opt) in answerOptions" :key="opt" :class="{'form-field-error': false}" >
            <label>
              <input class="radio geoff-radio" type="radio" :value="opt" v-model="model.answer4">
            </label>
          </div>
        </div>

      </div>
      <vue-button 
        type="button"
        @click="submitForm"
        :disabled="!isAllAnswered"
        :cls="isAllAnswered ? 'main': 'disable'">Confirm</vue-button>
    </template>

    <template v-else>
      <!-- RESULT -->
      
      <div v-if="isResult1" class="container-survey">
        <div class="result">
          Thank you for your time with this. Given your responses, we would recommend reducing your net deposit limit with us. A member of our compliance team will also be in touch shortly to discuss your activity, so please look out for our call.
        </div>
        <vue-button 
        type="button"
        @click="goToDeposit"
        cls="main">Reduce Deposit Limit</vue-button>
      </div>
      <div v-else class="container-survey">
        <div class="result">
    
          Thank you for your time with this. If you ever need to talk about your activity, we're always available for a chat on 01344 873334 or email us at <a class="geoff-link-accent" href="mailto:support@geoffbanks.bet">support@geoffbanks.bet</a>. Additionally, visit our <a class="geoff-link-accent" href="javascript://" @click="goToResponsibleGambling" >Responsible Gambling page</a> for tools and advice.
        </div>
        <vue-button 
          type="button"
          @click="close"
          cls="main">OK</vue-button>
      </div>
    </template>

    
    
  </template>
</vue-modal>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/UI/PreLoader";
import VueModal from "@/components/UI/VueModal";
import VueButton from "@/components/UI/VueButton";
export default {
  name: "ComplianceSurveyModal",
  components: {
    VueModal,
    VueButton,
    PreLoader
  },
  data: () => {
    return {
      loading: false,
      showResult: false,
      answerOptions: ['Yes', 'No'],
      model: {
        answer1: null,
        answer2: null,
        answer3: null,
        answer4: null
      }
    }
  },
  computed: {
    ...mapGetters(['USER']),
    userName() {
      return `${this.USER?.first_name} ${this.USER?.last_name}`
    },
    isAllAnswered() {
      return !!this.model.answer1 && !!this.model.answer2 && !!this.model.answer3 && !!this.model.answer4
    },
  
    isResult1() {
      if (this.showResult) {
        return this.model.answer1 === 'No'&&
               this.model.answer4 === 'Yes'
      }
      else {
        return false
      }
    }
  },
  watch: {
    'USER.isCompliancePopup'() {
      this.initPopupState()
    }
  },
  mounted() {
    this.initPopupState()
  },
  methods:{

    initPopupState() {
      if (this.USER?.isCompliancePopup) {
        this.$refs.modal.open()
      }
   
    },
    submitForm() {
      this.loading = true;
      const model = {
        BettingLevel: this.model.answer1 === 'Yes', // Are you comfortable with your general betting levels?
        ResponsibleGamblingToolUsing: this.model.answer2 === 'Yes', // Have you, in the past, used any of the Responsible Gambling Tools available on our website?
        ResponsibleGamblingToolBenefit: this.model.answer3 === 'Yes', // Do you feel you could benefit from the responsible gambling tools on our website to research adjusting your deposit limit or taking time out from betting?
        StressRelatedIssue: this.model.answer4 === 'Yes', // Does your gambling cause you stress related issues?
      }

      this.API.postComplianceForm(model)
        .then(resp => {
          console.log('postComplianceForm RESP', resp)
          this.loading = false;
          this.showResult = true
        })
        .catch(er => {
          console.log('postComplianceForm ERROR', er)
          this.loading = false;
        })
        .finally(() => {
          //reload user info
          const authData = JSON.parse(localStorage.getItem('userCreds'))
           this.API.getUserInfo(authData)
              .then(res=>{
              this.$store.dispatch('SET_USER_DATA', {
                    email : res.emailAddress,
                    limit : res.telephoneNumber,
                    workPhone : res.work_Tel,
                    phone : res.mobile,
                    address1 : res.address1,
                    address2 : res.address2,
                    address3 : res.address3,
                    city : res.address4,
                    country : res.country,
                    zip : res.postCode,
                    userInfoLoaded: true,
                    firstName: res.firstName,
                    lastName: res.lastName,
                    isCompliancePopup: res.isCompliancePopup
                  })

              })
              .catch(er => {
                console.log('DEBUG //reload user info is FAILED', er)
              })

        })
    },
    close() {
      this.$refs.modal.close()
    },
    goToDeposit() {
      this.$router.push({name: 'updateDepositLimit'})
      this.$refs.modal.close()
    },
    goToResponsibleGambling() {
      this.$router.push( {name:'info', params: { infoType: 'ResponsibleGambling' }})
      this.$refs.modal.close()
    }

  }
}
</script>

<style scoped lang="scss">
.content{
  max-width: 400px;
}

.container-survey {
  margin: 1rem 0;
  max-width: 60vw;
  @media only screen and (max-width: 1024px){
    max-width: unset;
  }

  .survey-item {
    display: grid;
    grid-template-columns: 80% 1fr 1fr;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;

    >div:first-child {
      text-align: left;
    }

    &.title {
      text-transform: uppercase;
    }
  }

  .result {
    margin-bottom: 1rem;
  }
}
</style>