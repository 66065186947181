<template>
<div class="info fw-600">
  <slot></slot>
</div>
</template>

<script>
export default {
  name: "InfoIcon"
}
</script>

<style scoped>
.info{
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FD7760;
  color: #FFFFFF;
  border-radius: 3px;
  padding: 1px 3px;
  width: max-content;
  height: max-content;
  line-height: 16px;
}
</style>