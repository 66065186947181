<template>
<div v-if="$store.state.isMobile" class="header py-2">
  <div class="d-flex justify-content-between align-items-center w-100 h-100">
    <div
        @click="$router.push({name: 'home'})"
        class="header_logo ms-3 cursor-pointer"
        :style="{backgroundImage: 'url('+require('@/assets/logo.png')+')'}"
    ></div>
    
    <!-- <div
        v-if="['home', 'sports'].includes($route.name)"
        @click="$router.push({name: 'home'})"
        class="header_logo ms-3 cursor-pointer"
        :style="{backgroundImage: 'url('+require('@/assets/logo.png')+')'}"
    ></div>
    <div
      v-else
      class="d-flex justify-content-center align-items-center p-3 cursor-pointer"
      @click="$router.back()"
    >
      <img class="" :src="require('@/assets/to-left-arrow.svg')">
    </div> -->
    <div class="header_buttons">
      <div class="d-flex h-100">
        <div v-if="!$store.state.user.cust_Ref" class="me-3 d-flex cursor-pointer">
          <vue-button sm cls="head" @click="$router.push({name: 'signup'})" class="me-2">Register</vue-button>
          <vue-button sm cls="head" @click="loginLogut" class="text-white">Login</vue-button>
        </div>
        <div v-else @click="$router.push({name: 'account'})" class="d-flex align-items-center me-2 cursor-pointer">
          <div class="text-end text-white fs-12 me-2">
            <div class="fs-14">{{ $store.state.user.cust_Ref }}</div>
            <div class="text-white-50 text-capitalize">{{ $store.state.user.first_name }}</div>
          </div>
          <div class="avatar" :style="{backgroundImage: 'url('+ require('@/assets/img/abstract-user-flat-3.svg') +')'}"></div>
        </div>
      </div>
    </div>
  </div>
  <div

      v-if="$store.state.user.funddetail"
      class="position-absolute start-50 top-50 translate-middle text-center cursor-pointer"
  >
      <div class="fs-10 text-secondary text-uppercase">Funding</div>
      <div class="d-flex justify-content-between align-items-center">
        <div
          class="text-white f-roboto"
          @click="$router.push({name:'deposit'})"
        >{{ $store.state.user.funddetail.balance }}</div>
        <refresh-balance class="p-1 ms-2" icon dark/>
      </div>
  </div>
</div>
<div v-else class="header desktop-header py-2">
  <div class="d-flex justify-content-between align-items-center w-100 h-100">
    <div class="d-flex align-items-center links-wr">
      <div
          @click="$router.push({name: 'home'})"
          class="header_logo header_logo-desktop cursor-pointer mx-4"
          :style="{backgroundImage: 'url('+require('@/assets/logo-text.png')+')'}"
      ></div>
      <!-- <a href="#" class="navigate fw-400 mx-4 ms-5">Home</a> -->
      <!-- <a href="/#/info/Affiliates" class="navigate fw-400 mx-4">Affiliates</a> -->
      <!-- <a href="/#/promos" class="navigate">Promotions</a> -->

      <div class="links-container">
        <router-link class="navigate fw-400 mx-4 ms-5" :to="{name:'home'}">Home</router-link>
        <router-link class="navigate" :to="{name:'promos'}">Promotions</router-link>
        <!-- <a v-for="(linkItem, idx) in API.TOP_MENU_LINKS" :href="linkItem.link" :key="idx" class="navigate">{{ linkItem.name }}</a> -->
      </div>

    </div>
    <div class="user-wr d-flex me-4">
      <template v-if="!$store.state.authToken">
        <div v-if="!$store.state.authToken" class="me-3 d-flex cursor-pointer">
          <vue-button sm cls="head" @click="$router.push({name: 'signup'})" class="me-2">Register</vue-button>
          <vue-button sm cls="head" @click="$refs.loginModal.open()" class="text-white">Login</vue-button>
        </div>
      </template>
      <template v-else>
        <div class="logged-wr">
            <!-- DEPOSIT -->
            <!-- <div class="action-link-item" @click="$router.push({name: 'myActivity'})">
              <div class="link-lbl"> My Activity </div>
              <img class="link-icon" :src="require('@/assets/img/account/chart-1.svg')"/>
            </div>

            <div class="action-link-item" @click="$router.push({name: 'deposit'})">
              <div class="link-lbl"> Deposit </div>
              <img class="link-icon" :src="require('@/assets/img/account/card-tick.svg')"/>
            </div>


            <div class="d-flex">
              <vue-button cls="dark" @click="$router.push({name: 'deposit'})" sm >Deposit</vue-button>
            </div> -->
           
            <!-- balance -->
            <!-- <div>
              <div
                @click="$router.push({name: 'transactions'})"
                class="user-balance f-roboto">
                {{ $store.state.user?.funddetail?.balance }}
              </div>
              <div>
                <refresh-balance class="refresh-btn" icon dark/>
              </div>
            </div> -->
             <!-- balance -->
            <div class="balance-wr">
              <div
                @click="$router.push({name: 'transactions'})"
                class="user-balance f-roboto">
                {{ $store.state.user?.funddetail?.balance }}
              </div>
              <div class="d-flex align-items-center">
                <refresh-balance class="refresh-btn" icon dark/>
              </div>
            </div>
              
            <!-- account avatar + name -->
            <div class="account-info-wr expand-menu-container" @click="$router.push({name: 'account'})">
              <div class="avatar" :style="{backgroundImage: 'url('+ require('@/assets/img/abstract-user-flat-3.svg') +')'}"></div>
            
              <div class="account-name" >
                <div>{{ $store.state.user.cust_Ref }}</div>
                <div class=" text-white-50 text-uppercase">{{ $store.state.user.first_name }}</div>
              </div>

              <div class="expand-menu" @click.stop="">
                <div class="link-list">
                  <div class="action-link-item" @click="$router.push({name: 'myActivity'})">
                    <img class="link-icon" :src="require('@/assets/img/account/chart-1.svg')"/>
                    <div class="link-lbl"> My Activity </div>
                  </div>

                  <div class="action-link-item" @click="$router.push({name: 'transactions'})">
                    <img class="link-icon" :src="require('@/assets/img/account/document-text.svg')"/>
                    <div class="link-lbl"> My Bets </div>
                  </div>
                

                  <div class="action-link-item" @click="$router.push({name: 'deposit'})">
                    <img class="link-icon" :src="require('@/assets/img/account/card-tick.svg')"/>
                    <div class="link-lbl"> Deposit </div>
                  </div>

                  <hr/>
                  <div class="action-link-item " @click="$store.dispatch('LOGOUT')">
                    <img class="link-icon" :src="require('@/assets/img/account/logout.svg')"/>
                    <div class="link-lbl"> Log Out </div>
                  </div>
                </div>
                
              </div>
            </div>

            <!-- expand btn -->
            <!-- <div class="p-3 btn-expand-menu"> -->
              <!-- <img style="filter: invert()" :src="require('@/assets/img/arrow-down.svg')"/>
              <div class="expand-menu">
                <div class="link-list">
                  <div class="action-link-item" @click="$router.push({name: 'myActivity'})">
                    <img class="link-icon" :src="require('@/assets/img/account/chart-1.svg')"/>
                    <div class="link-lbl"> My Activity </div>
                  </div>

                  <div class="action-link-item" @click="$router.push({name: 'deposit'})">
                    <img class="link-icon" :src="require('@/assets/img/account/card-tick.svg')"/>
                    <div class="link-lbl"> Deposit </div>
                  </div>
                  <div class="action-link-item" @click="$store.dispatch('LOGOUT')">
                    <img class="link-icon" :src="require('@/assets/img/account/logout.svg')"/>
                    <div class="link-lbl"> Log out </div>
                  </div>
                </div>
                
              </div> -->
              <!-- <div class="bg-dark rounded-3 position-absolute end-0 d-none p-3">
                <vue-button cls="dark" class="mb-2"><router-link class="text-decoration-none" :to="{name:'account'}">My Account</router-link></vue-button>
                <vue-button cls="dark" class="" @click="$store.dispatch('LOGOUT')">Log Out</vue-button>
              </div> -->
            <!-- </div> -->
             
            <!-- expand btn -->
            <!-- <div class="p-3 btn-expand-menu">
              <img style="filter: invert()" :src="require('@/assets/img/arrow-down.svg')"/>
              <div class="bg-dark rounded-3 position-absolute end-0 d-none p-3">
                <vue-button cls="dark" class="mb-2"><router-link class="text-decoration-none" :to="{name:'account'}">My Account</router-link></vue-button>
                <vue-button cls="dark" class="" @click="$store.dispatch('LOGOUT')">Log Out</vue-button>
              </div>
            </div> -->
        </div>
      </template>
      <!-- <div v-if="$store.state.authToken" class="d-flex">
        <vue-button cls="dark" @click="$router.push({name: 'deposit'})" sm class="me-2">Deposit</vue-button>
      </div> -->
     
      <!-- <div class="d-flex h-100">
        <div v-if="!$store.state.authToken" class="me-3 d-flex cursor-pointer">
          <vue-button sm cls="head" @click="$router.push({name: 'signup'})" class="me-2">Register</vue-button>
          <vue-button sm cls="head" @click="$refs.loginModal.open()" class="text-white">Login</vue-button>
        </div>
        
        <div v-else  class="d-flex align-items-center me-2 cursor-pointer">

          <div class="avatar" :style="{backgroundImage: 'url('+ require('@/assets/img/abstract-user-flat-3.svg') +')'}"></div>
          <div class="text-end text-white fs-12 ms-3">
            <div class="fs-12">{{ $store.state.user.cust_Ref }}<span class="ms-3 text-white-50 text-uppercase">{{ $store.state.user.first_name }}</span></div>
            <div v-if="$store.state.user.funddetail" class="d-flex justify-content-between align-items-center">
              <refresh-balance class="px-2 mt-1 me-1" icon dark/>
              <div
                @click="$router.push({name: 'transactions'})"
                class="fs-18 mt-1 text-warning text-center f-roboto fw-700">
                {{ $store.state.user.funddetail.balance }}
              </div>
            </div>
          </div>
          <div class="ms-3 p-3 btn-expand-menu"><img style="filter: invert()" :src="require('@/assets/img/arrow-down.svg')"/>
            <div class="bg-dark rounded-3 position-absolute end-0 d-none p-3">
              <vue-button cls="dark" class="mb-2"><router-link class="text-decoration-none" :to="{name:'account'}">My Account</router-link></vue-button>
              <vue-button cls="dark" class="" @click="$store.dispatch('LOGOUT')">Log Out</vue-button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <teleport to="body">
  <back-claim v-if="0" ref="backClaimModal"/>
  <vue-modal ref="loginModal">
    <template v-slot:header>
      <div class="p-3 bg-dark">
        <div class="auth-logo bg-dark" :style="{backgroundImage: 'url('+require('@/assets/logo-text.png')+')'}"></div>
      </div>
    </template>
    <template v-slot:default>
      <log-in
        class="bg-layout"
        style="margin-top: 3rem"
        @close-modal="$refs.loginModal.close()"
      ></log-in>
    </template>
  </vue-modal>
  </teleport>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VueButton from "@/components/UI/VueButton";
import VueModal from "@/components/UI/VueModal";
import LogIn from "@/components/Auth/LogIn";
import BackClaim from "@/components/BackClaim";
import RefreshBalance from "@/components/Sevice/RefreshBalance";

export default {
  name: "MainHeader",
  components: {RefreshBalance, BackClaim, LogIn, VueModal, VueButton},
  computed:{
    ...mapGetters(['isAuthenticated'])
  },
  methods:{
    ...mapActions(['LOGOUT']),
    loginLogut(){
      if (this.isAuthenticated) {
        this.LOGOUT()
      } else {
        this.$router.push({name: 'login'})
      }
    }
  }
}
</script>

<style scoped lang="scss">
.header{
  position: fixed;
  height: 3.5rem;
  left: 0;
  right: 0;
  top: calc(0 + env(safe-area-inset-top));
  background-color: #09080C;
  z-index: 100;
}
.desktop-header{
  /* height: 5rem; */
  height: var(--geoff-header-height);
  z-index: 1100;
}
.header_logo{
  height: 2rem;
  width: 2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.header_logo-desktop{
  height: 3.125rem;
  width: 7rem;
  min-width: 7rem;
}
.header_buttons__search{
  height: 1.5rem;
  width: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.avatar{
  border-radius: 50%;
  min-height: 2.5rem;
  min-width: 2.5rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.navigate{
  text-decoration: none;
  color: white;
  color: var(--geoff-link-menu);
  font-size: 0.875rem;
  line-height: 1.1;
  text-transform: uppercase;
  /* margin: 0 1rem; */
}
.auth-logo{
  height: 3rem;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.btn-expand-menu{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #23232F;
  border-radius: 0.3rem;
}
.btn-expand-menu > div {
  max-height: 0;
  transition: max-height 0.2s ease-in;
}
.btn-expand-menu:hover > div {
  max-height: 100vh;
  top: 2.5rem;
  display: block!important;
}
a{
  color: inherit;
}
.links-wr {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-wr {
  min-width: max-content;
}

.links-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 1rem;
}

.logged-wr {
  display: flex;
  gap: 1rem;

  .balance-wr {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .user-balance {
    font-size: 1.125rem;
    color: var(--geoff-accent);
    font-weight: 700;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  // .fs-18 mt-1 text-warning text-center f-roboto fw-700
}

.refresh-btn {
  width: auto;
  height: auto;
  padding: 0.35rem;
  display: flex;
  align-items: center;
}

.account-info-wr {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;

  .account-name {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
  }
}


.action-link-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  .link-lbl {
    font-size: 0.825rem;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .link-icon {
    width: 1.125rem;
    height: 1.125rem;
    filter: invert(100%);
  }
}

.expand-menu-container {
  position: relative;
  > .expand-menu {
    max-height: 0;
    transition: max-height 0.2s ease-in;
  }

  &:hover > .expand-menu {
    max-height: 100vh;
    top: 2.5rem;
    right: -1.5rem;
    display: block!important;
  }

  .expand-menu {
    display: none;
    position: absolute;
    width: 10rem;
    background-color: #09080C;
    padding: 1rem;
    gap: 1rem;
    cursor: auto;

    .link-list {

      .action-link-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        margin-bottom: 0.75rem;

        &:last-child {
          margin-bottom: 0;
        }

        .link-lbl {
          font-size: 0.825rem;
          text-transform: uppercase;
        }
        .link-icon {
          width: 1.25rem;
          height: 1.25rem;
          filter: invert(100%);
        }
      }
    }
  }
}

</style>