<template>
<div class="bottom-menu">
  <div class="d-flex align-items-center justify-content-center">
    <div class="bottom-menu-item mx-2" @click="$router.push({name: 'home'})" :class="{'active': $route.name === 'home'}">
<!--      <img class="bottom-menu-item__icon" :src="require('../assets/bottom-menu/home.svg')">-->
      <div class="bottom-menu-item__icon" :style="{backgroundImage: 'url(' + require('../assets/bottom-menu/home.svg') + ')'}"></div>
      <div class="bottom-menu-item__title">Home</div>
    </div>
    <div class="bottom-menu-item mx-2" @click="$router.push({name: 'sports'})" :class="{'active': $route.name === 'sports'}">
      <div class="bottom-menu-item__icon" :style="{backgroundImage: 'url(' + require('../assets/bottom-menu/sports.svg') + ')'}"></div>
      <div class="bottom-menu-item__title">Sports</div>
    </div>

    <div class="bottom-menu-item mx-2">
      <div class=" gradient-box" :key="BETSLIP.bets.length">
        <div class=""   @click="$refs.betslip.open()">
          <div class="bottom-menu-item__icon position-relative" :style="{backgroundImage: 'url(' + require('../assets/bottom-menu/betslip.svg') + ')'}">
            <div v-if="Array.isArray(BETSLIP.bets)">
              <div v-if="BETSLIP.bets.length" class="betslip-length">
                <div>{{ BETSLIP.bets.length }}</div>
              </div>
            </div>
          </div>
          <div class="bottom-menu-item__title m-0">Betslip</div>
        </div>
      </div>
    </div>

    <div class="bottom-menu-item mx-2" @click="$router.push({name: 'transactions'})" :class="{'active': $route.name === 'transactions'}">
      <div class="bottom-menu-item__icon" :style="{backgroundImage: 'url(' + require('../assets/bottom-menu/transactions.svg') + ')'}"></div>
      <div class="bottom-menu-item__title">Transactions</div>
    </div>
    <div class="bottom-menu-item mx-2" @click="$router.push({name: 'account'})" :class="{'active': $route.name === 'account'}">
      <div class="bottom-menu-item__icon" :style="{backgroundImage: 'url(' + require('../assets/bottom-menu/account.svg') + ')'}"></div>
      <div class="bottom-menu-item__title">Account</div>
    </div>
  </div>
</div>
<fly-in-anim ref="flyIn"></fly-in-anim>

<div
style="
  position: fixed;
  bottom: 0;
  left: 20%;
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  display: none;
  "
  class=". betslip-gradient"
>test</div>

<betslip-modal ref="betslip"></betslip-modal>
</template>

<script>
import BetslipModal from "@/components/BetslipModal";
import FlyInAnim from "@/components/FlyInAnim";
import {mapGetters} from "vuex";
export default {
  name: "BottomMenu",
  // components: {BetslipModal},
  components: {
    BetslipModal,
    FlyInAnim
  },
  computed:{
    ...mapGetters(['BETSLIP'])
  },
  data() {
    return {
      showFlyInd: false,
      width: 0,
      height: 0,
      top: 0,
      right: 0
    }
  },
  // watch: {
  //   '$store.state.betslipStore.betslip.bets'(newVal, oldVal){
  //     console.log('DEBUG this.BETSLIP.bets.length', newVal, oldVal)
  //   }
  // },
  mounted(){
    this.emitter.on("odd-btn-click", this.flyinAnimation);
  },
  unmounted () {
    this.emitter.off("odd-btn-click", this.flyinAnimation);
  },
  methods: {
    flyinAnimation (cord) {
      this.$refs.flyIn.play(cord)
    }
  }
}
</script>

<style scoped>
.bottom-menu{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(3.5rem + var(--ion-safe-area-bottom));
  background-color: #09080C;
  color: rgb(83, 9, 9);
  z-index: 1000
}
.bottom-menu-item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  /* height: 2.5rem; */
}
.bottom-menu-item:hover{
  cursor: pointer;
}
.bottom-menu-item__icon{
  height: 1.5rem;
  width: 2rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.bottom-menu-item__title{
  text-align: center;
  margin-top: 0.25rem;
  color: white;
  font-size: 0.625rem;
}
.active .bottom-menu-item__icon{
  filter:invert(51%) sepia(93%) saturate(1300%) hue-rotate(13deg) brightness(145%) contrast(119%);
}
.active .bottom-menu-item__title{
  color: #FFD60A;
}
.betslip-length{
  height: 1rem;
  width: 1rem;
  background-color: #debd16;
  border-radius: 50%;
  color: #000000;
  font-size: 0.5rem;
  border: solid 2px #000000;

  position: absolute;
  top: -0.3rem;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>