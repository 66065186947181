<template>
  <div
    :class="$store.state.isMobile?'content':'content-desktop rounded-3'"
    class="info-best-odds-guaranteed  geoff-bg-section p-n"
  >
      <info-icon
        class="fs-12"
        data-toggle="tooltip"
        data-placement="top"
        title="Best odds guaranteed"
      >BOG</info-icon>
      <div><b>Best Odds Guaranteed</b></div> 

      <div 
        @click.stop="toggleExpand"
        class="mx-2 collapse-btn-wr d-flex align-items-center desk-c-pointer"
      >
        <!-- arrow-down.svg -->
        <svg  class="collapse-btn" :class="{'reverse':expanded}" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 5.72376L10.862 0.861827L11.8048 1.80464L6.00005 7.60937L0.195312 1.80464L1.13812 0.861828L6.00005 5.72376Z" fill="currentColor"/>
        </svg>
      </div>

      <collapse-transition :duration="200">
        <div class="info-description" v-if="expanded">
          With Best Odds Guaranteed on all UK & Irish Racing we promise to give our clients the best value possible. 
          <br/>
          You back a horse at 5/1 and your selection returns at 7/1, we will pay out at 7/1! T&C Apply.
          <span 
            v-if="bogInfoPath"
            @click="moreInfoClick"
            class="button desk-c-pointer"
          >More Info</span>
        </div>
      </collapse-transition> 
  </div>
</template>

<script>
  import InfoIcon from "@/components/UI/InfoIcon";
  import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'

  export default {
    name: "BestOddsGuaranteedInfo",
    components: {
      InfoIcon,
      CollapseTransition
    },
    props: {
      bogInfoPath: {
        type: String,
        default: ''
      },
    },
    data: () => {
      return {
        expanded: false
      }
    },
    methods: {
      toggleExpand() {
        this.expanded = !this.expanded
      },
      moreInfoClick() {
        if (this.bogInfoPath) {
          this.$router.push({name: 'promo', params: {page: this.bogInfoPath}})
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .info-best-odds-guaranteed {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 0.5rem 0.75rem;
    align-items: center;

    .info-description {
      font-size: 0.875rem;
      line-height: 1.25;
      grid-column: 2 / span 1;
      display: grid;
      grid-gap: 0.25rem 1rem;
      grid-template-columns: auto;

      @media screen and (min-width: 769px) {
        grid-template-columns: auto 1fr;
      }
    }


    .button {
      align-self: flex-end;
      justify-self: flex-end;
      line-height: 1.5rem;
      padding: 0.35rem 1.15rem;
      border-radius: 0.25rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      width: max-content;
      white-space: nowrap;
      color: var(--geoff-txt-main);
      background: var(--geoff-bg-active);

      @media screen and (min-width: 769px) {
        justify-self: center;
        // justify-self: flex-start;
      }
    }


  }

</style>