<template>
<vue-button
  :cls="dark?'dark':'disable'"
  sm
  class="w-auto"
  @click="refreshBalance"
>
  <span v-if="!icon" class="fs-15 me-2">Refresh Balance</span>
  <img :class="{'loading': loading, 'white-icon': dark, 'small': icon}" :src="require('@/assets/refresh.svg')"/>
</vue-button>
</template>

<script>
import VueButton from "@/components/UI/VueButton";
export default {
  name: "RefreshBalance",
  components: {VueButton},
  props:{
    icon:{
      type: Boolean,
      default: false
    },
    dark:{
      type: Boolean,
      default: false
    }
  },
  data: ()=>{
    return{
      loading: false
    }
  },
  methods:{
    refreshBalance(){
      const vm = this
      vm.loading = true
      vm.$store.dispatch('UPDATE_USER_DATA')
      .then(()=>vm.loading = false)
    }
  }
}
</script>

<style scoped>
.white-icon{
  filter: invert(1);
}
.small{
  height: 1rem;
}
.loading{
  animation: rotate 0.5s linear infinite;
}
@keyframes rotate {
  100%{
    transform: rotate(360deg);
  }
}
</style>