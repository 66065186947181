<template>
<div class="live-events-section d-flex overflow-hidden" :class="{'rounded-3': !$store.state.isMobile}">
  <div class="overflow-hidden">
    <div class="text-start">Highlights</div> <!--px-4 py-2 fw-700 -->
    <div class="geoff-border-bottom"></div>
    <scroll-tabs-bar
      :items="sportsList.map(sport=>sport.title)"
      :selected-item="currentSport.title"
      @selectitem="selectSport"
    ></scroll-tabs-bar>
    <div class="geoff-border-bottom"></div>
  </div>
  <div class="section-content">
    <div v-if="loading" class="content_wrap position-relative flex-fill"><pre-loader/></div>
    <div v-else-if="events.length>0" class="content_wrap">
        <!-- HEADER select -->
        <div class="geoff-bg-sectionXXX" :class="{'rounded-3XXX mb-2XXX': !$store.state.isMobile}">
          <div class="sub-header p-n grid-coupon-view"
            :class="colCountClass"
          >
          
            <div></div>
            <!-- single market -->
            <template v-if="availableMarketsOptions.length === 1">
              <div class="market-static">{{ defaultMarket }}</div>
            </template>

            <!-- few markets -->
            <template v-else>
              <div v-for="(col, idx) in columns" :key="idx">
                <select
                  :disabled="availableMarketsOptions.length <= 0"
                  v-model="columns[idx]" class="market-select" name="" id="">
                  <option
                    v-for="(item, midx) in availableMarketsOptions"
                    :key="midx"
                    :value="item">{{ item }}</option>
                </select>
              </div>
            </template>

          </div>
        </div>

        <!-- HEADER legend -->
        <div
          class=" fw-700 sub-header geoff-border-bottomXXX p-n grid-coupon-view geoff-bg-subsection"
          :class="colCountClass"
        >
          <div class="league-name"></div>
          <!-- cols legends -->
          <competitions-legend
            v-for="(colMarket, idx) in selectedMarkets"
            :key="idx"
            :market-name="colMarket.marketName"
            :market-type="colMarket.legendType"
            :market-legend="colMarket.legends"
          />
        </div> <!--casediff-->

        <!-- ITEMS -->
        <competitions-list-item
          class="geoff-border-bottom-separator"
          v-for="(event, index) in currentSportMatches"
          :key="index"
          :event="event"
          :sport="currentSport"
          :columns="selectedMarkets"
          showLeague
        ></competitions-list-item>
    </div>
    <no-events v-else/>
  </div>
</div>
</template>

<script>
import * as Utility from "@/http/utils"
import _ from 'underscore'
import ScrollTabsBar from "@/components/UI/ScrollTabsBar";
import CompetitionsListItem from "@/components/Sport/Competitions/CompetitionsListItem";
import CompetitionsLegend from "@/components/Sport/Competitions/CompetitionsLegend";
import NoEvents from "@/components/Sport/NoEvents";
import PreLoader from "@/components/UI/PreLoader";

export default {
name: "SportHighlights",
  emits: ['dataloaded'],
  components: {PreLoader, NoEvents, CompetitionsListItem, CompetitionsLegend, ScrollTabsBar},
  props:{

  },
  data: ()=>{
  return {
    sportsList: [],
    currentSport: {},
    loading: false,
    currentSportMatches: [],

    events: [],
    currentEvent: {},
    currentEventIndex: 0,
    showLess: true,
    isFirstLoad: "0",
    InPlayEventsToggle: null,
    mainMarket: '',
    defaultMarket: null, // market which is not in fullcard
    columns: [], // selected markets names
    availableMarkets: [], // option list to display in select in additional cols
  }
},
beforeMount() {
  this.sportsList = this.API.SPORTS.filter(sport=>sport.type==='competition' && sport.code)
  this.currentSport = this.API.SPORTS[4]
},
  mounted() {
  this.loadData()
},
watch:{
    currentSport(){
      this.loadData()
    }
},
computed: {
  colCountClass() {
    return `market-col-${this.columns.length} ${ this.$store.state.isMobile ? 'grid-coupone-view-mobile' : 'grid-coupone-view-desktop' }`
  },
  // string options for select
  availableMarketsOptions() {
    return this.availableMarkets.map(el => el.marketName)
  },
  selectedMarkets() {
    const selectedMarkets = this.columns
      .map(el => this.availableMarkets.find(m => el === m.marketName ))
    return selectedMarkets;
  },
  isGolf() {
    return this.currentSport?.code === 'm'
  }
},
methods:{
  loadData:function(){
    const vm = this
    vm.loading = true
    this.columns = []
    this.availableMarkets = []
    this.mainMarket = '',
    this.defaultMarket = null
    vm.API.getLiveEvents().then(responseresult => {
        vm.InPlayEventsToggle = responseresult
        vm.API.getEvents(this.currentSport.code)
          .then(resp => {
            let events = Utility.FilterEvents(resp.data);
            let eventDate = events;

            eventDate.map((data) => {
              data.competitions.map((data1) => { // casediff
                data1.details.map((data2) => {
                  data2.League = data.EventName || data.eventName // casediff
                  if (data2.firstPlayer != undefined) {
                    data2.homeTeam = data2.firstPlayer;
                    data2.awayTeam = data2.secondPlayer;
                    data2.homeodds = data2.firstodds;
                    data2.drawodds = data2.secondodds;
                    data2.awayodds = data2.thirdodds;
                    data2.kickoff = data2.time;
                    data2.code = data.EventCode || data.eventCode // casediff
                  }
                  vm.checkIsLiveMatch(data2);
                });
              });
            });

            vm.availableMarkets = resp.markets
            vm.defaultMarket =  vm.availableMarkets[0]?.marketName
            // sel selected options if there is no one:
            if (!vm.columns.length) {
              vm.columns = resp.markets.map(el => el.marketName).slice(0, 3)
            }
            if (vm.showLess) {
              eventDate = eventDate.slice(0, 3);
            }
            vm.events = eventDate
            vm.currentEvent = events[0]
            let allEvents = []
            eventDate.map(cat=>{
              let category = cat
              category.events = []
              cat.competitions.map(comp=>{ // casediff
                console.log()
                comp.details.map(ev=>{
                  let event = ev
                  event.fullCard = ev.fullCard.map( el => {
                    const parsed = this.API.ParseEventData(el)
                    return {
                      ...el,
                      fullCard: parsed
                    }
                  })
                  event.date = comp.date
                  event.Code = cat.Code || cat.code // casediff
                  event.code = cat.Code || cat.code // casediff
                  event.EventName = comp.EventName || comp.eventName // casediff
                  event.eventName = comp.EventName || comp.eventName // casediff
                  category.events.push(event)
                  allEvents.push(event)
                  return event
                })
              })
              return category
            })

            allEvents.sort((ev1, ev2)=>{
              let date1 = new Date(ev1.date.split('-').reverse().join('-'))
              let date2 = new Date(ev2.date.split('-').reverse().join('-'))
              if (date1 < date2) return -1
              if (date1 === date2) return 0
              if (date1 > date2) return 1
            })
            vm.currentSportMatches = allEvents
            vm.loading = false
            this.$emit('dataloaded')

          })
      });
  },
  checkIsLiveMatch(event) {
    _.find(this.InPlayEventsToggle, function(o) {
      event["isLive"] = false;

      if (o.event_id1 === event.eventId1) {
        event["isLive"] = true;
        event["id"] = o.id;
        // var toggle_old = "toggle-" + event.eventId1;
        // var livetoggle = "#" + toggle_old;
        return true;
      }
    });
  },
  selectSport(sportName){
    this.currentSport = this.sportsList.filter(s=>s.title===sportName)[0]
  },
  selectEvent(eventName){
    this.currentEvent = this.events.filter(e=>e.EventName === eventName)[0]
  }
}
}
</script>

<style scoped lang="scss">

.text-start {
  /* px-4 py-2 fw-700  */
  font-weight: 700;
  padding: 0 0.75rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
}
.content_wrap{
  min-height: 150px;
}
.live-events-section{
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--geoff-bg-section);
}
.section-content{
  position: relative;
  min-height: 10rem;
}

// markets

.market-static {
  font-size: 0.75rem;
  height: 29px;
  line-height: 29px;
  text-align: center;
}
.market-select {
  background-color: var(--geoff-bg-section);
  font-size: 0.75rem;
  height: 29px;

  border-radius: 0.25rem;
  border: 1px solid #858585;
  padding-left: 0.5em;
  padding-right: 1.75em;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  option {
    background-color: transparent;
  }

  &:focus-visible{
    outline: none;
  }
}

.league-name {
  font-size: 0.7rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 400;
}

.sub-header {
  min-height: 2rem;
}
</style>