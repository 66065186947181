<template>
<div
  class="container-fluid desktop-container"
  :class="['sport', 'raceEvent', 'matchEvent'].includes($route.name)?'desktop-container-with-sportsbar':''"
>
  <div class="row geoff-bg-main py-3">
    <div class="col-2 px-2">
      <div class="rounded-3 geoff-bg-section position-relative">
        <side-bar/>
      </div>
    </div>
    <div class="col-7 px-0">
      <div class="router-container">
        <router-view v-slot="{Component}">
          <transition name="fade">
            <component :is="Component"/>
          </transition>
        </router-view>
      </div>
    </div>
    <div class="col-3 position-relative">
      <div class="fixed-container111">
        <div class="fixed-content">
          <div>
            <video-stream-control/>
          </div>
          <div class="betslip-container">
            <bet-slip-main/>
          </div>
          <div class="promo-slider-container pt-2">
            <promo-slider />
          </div>
          <div class="promo-slider-container pt-2">
            <corner-tile media-name="blog" title="Geoff’s Reports" />
          </div>
          <div class="promo-slider-container pt-2">
            <corner-tile media-name="furlong"/>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import BetSlipMain from "@/components/BetSlip/BetSlipMain";
import SideBar from "@/components/Desktop/SideBar";
import PromoSlider from "@/components/PromoSlider";
import CornerTile from "@/components/CornerTile";
import VideoStreamControl from "@/components/VideoStream/VideoStreamControl";

export default {
  name: "BaseDescktopView",
  components: {SideBar, BetSlipMain, PromoSlider, CornerTile, VideoStreamControl}
}
</script>

<style scoped lang="scss">
.desktop-container{
  // margin-top: 5rem;
  // background-color: #FFFFFF;
  margin-top: var(--geoff-header-height);
  background-color: #d4adad;
}
.router-container{
  position: relative;
  width: 100%;
  min-height: 80vh;
}

.fixed-container {
  right: 0;
  // position: relative;
  position: fixed;
  width: inherit;
  z-index: 100;
  height: calc(100vh - 6rem);

  .fixed-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .betslip-container {
    overflow-y: hidden;
    padding: 0 0.5rem;
  }

}

.promo-slider-container {
  flex: 1;
}

</style>