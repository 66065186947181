const videoStreamStore = {
    state: {
        showControls: false,
        videoWidth: 0,
        nextMeeting: null,
        /*
            type: (TRP, SD365, SIS)??? 
            name: string - place and time??
        */
        singleEvent: null,
        /*
            eventFile: null,
            eventLeague: null,
            locationName: null,
            eventTime: null,
            eventName: null,
        */
        show: localStorage.getItem('showStream')? JSON.parse(localStorage.getItem('showStream')) : true,
        streamSrc: null,
        streamType: null,
        loaded: false
    },
    getters: { 
        SINGLE_EVENT(state) {
            return state.singleEvent
        },
        NEXT_MEETING(state) {
            return state.nextMeeting
        },
        VIDEO_WIDTH(state) {
            return state.videoWidth
        },
        SHOW_CONTROLS(state) {
            return state.showControls
        },
        STREAM_LOADED(state) {
            return state.loaded
        },

        // fields
        STREAM_SHOW(state){
            return state.show
        },
        STREAM_SRC(state){
            return state.streamSrc
        },
        STREAM_TYPE(state){
            return state.streamType
        }
    },
    mutations: {
        SET_STATE(state, data){
        for (let key in data){
            state[key] = data[key]
        }
        }
    },
    actions: {
        SET_STREAM_LOADED({commit}, value) {
            commit('SET_STATE', {loaded: value})
        },
        SET_VIDEO_NEXT_MEETING({commit}, value){
            commit('SET_STATE', {nextMeeting: value})
        },
        SET_VIDEO_WIDTH({commit}, value){
            commit('SET_STATE', {videoWidth: value})
        },
        SET_SHOW_CONTROLS({commit}, value){
            commit('SET_STATE', {showControls: value})
        },
        SET_SHOWN({commit}, value){
            commit('SET_STATE', {show: value})
        },
        SET_STREAM_SRC({commit}, value) {
            commit('SET_STATE', {streamSrc: value})
        },
        SET_STREAM_DATA({commit}, value) {
            commit('SET_STATE', {
                streamSrc: value.src,
                streamType: value.type
            })
        },

        // show controls with all necessary data
        START_STREAM_CONTROLS({commit, state}, value) {
            if (!state.showControls){
                commit('SET_STATE', {
                    // showControls: true,
                    streamSrc: null,
                    streamType: null,
                    nextMeeting: value,
                    singleEvent: null,
                })
            }
        },

        SET_STREAM_EVENT ({commit}, value){
            commit('SET_STATE', {
                singleEvent: value,
            })
        },

        CLEAR_STREAM_SRC({commit}) {
            commit('SET_STATE', {
                streamSrc: null,
                streamType: null
            })
        },
        CLEAR_STREAM_DATA({commit}) {
            commit('SET_STATE', {
                videoWidth: 0,
                nextMeeting: null,
                singleEvent: null,
                streamSrc: null,
                streamType: null,
                loaded: false
            })
        }
    }
}
export default videoStreamStore

