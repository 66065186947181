<template>
  <div v-if="loading" class="loading-wr position-relative">
    <pre-loader/>
  </div>
  <div v-else class="mb-2" :class="{'mx-2': $store.state.isMobile}">

    <div v-if="showHeader && sliderEvents.length" class=" mb-2 fw-600 fs-6 p-n sub-header geoff-bg-section" :class="{'rounded-3': !$store.state.isMobile}">
      <div>Featured Matches</div>
    </div>

    <swiper
      v-if="sliderEvents"
      slidesPerView="auto"
      :centeredSlides="false"
      :spaceBetween="8"
      :navigation="$store.state.isMobile ? false : true"
      :modules="modules"
      :loop="false"
      class="mySwiper">

      <swiper-slide
        v-for="(event, index) in sliderEvents"
        :key="index"
        class="slide-match"
        :class="{'desktop-slide': !$store.state.isMobile}"
      >
        <div class="match-event m-2">
          <div class="match-info" @click="goToEvent(event)">
            <div class="info">
              <div class="text-ellipsies">{{ event.leagueName }}</div> <!-- casediff -->
              <div class="text-ellipsies">{{ event.formatDate }} {{ event.kickoff }}</div>
            </div>
            <div class="teams-wr" :class="{'three-players': isThreePlayers && !['draw', 'vs', '@', 'tie'].includes(event.secondPlayer?.toLowerCase())}">
              <div class="team">
                <div v-if="event.homeLogo" class="team-logo">
                  <img :src="event.homeLogo" />
                </div>
                <div class="team-name text-ellipsies">{{event.homeTeam}}</div>
              </div>

              <div v-if="isThreePlayers &&  !['draw', 'vs', '@', 'tie'].includes(event.secondPlayer?.toLowerCase())" class="team">
                <div v-if="event.secondPlayerLogo" class="team-logo">
                  <img :src="event.secondPlayerLogo" />
                </div>
                <div class="team-name text-ellipsies">{{ event.secondPlayer }}</div>
              </div>

              <div v-else class="teams-separator">-</div>

              <div class="team">
                <div  v-if="event.awayLogo" class="team-logo">
                  <img :src="event.awayLogo" />
                </div>
                <div class="team-name text-ellipsies">{{event.awayTeam}}</div>
              </div>
            </div>
          </div>
          <div class="match-results">
            <hr />
            <div>{{ event.fullCard[0]?.elRespose }}</div>
            <hr />
          </div>
          <!-- buttons -->
          <div class="bets-wr">
            <odd-btn 
              v-for="(subEvent, index) in event?.fullCard[0]?.events"
              :key="index"
              :race="subEvent"
              :selection="parseInt(subEvent.selection)"
              :showEmptyButton="false"
              class="slider-odd"
            />
          </div>
        </div>
      </swiper-slide>


    </swiper>
  </div>
</template>

<script>
import OddBtn from "@/components/UI/OddBtn";
import PreLoader from "@/components/UI/PreLoader";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

export default {
  name: "MatchSlider",
  components: { Swiper, SwiperSlide, OddBtn, PreLoader },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  props: {
    sportName: {
      type: String,
      default: 'Football'
    },
    showToggle: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data: ()=>{
    return {
      // intervalLoad: null,
      loading: true,
      sport: {},
      toggleSport: {},
      sliderEvents: []
    }
  },
  computed: {
    isThreePlayers() {
      return (this.sportName === 'Golf')
    }
  },
  watch: {
    sportName() {
      this.sport = this.API.SPORTS.find(sport => sport.title.toLowerCase() === this.sportName.toLocaleLowerCase())
      this.loadEvents()
    }
  },
  beforeMount() {
    this.sport = this.API.SPORTS.find(sport => sport.title.toLowerCase() === this.sportName.toLocaleLowerCase())
    this.toggleSport = this.API.SPORTS.find(sport => sport.title.toLowerCase() === 'horse racing')
  },
  mounted() {
    const vm = this
    vm.loadEvents();
  },
  methods:{
    formatDate(eventDate){
      let rawDate = new Date(eventDate.split('-').reverse().join('-'))
      let today = new Date()
      if (rawDate.getDate()===today.getDate() && rawDate.getFullYear()===today.getFullYear() && rawDate.getMonth()===today.getMonth()){
        return 'Today'
      } else {
        return eventDate
      }
    },
    loadEvents(){
      this.loading = true;
      let vm = this
      this.API.getSportEventsList(this.sport.code, false)
        .then(data => {
          const sliderEventsData = []
          data.forEach(category => {
            if (sliderEventsData.length < 12) {
              category.events =  [];
              category.competitions.map(comp => { // casediff
                comp.details.map(ev => {
                  let event = ev
                  event.date = comp.date
                  event.formatDate = vm.formatDate(event.date)
                  event.code = category.code // casediff
                  event.eventName = comp.eventName // casediff
                  event.leagueName = category.eventName
                  
                  event.fullCard = event.fullCard.map( el => {
                    const parsed = this.API.ParseEventData(el)
                    return {
                      ...el,
                      fullCard: parsed
                    }
                  }) 

                  vm.sliderEvents = [...this.sliderEvents]
                  sliderEventsData.push(event)
                  return event
                })
              })
            }
          });

          vm.sliderEvents = [...sliderEventsData]

          this.loading = false
          vm.$emit('dataloaded') // casediff
        })
        .catch(error=>{console.log(error), vm.loading=false})
        // .finally(()=> vm.$emit('dataloaded'))

    },
    goToEvent(event){
      this.$router.push({
        name: 'matchEvent',
        params: {
            sportName: this.sport.title,
            ef: event.code, // casediff
            eg: event.eventGroup
          },
        query: {
            title: event.eventName, // name??
            homeParticipant: event.homeTeam,
            awayParticipant: event.awayTeam,
            ...( this.sport.code==='m' && event.secondPlayer && event.secondPlayer && !['draw', 'vs', '@', 'tie'].includes(event.secondPlayer.toLowerCase()) ? {secondParticipant: event.secondPlayer,} : {}),
            date: `${event.formatDate} ${event.kickoff}`
          }
      })
    },
    setActive(sport) {
      this.$emit('setActiveSlider', sport)
    }
  },
}
</script>

<style scoped lang="scss">
.loading-wr {
  min-height: 150px;
}

.slide-match {
  max-width: min(24rem, calc(100vw - 2rem));
  // max-width: min(24rem, calc(100vw - 4rem)); // TODO
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--geoff-bg-section);

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  &.desktop-slide {
    width: calc((100% - 1rem)/3);
    max-width: unset;

    @media only screen and (max-width: 1400px) {
      width: calc((100% - 0.5rem)/2);

    }
  }
}

.match-event {
  width: 100%;
  height: 100%;

  .match-info {
    text-decoration: none;
    color: var(--geoff-txt-main);
    cursor: pointer;

    .info {
      display: flex;
      justify-content: space-between;
      color: var(--geoff-item-timestamp);
      font-size: 0.675rem;
      line-height: 1;
      margin-bottom: 0.75rem;
    }
    .teams-wr {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      grid-gap: 0.25rem;

      &.three-players {
        grid-template-columns: 1fr 1fr 1fr;
      }

      .team {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 0.5rem;
        font-size: 0.875rem;
        line-height: 1.25;
        overflow: hidden;
        flex-direction: column;

        .team-logo {
          width: 2rem;
          height: 2rem;
          display: flex;
          min-width: 1rem;

          img { object-fit: contain;}
        }
        .team-name {
          max-width: 100%;
        }

        &.home {
          flex-direction: row-reverse;
          .team-logo {
            margin-left: 0.5rem;
          }
        }
        &.away {
          flex-direction: row;
          .team-logo {
            margin-right: 0.5rem;
          }
        }

      }

      .teams-separator {
        display: flex;
        align-items: center;
      }
    }
  }
  .match-results {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    grid-gap: 0.5rem;
    color: var(--geoff-item-timestamp);
    font-size: 0.75rem;
  }

  .bets-wr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5rem;
  }
}

</style>