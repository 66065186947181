<template>
  <div>
    <div v-if="!isMobile" class="geoff-bg-section rounded-3 mb-2 p-3">
      <div class="geoff-border-bottom d-flex justify-content-between pb-2">
        <div class="fw-600">Account Overview</div>
        <refresh-balance :dark="true"/>
      </div>
      <div v-if="$store.state.user.funddetail" class="container-fluid">
        <div class="row">
          <div class="col-6 d-flex justify-content-center align-items-center geoff-border-end">
            <div class="d-flex my-4">
              <div class="balance-icon-wrap" :style="{'background-image': 'url('+require('../assets/wallet.svg')+')'}"></div>
              <div>
                <div class="fs-14 text-secondary">Current Balance</div>
                <div class="fs-24 fw-600">{{$store.state.user.funddetail.balance}}</div>
              </div>
            </div>
          </div>
          <div class="col-6 d-flex justify-content-center align-items-center">
            <div class="d-flex my-4">
              <div class="balance-icon-wrap" :style="{'background-image': 'url('+require('../assets/card-tick.svg')+')'}"></div>
              <div>
                <div class="fs-14 text-secondary">Available Credit</div>
                <div class="fs-24 fw-600">{{$store.state.user.funddetail.availableFunds}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="isMobile?'':'geoff-bg-section p-3 rounded-3'">
      <div v-if="isMobile" class="filters-section geoff-border-bottom">
        <div class="w-50 h-100 d-flex justify-content-center align-items-center geoff-border-end px-4 cursor-pointer">
          <select v-model="selectType" class="h-100 d-flex fs-12 justify-content-between w-100 border-0 geoff-bg-main">
            <option value="1" selected>All Transactions</option>
            <option value="2">All Bets</option>
            <option value="3">Unsettle/Pending Bets</option>
            <option value="11">Deposits</option>
            <option value="9">Withdrawals</option>
      <!--      removed at Geoff's request   -->
      <!--      <option value="10">Reversal Withdrawals</option>-->
      <!--      <option value="15"> Deposits, Withdrawals, Reversals</option>-->
          </select>
        </div>
        <div class="w-50 h-100 d-flex justify-content-center align-items-center px-4">
          <select v-model="selectDuration" class="h-100 d-flex fs-12 justify-content-between w-100 border-0 geoff-bg-main">
            <option value="1" selected>Last 24 Hours</option>
            <option value="2">Last 2 Days</option>
            <option value="3">Last 5 Days</option>
            <option value="4">Last 7 Days</option>
            <option value="5">Last 14 Days</option>
            <option value="6">Last 30 Days</option>
            <option value="7">Last 2 Months</option>
            <option value="8">Last 3 Months</option>
            <option value="9">Last 6 Months</option>
            <option value="10">Last 9 Months</option>
            <option value="11">Last 12 Months</option>
          </select>
        </div>
      </div>
      <div v-else class="d-flex justify-content-between align-items-center w-100">
        <div class="fw-600">Statements</div>
        <div class="d-flex">
          <select v-model="selectType" class="d-flex fs-12 justify-content-between border-0 p-2 geoff-bg-active rounded-3 me-3">
            <option value="1" selected>All Transactions</option>
            <option value="2">All Bets</option>
            <option value="3">Unsettle/Pending Bets</option>
            <option value="11">Deposits</option>
            <option value="9">Withdrawals</option>
      <!--      removed at Geoff's request   -->
      <!--      <option value="10">Reversal Withdrawals</option>-->
      <!--      <option value="15"> Deposits, Withdrawals, Reversals</option>-->
          </select>
          <select v-model="selectDuration" class="d-flex fs-12 justify-content-between border-0 p-2 geoff-bg-active rounded-3">
            <option value="1" selected>Last 24 Hours</option>
            <option value="2">Last 2 Days</option>
            <option value="3">Last 5 Days</option>
            <option value="4">Last 7 Days</option>
            <option value="5">Last 14 Days</option>
            <option value="6">Last 30 Days</option>
            <option value="7">Last 2 Months</option>
            <option value="8">Last 3 Months</option>
            <option value="9">Last 6 Months</option>
            <option value="10">Last 9 Months</option>
            <option value="11">Last 12 Months</option>
          </select>
        </div>
      </div>
    </div>

    <div :class="isMobile?'m-2':'my-2'">
      <pre-loader v-if="loading || !isGlobalLoaded"></pre-loader>
        <!-- :class="$store.state.isMobile?'':'border-bottom'" -->
      <transaction-new-item
        v-else
        v-for="(transaction, index) in transactions"
        :key="index"
        :transaction="transaction"
      />
    </div>
  </div>
</template>
  
<script>
  // import TransactionItem from "@/components/Transactions/TransactionItem";
  import TransactionNewItem from "@/components/Transactions/TransactionNewItem";
  import {mapGetters} from "vuex";
  // import moment from 'moment'
  import PreLoader from "@/components/UI/PreLoader";
  import RefreshBalance from "@/components/Sevice/RefreshBalance";
  
  export default {
    name: "TransactionsView",
    components: {RefreshBalance, PreLoader, TransactionNewItem, },
    data: ()=>{
      return{
        loading: false,
        selectType: '1',
        selectDuration: '1',
        transactions: [],
        betSlipHistory: [],
      }
    },
    watch:{
      selectType(){
        this.loadTransactions()
      },
      selectDuration(){
        this.loadTransactions()
      },
      isGlobalLoaded(val){
        if (val) {
          this.loadTransactions()
        }
      }
    },
    computed:{
      ...mapGetters(['USER', 'isMobile', 'BETSLIP_AUTH_TOKEN']),
     
      displayTransactions() {
        switch (this.transType) {
          case 9:
          case 10:
          case 11: {
            return this.transactions.filter(t=>t.abetaTransactions.mainTransaction.type == this.transType)
          }
          default: {
            return  this.transactions
          }
        }
      },
      isGlobalLoaded() {
        return this.USER && this.BETSLIP_AUTH_TOKEN
      },
    },
    
    mounted() {
      if (this.isGlobalLoaded){
        this.loadTransactions()
      }
    },
    methods:{
      loadTransactions(){
        let vm = this
        vm.loading = true
        // betslip historry data is not used
        /*
        let start_date = ''
        const end_date = moment().format("YYYY-MM-DD HH:mm:ss");
        switch (this.selectDuration){
              case 1:
                  start_date = moment().subtract(24, "hours").format("YYYY-MM-DD HH:mm:ss");
                  break;
              case 2: //            2 Days
                  start_date = moment().subtract(2, "days").format("YYYY-MM-DD HH:mm:ss");
                  break;
              case 3: //          5 Days
                  start_date = moment().subtract(5, "days").format("YYYY-MM-DD HH:mm:ss");
                  break;
              case 4: ///        7 Days
                  start_date = moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss");
                  break;
              case 5: //     14 Days
                  start_date = moment().subtract(14, "days").format("YYYY-MM-DD HH:mm:ss");
                  break;
              case 6: //   30 Days
                  start_date = moment().subtract(30, "days").format("YYYY-MM-DD HH:mm:ss");
                  break;
              case 7: // 2 Months
                  start_date = moment().subtract(2, "months").format("YYYY-MM-DD HH:mm:ss");
                  break;
              case 8: // 3 Months
                  start_date = moment().subtract(3, "months").format("YYYY-MM-DD HH:mm:ss");
                  break;
              case 9: //6 Months
                  start_date = moment().subtract(6, "months").format("YYYY-MM-DD HH:mm:ss");
                  break;
              case 10: //9 Months
                  start_date = moment().subtract(9, "months").format("YYYY-MM-DD HH:mm:ss");
                  break;
              case 11: //12 Months
                  start_date = moment()
                      .subtract(12, "months")
                      .format("YYYY-MM-DD HH:mm:ss");
                  break;
          }
        this.API.getBetSlipHistory(this.USER.cust_Ref, start_date, end_date, (data)=>{
          data.data.map(function (transaction) {
                vm.betSlipHistory.push(JSON.parse(transaction.bet_info));
            });
        })
        */
        this.API.getStatementsNew(this.USER.cust_Ref, vm.selectType, vm.selectDuration, (data)=>{
          vm.transactions = data.filter(el => el.transaction).map(el => el.transaction)
          
          vm.loading = false
        })
      },
      filterTransactions(transactions, transType) {
        if (transType === 9 || transType === 10 || transType === 11) {
          return transactions.filter(t=>t.MainTransaction.Type == transType)
        } else if (transType === 15) {
          return transactions.filter(t=>t.MainTransaction.Type == 9 || t.MainTransaction.Type == 10 || t.MainTransaction.Type == 11)
        } else {
          return transactions
        }
      }
    }
  
  }
  </script>
  
  <style scoped>
  .balance-icon-wrap{
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: #FFD60A;
    margin-right: 2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 2rem;
  }
  .filters-section{
    height: 2.75rem;
    position: sticky;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    background-color: var(--geoff-bg-main);
  }
  
  select:focus-visible{
    border: none;
    outline: none;
  }
  </style>