<template>
  <!-- <div class="hidden">
    <div>STREAM_LOADED: {{ STREAM_LOADED }}</div>
    <div>SHOW_CONTROLS: {{ SHOW_CONTROLS }}</div>
    <div>STREAM_SHOW: {{ STREAM_SHOW }}</div>
    <div>STREAM_SRC: {{ STREAM_SRC }}</div>
    <div>STREAM_TYPE: {{ STREAM_TYPE }}</div>
    <div>VIDEO_WIDTH: {{ VIDEO_WIDTH }}</div>
    <div>NEXT_MEETING: {{ NEXT_MEETING }}</div>
    <div>SINGLE_EVENT: {{ SINGLE_EVENT }}</div>
  </div> -->
  <div class="video-container" ref="videoWrap" id="videoWrap">
    <div v-if="$store.state.authToken && STREAM_SHOW">
      <div class="video mb-2" v-if="!STREAM_LOADED">
        <pre-loader />
      </div>
      <div class="video mb-2"  v-else-if="SHOW_CONTROLS && STREAM_SHOW" :class="`ratio-${STREAM_TYPE}`">
        <iframe
          v-if="STREAM_SRC"
          sandbox="allow-scripts allow-same-origin"
          allow="autoplay"
          :src="STREAM_SRC"
          scrolling="no"
          frameborder="no"
          :width="'100%'"
          :height="'100%'"
          allowfullscreen
        ></iframe>

        <div v-else  class="no-stream p-2 text-center text-warning h-100 w-100">
          <div class="logo" :style="{backgroundImage: 'url('+require('@/assets/logo.png')+')'}"></div>
          <div>Thank you for your continued custom!</div>
          <div>No stream currently available on this channel.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreLoader from "@/components/UI/PreLoader";
import {mapGetters} from "vuex";

export default {
  name: "VideoStreamControl",
  components: { PreLoader },
  props: ['eventName', 'eventFile', 'sportCode', 'eventTime', 'locationName', 'eventLeague', 'nextMeetings'],
  data:()=>{
    return{
      show: localStorage.getItem('showStream')? JSON.parse(localStorage.getItem('showStream')) : true,
    }
  },
  computed:{
    ...mapGetters([
      'STREAM_SHOW',
      'STREAM_SRC',
      'STREAM_TYPE',
      'VIDEO_WIDTH',
      'NEXT_MEETING',
      'SINGLE_EVENT',
      'SINGLE_MEETING',
      'SHOW_CONTROLS',
      'STREAM_LOADED'
    ]),
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.calculateWidth)
    this.$store.dispatch('SET_SHOW_CONTROLS', false)
    this.$store.dispatch('CLEAR_STREAM_DATA', false)
  },
  mounted() {
    window.addEventListener('resize', this.calculateWidth)
    this.calculateWidth()
  },
  methods: {
    calculateWidth(){
      try {
        if (this.$refs.videoWrap){
          this.calculatedWidth = this.$refs.videoWrap.offsetWidth
          this.$store.dispatch('SET_VIDEO_WIDTH', this.$refs.videoWrap.offsetWidth);
          if (!this.calculatedWidth) setTimeout(()=>this.calculateWidth(), 100)
        }
      } catch (e) {
        console.log('calculateWidth error > ', e)
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .video-container {
    // background-color: teal;
    position: relative
  }
  .video { 
    min-height: 50px;
    aspect-ratio: 16 / 9;
    
    iframe {
      // aspect-ratio: 3 / 4.7;
      width: 100%;
    }

    &.ratio-SD365 { aspect-ratio: 4.7 / 3; }
    &.ratio-SD365_2 { aspect-ratio: 4.7 / 3; }
    &.ratio-TRP { aspect-ratio: 16 / 9; }
    &.ratio-SIS { aspect-ratio: 16 / 9; }
  }

  .no-stream {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.85rem;
    
    .logo {
      background-size: contain;
      width: 60px;
      height: 60px;
      background-repeat: no-repeat;
    }
  }
</style>