<template>
<div class="meeting match-table rounded-3">
  <div class="match-title geoff-border-bottom">
    <div class="d-flex w-100 justify-content-between align-items-center p-2">
      <div class="fs-13 fw-600">{{meeting.meetinName}}</div>
      <div v-if="meeting.events" class="fs-11 fw-500">{{meeting.events[0]?.date.split(' ')[0]}}</div>
    </div>
  </div>
  <div class="container-fluid pb-2">
    <div class="row row-cols-4">
      <div
          class="event-cell col fw-500 text-center p-2 cursor-pointer"
          v-for="event in meeting.events"
          :key="event.eventIdx"
          @click="$router.push({name: 'raceEvent', params:{sportName: sport.title ,ef: event.eventFile, eg: event.eventGroup}, query:{ev:event.eventIdx, sportCode:sportCode}})"
      >
        <img :src="event.status === 'Result'? require('../../../assets/pr.svg'): require('../../../assets/p.svg')">

        <info-icon
            v-if="event.guarantee.toLowerCase()==='best odds guaranteed'"
            class="tag-bog fs-12 ms-2XXX"
            data-toggle="tooltip"
            data-placement="top"
            title="Best odds guaranteed"
          >BOG</info-icon>

        <span class="ms-2XXX">{{event.time}}</span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import InfoIcon from "@/components/UI/InfoIcon";

export default {
name: "SportRacesEventMeeting",
  components: {InfoIcon},
props: ['meeting', 'sport', 'sportCode']
}
</script>

<style scoped lang="scss">

.tag-bog {
  display: inline-block;
}

.event-cell {
  display: flex;
  gap: 0 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.meeting {
      /*min-width: 20rem;*/
    /*height: 12rem;*/

  /* margin-right: 0.5rem;
  width: 100%;

  background-color: var(--geoff-bg-section);
  border-radius: 0.25rem; */
}
</style>