<template>
  <pre-loader v-if="loading"></pre-loader>
  <div class="phone-debosit-container">

    
      <div class="title">
        £4.50 one off payment
      </div>
      <div>
        Charges will be added to this mobile phone bill
      </div>
      <template v-if="step==='phone'">
        <div class="text-upper">
          Enter your mobile number:
        </div>

        <div class="input-icon-container">
          <div class="input-icon">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="auto" height="auto" viewBox="0 0 72.000000 128.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
            fill="currentColor" stroke="none">
            <path d="M65 1271 c-11 -5 -29 -19 -40 -31 -19 -21 -20 -38 -23 -560 -1 -334
            1 -547 7 -563 6 -14 22 -34 38 -44 24 -16 57 -18 289 -21 298 -4 334 1 364 51
            19 31 20 54 20 569 l0 538 -29 32 -29 33 -289 2 c-158 1 -297 -2 -308 -6z
            m435 -61 c0 -6 -53 -10 -140 -10 -87 0 -140 4 -140 10 0 6 53 10 140 10 87 0
            140 -4 140 -10z m160 -515 l0 -445 -300 0 -300 0 0 445 0 445 300 0 300 0 0
            -445z m-260 -500 c29 -35 4 -95 -40 -95 -28 0 -52 25 -52 53 0 36 19 57 52 57
            15 0 33 -7 40 -15z"/>
            </g>
            </svg>

          </div>
          <input 
            type="tel"
            class="input-value"
            maxlength="8"
            pattern="\d"
            v-model="phone"
            placeholder="07xxxxxx">
        </div>

        <vue-button cls="main" class="my-3" @click="()=>{this.step='pin'}">REQUEST YOUR PIN CODE</vue-button>
      </template>
      <template v-if="step==='pin'">
        <div class="text-upper">
          Enter your PIN:
        </div>

        <div class="input-container">
          <input 
            type="text"
            class="input-value pin-code positive"
            maxlength="8"
            pattern="\d"
            v-model="pin"
            placeholder="">
        </div>

        <vue-button cls="positive" class="my-3" @click="() => {this.step = 'phone'}">PAY NOW</vue-button>
      </template>

      <div class="note">To unsubscribe send STOP to xxxx</div>
      <div class="note">For support, call xxxxxxxxxxxx</div>
      <div class="note"> email : <a class="geoff-link-accent" href="mailto:support@geoffbanks.co.uk">support@geoffbanks.co.uk</a></div>
      
  </div>
</template>

<script>
import PreLoader from "@/components/UI/PreLoader";
import VueButton from "@/components/UI/VueButton";

export default {
  name: "PhoneDepositView",
  components: {
    PreLoader,
    VueButton
  },
  data:()=>{
    return{
      loading: false,
      phone: null,
      pin: null,
      step: 'phone'
    }
  },
  methods: {
    setStep(val) {
      this.step = val
    }
  }
}
</script>

<style scoped lang="scss">

.phone-debosit-container {
  max-width: 400px;
  margin: auto;
  text-align: center;

  >div {
    margin-bottom: 0.5rem;
  }
  .title {
    font-size: 1.5rem;
  }

  .note {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--geoff-txt-note);
  }

  .input-icon-container {
    background: var(--geoff-bg-btn-1);
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 4px;

    .input-icon {
      height: 3.125rem;
      padding: 0.25rem 0.5rem;
    }

    
  }
  
  input.input-value {
      width: 100%;
      height: 3.125rem;
      padding: 0.5rem 1rem 0.5rem 0.5rem;
      border: none;
      overflow: hidden;

      &.pin-code {
        max-width: 200px;
        text-align: center
      }

      &[disabled] {
        background-color: #333;
        color: #666;
        cursor: not-allowed;
      }
      &:active{
        border: none;
      }
      &:focus{
        border: none;
      }
      &:focus-visible{
        outline: none;
      }
    }
    .text-upper {
      text-transform: uppercase;
    }
}



</style>