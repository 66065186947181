<template>
<div 
  class="competition-item  grid-coupon-view geoff-text-14 justify-content-between-xxx cursor-pointer align-items-center-xxx"
  :class="colCountClass"
  @click.self="goToEvent"
>
  <div
      class="cursor-pointer competition-info"
      @click="goToEvent"
  >
    <div>{{event.homeTeam}}</div>
    <div v-if="sport.code==='m' && event.secondPlayer">{{ event.secondPlayer }}</div>
    <div>{{event.awayTeam}}</div>
    <div>
      <live-icon v-if="event.isLive"></live-icon>
      <span v-else class="time-stamp">{{formatDate}}</span>
      <span>&nbsp;</span>
      <span class="time-stamp">{{event.kickoff}}</span>
      <span class="league-stamp text-ellipsies" v-if="showLeague">{{ event.League }}</span>
    </div>
  </div>

  <!-- markets -->
  <div v-for="(col, idx) in marketsData" :key="idx">
    <competiotions-market-cell :market="col"></competiotions-market-cell>
  </div>
</div>
</template>

<script>
import LiveIcon from "@/components/UI/LiveIcon";

import CompetiotionsMarketCell from "@/components/Sport/Competitions/CompetiotionsMarketCell";

export default {
name: "CompetitionsListItem",
  components: {
    LiveIcon,
    CompetiotionsMarketCell
  },
  props: {
    event: { type: Object },
    sport: { type: Object },
    showLeague: {
        type: Boolean,
        default: false
      },
    mainMarket: {
        type: String,
        default: 'Match Result'
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    formatDate(){
      let rawDate = new Date(this.event.date.split('-').reverse().join('-'))
      let today = new Date()
      if (rawDate.getDate()===today.getDate() && rawDate.getFullYear()===today.getFullYear() && rawDate.getMonth()===today.getMonth()){
        return 'Today'
      } else {
        return this.event.date
      }
    },
    mainMarketData() {
      const market = this.event.fullCard?.find(fc => fc.elRespose === this.mainMarket)
      if (market) {
        // return market?.events
        return market
      }
      return []
    },
    colCountClass() {
      return `market-col-${this.columns.length} ${ this.$store.state.isMobile ? 'grid-coupone-view-mobile' : 'grid-coupone-view-desktop' }`

    },
    marketsData() {
      const res = this.columns
        .map(el => {
          const fullCard = this.event.fullCard?.find(fc => fc.elRespose === el.marketName)
          return {
            ...el,
            events: fullCard ? fullCard.events : []
          }
        })
      return res
    }  
  },
  methods:{
    goToEvent(){
      this.$router.push({
        name: 'matchEvent',
        params: {
            sportName:this.sport.title,
             ef:this.event.code, // casediff
             eg:this.event.eventGroup
          },
        query: {
            title: this.event.eventName,
            homeParticipant: this.event.homeTeam,
            awayParticipant: this.event.awayTeam,
            ...( this.sport.code==='m' && this.event.secondPlayer && !['draw', 'vs', '@', 'tie'].includes(this.event.secondPlayer.toLowerCase()) ? {secondParticipant: this.event.secondPlayer,} : {}),
            date: `${this.formatDate} ${this.event.kickoff}`
          }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.competition-item{
  width: auto;
  background: var(--geoff-bg-section);
  padding: 0.3rem 0;
  margin: 0 0.7rem;
  /* border-radius: 0.25rem; */

  // font-size: 0.875rem; // 14px
  // line-height: 1.25;
  line-height: 1.231;

  .time-stamp {
    font-size: .6875rem;
    color: var(--geoff-item-timestamp);
  }

  .competition-info {
    overflow: hidden;
  }
  .league-stamp {
    font-size: .6875rem;
    color: var(--geoff-item-timestamp);
    margin-left: 0.5rem;

    @media screen and (max-width: 475px) {
      margin-left: 0;
      display: block;
    }
  }

}
</style>