<template>
<div class="container mt-3">
  <div class="fs-20 fw-600 mb-3">Test iOS safe areas</div>
    <div class="mb-3">
      <div class="mb-1 fs-14">25px</div>
      <div class="square twenty-five-px"></div>
    </div>
    <div class="mb-3">
      <div class="mb-1 fs-14">safe-area-inset-top</div>
      <div class="square safe-area-inset-top"></div>
    </div>
    <div class="mb-3">
      <div class="mb-1 fs-14">safe-area-inset-bottom</div>
      <div class="square safe-area-inset-bottom"></div>
    </div>
</div>
</template>

<script>
export default {
  name: "TestSafeAreasView"
}
</script>

<style scoped>
.square{
  background-color: #1a1e21;
}
.twenty-five-px{
  height: 25px;
  width: 25px;
}
.safe-area-inset-top{
  height: env(safe-area-inset-top);
  width: env(safe-area-inset-top);
}
.safe-area-inset-bottom{
  height: env(safe-area-inset-bottom);
  width: env(safe-area-inset-bottom);
}

</style>