<template>
<!-- <div class="p-2" :class="$store.state.isMobile?'':'px-0'"> -->
<div>
  <div class="res-card mb-2">
    <div class="res-card-header sub-header geoff-border-bottom p-n fs-15 fw-600">Race Results</div>
    <div class="res-card-list px-n">
      <div
          v-for="(res, index) in race.results"
          :key="index"
          class="d-flex justify-content-between align-items-center py-n geoff-border-bottom-separator"
      >
        <div class="d-flex align-items-center justify-content-start">
          <div class="d-flex align-items-center justify-content-start" v-if="!['g,q', '$'].includes(sport.code)">
            <race-number color="#000000" :race-number="parseInt(res.resultPlace)" :sport="sport" result/>
            <div v-if="res.additionalInfo?.silkLogoUrl" class="racer-image-wr ms-2">
              <img :src="res.additionalInfo.silkLogoUrl" alt="">
            </div>
            <div class="ms-2">{{ res.selectionName }}</div>
          </div>
          <div class="d-flex align-items-center justify-content-center" v-else>
            <race-number color="#000000" :race-number="parseInt(res.resultPlace)" :sport="sport" result/>
            <div class="ms-2">{{ res.selectionName }}</div>
            <race-number class="ms-2" :race-number="parseInt(res.selectionNumber)" :sport="sport"/>
          </div>
        </div>
        <div class="fw-500 fs-5">{{ res.odds }}</div>
      </div>
    </div>
  </div>
  <div class="res-card">
    <div class="res-card-header sub-header geoff-border-bottom p-n fs-15 fw-600">Dividends</div>
    <div class="px-n">
      <div
        v-for="(res, index) in race.results1"
        :key="index"
        class="d-flex justify-content-between align-items-center py-n geoff-border-bottom-separator"
      >
        <div class="">{{ res.name }}</div>
        <div class="fw-500">{{ res.odds }}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import RaceNumber from "@/components/UI/RaceNumber";
export default {
  name: "RaceEventResults",
  components: {RaceNumber},
  props: ['race', 'sport']
}
</script>

<style scoped>
.res-card{
  width: auto;
  /* background: #FFFFFF; */
  background-color: var(--geoff-bg-section);
  border-radius: 0.25rem;
}

.racer-image-wr {
  max-width: 2rem;
}
</style>