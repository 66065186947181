<template>
<div class="position-absolute top-50 start-50 translate-middle text-center">
  <div v-if="title" class="text-capitalize fw-500 mb-4">{{title}}</div>
  <div class="d-flex justify-content-center">
    <div class="no-events-picture">
      <svg class="no-event-img" width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="48" cy="48" r="48" fill="var(--geoff-bg-section)"/>
        <g opacity="0.1">
          <path d="M38 32.375C36.975 32.375 36.125 31.525 36.125 30.5V23C36.125 21.975 36.975 21.125 38 21.125C39.025 21.125 39.875 21.975 39.875 23V30.5C39.875 31.525 39.025 32.375 38 32.375Z" fill="currentColor"/>
          <path d="M58 32.375C56.975 32.375 56.125 31.525 56.125 30.5V23C56.125 21.975 56.975 21.125 58 21.125C59.025 21.125 59.875 21.975 59.875 23V30.5C59.875 31.525 59.025 32.375 58 32.375Z" fill="currentColor"/>
          <path d="M39.25 54.25C38.6 54.25 37.95 53.975 37.475 53.525C37.375 53.4 37.25 53.275 37.175 53.15C37.075 53 37 52.85 36.95 52.7C36.875 52.55 36.825 52.4 36.8 52.25C36.775 52.075 36.75 51.9 36.75 51.75C36.75 51.1 37.025 50.45 37.475 49.975C38.4 49.05 40.075 49.05 41.025 49.975C41.475 50.45 41.75 51.1 41.75 51.75C41.75 51.9 41.725 52.075 41.7 52.25C41.675 52.4 41.625 52.55 41.55 52.7C41.5 52.85 41.425 53 41.325 53.15C41.225 53.275 41.125 53.4 41.025 53.525C40.55 53.975 39.9 54.25 39.25 54.25Z" fill="currentColor"/>
          <path d="M48 54.2499C47.675 54.2499 47.35 54.175 47.05 54.05C46.725 53.925 46.45 53.7499 46.225 53.5249C46.125 53.3999 46 53.2749 45.925 53.1499C45.825 52.9999 45.75 52.8499 45.7 52.6999C45.625 52.5499 45.575 52.4 45.55 52.25C45.525 52.075 45.5 51.8999 45.5 51.7499C45.5 51.0999 45.775 50.4499 46.225 49.9749C46.45 49.7499 46.725 49.5749 47.05 49.4499C47.95 49.0499 49.075 49.2749 49.775 49.9749C50.225 50.4499 50.5 51.0999 50.5 51.7499C50.5 51.8999 50.475 52.075 50.45 52.25C50.425 52.4 50.375 52.5499 50.3 52.6999C50.25 52.8499 50.175 52.9999 50.075 53.1499C49.975 53.2749 49.875 53.3999 49.775 53.5249C49.3 53.9749 48.65 54.2499 48 54.2499Z" fill="currentColor"/>
          <path d="M39.25 63C38.575 63 37.95 62.725 37.475 62.275C37.025 61.8 36.75 61.15 36.75 60.5C36.75 59.85 37.025 59.2001 37.475 58.7251C37.6 58.6251 37.725 58.5001 37.85 58.4251C38 58.3251 38.15 58.25 38.3 58.2C38.45 58.125 38.6 58.075 38.75 58.05C39.25 57.95 39.75 58 40.2 58.2C40.525 58.325 40.775 58.5001 41.025 58.7251C41.475 59.2001 41.75 59.85 41.75 60.5C41.75 61.15 41.475 61.8 41.025 62.275C40.775 62.5 40.525 62.6751 40.2 62.8001C39.9 62.9251 39.575 63 39.25 63Z" fill="currentColor"/>
          <path d="M69.25 42.5999H26.75C25.725 42.5999 24.875 41.7499 24.875 40.7249C24.875 39.6999 25.725 38.8499 26.75 38.8499H69.25C70.275 38.8499 71.125 39.6999 71.125 40.7249C71.125 41.7499 70.275 42.5999 69.25 42.5999Z" fill="currentColor"/>
          <path d="M63 77.375C56.45 77.375 51.125 72.05 51.125 65.5C51.125 58.95 56.45 53.625 63 53.625C69.55 53.625 74.875 58.95 74.875 65.5C74.875 72.05 69.55 77.375 63 77.375ZM63 57.375C58.525 57.375 54.875 61.025 54.875 65.5C54.875 69.975 58.525 73.625 63 73.625C67.475 73.625 71.125 69.975 71.125 65.5C71.125 61.025 67.475 57.375 63 57.375Z" fill="currentColor"/>
          <path d="M65.6748 70.1499C65.1998 70.1499 64.7248 69.975 64.3498 69.6L59.0748 64.325C58.3498 63.6 58.3498 62.4 59.0748 61.675C59.7998 60.95 60.9998 60.95 61.7248 61.675L66.9998 66.9499C67.7248 67.6749 67.7248 68.875 66.9998 69.6C66.6248 69.975 66.1498 70.1499 65.6748 70.1499Z" fill="currentColor"/>
          <path d="M60.3253 70.225C59.8503 70.225 59.3753 70.05 59.0003 69.675C58.2753 68.95 58.2753 67.7499 59.0003 67.0249L64.2753 61.7499C65.0003 61.0249 66.2003 61.0249 66.9253 61.7499C67.6503 62.4749 67.6503 63.6749 66.9253 64.3999L61.6503 69.675C61.2753 70.025 60.8003 70.225 60.3253 70.225Z" fill="currentColor"/>
          <path d="M56.425 74.875H38C28.875 74.875 23.625 69.625 23.625 60.5V39.25C23.625 30.125 28.875 24.875 38 24.875H58C67.125 24.875 72.375 30.125 72.375 39.25V58.9C72.375 59.675 71.9 60.375 71.15 60.65C70.425 60.925 69.6 60.725 69.075 60.125C67.525 58.375 65.3 57.375 62.975 57.375C58.5 57.375 54.85 61.025 54.85 65.5C54.85 66.975 55.25 68.425 56.025 69.675C56.45 70.425 57 71.05 57.6 71.575C58.2 72.075 58.425 72.9 58.15 73.65C57.925 74.375 57.225 74.875 56.425 74.875ZM38 28.625C30.85 28.625 27.375 32.1 27.375 39.25V60.5C27.375 67.65 30.85 71.125 38 71.125H52.55C51.625 69.425 51.125 67.5 51.125 65.5C51.125 58.95 56.45 53.625 63 53.625C64.975 53.625 66.925 54.125 68.625 55.05V39.25C68.625 32.1 65.15 28.625 58 28.625H38Z" fill="currentColor"/>
        </g>
      </svg>

    </div>
  </div>
  <div class="text-secondary fs-14 mt-4" style="width: 200px">There are Currently no active events. Please check back later.</div>
</div>
</template>

<script>
export default {
name: "NoEvents",
props: ['title']
}
</script>

<style scoped>
.no-events-picture{
  height: 6rem;
  width: 6rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1001;
}

.no-event-img {
  color: var(--geoff-txt-main);
}
</style>