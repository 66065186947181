<template>
  <div>
    <scroll-tabs-bar
        v-if="categories.length && $store.state.isMobile"
        :items="categories"
        :selected-item="currentCategory"
        @selectitem="selectCategory"
        class="geoff-border-bottom competitons-wr"
      ></scroll-tabs-bar>
    <div v-if="!loading">
      <MatchSlider 
          v-if="!$store.state.isMobile ||  currentCategory === 'Featured'"
          :sportName="this.sport.title"
          @dataloaded="matchSliderLoaded=true" 
          />
    </div>
    <div>
      <pre-loader v-if="loading"/>
      <no-events v-else-if="eventCategories.length===0 && antepostEvents.length===0" :title="sport.title+ ' matches'"/>
      <div v-else>
        <div v-if="currentCategory!=='Futures'"  class="geoff-bg-section" :class="{'rounded-3 mb-2': !$store.state.isMobile}" >
          <div v-if="$store.state.isMobile">
            <top-competitons-view
              :sport="sport"
              :is-collapsible="false"
              class="geoff-bg-subsection mb-1"/>

              <leagues-tree-view 
                v-show="showRegionMenu"
                :sport="sport"
                :hide-a-z="false"
                class="geoff-bg-subsection" />
              
              <!-- breadcrumb is show on "Competitions" tab and competition is not shown -->
              <template v-if="!showRegionMenu">
                <div class="match-menu" @click="selectCategory('Competitions')">
                  <div class="text-ellipsies"> 
                    <template v-if="selectedPath?.length">
                      <span v-for="(item, idx) in selectedPath" :key="idx" class="path-item">{{ item }}</span>
                    </template>
                    <div v-else class="all-item"> ALL COMPETITIONS &#10095;</div>
                  </div>
                </div>
              </template>
          </div>

          <template v-if="!showRegionMenu">
            <div >
              <div class="sub-header p-n grid-coupon-view"
                :class="colCountClass"
              >
                <div></div>

                <!-- single market -->
                <template v-if="availableMarketsOptions.length === 1">
                  <div class="market-static">{{ defaultMarket }}</div>
                </template>

                <!-- few markets -->
                <template v-else>
                  <div v-for="(col, idx) in columns" :key="idx">
                    <select
                      :disabled="availableMarketsOptions.length <= 0"
                      v-model="columns[idx]" class="market-select" name="" id="">
                      <option
                        v-for="(item, midx) in availableMarketsOptions"
                        :key="midx"
                        :value="item">{{ item }}</option>
                    </select>
                  </div>
                </template>
              </div>
            </div>

            <!-- <transition-group name="flip-list" tag="div" class="position-relative"> -->
            <div
              v-for="category in sortedEventCategories"
              :key="category.id" 
              :class="$store.state.isMobile?' geoff-border-bottom-separator':''"
            >
              <div
                class=" fw-700 sub-header p-n grid-coupon-view geoff-bg-subsection"
                :class="colCountClass"
              >
                <div class="league-name">{{category.eventName}}</div>
                <!-- cols legends -->
                <competitions-legend
                  v-for="(colMarket, idx) in selectedMarkets"
                  :key="idx"
                  :market-name="colMarket.marketName"
                  :market-type="colMarket.legendType"
                  :market-legend="colMarket.legends"
                />
              </div>
              <competitions-list-item
                v-for="(event, index) in category.events"
                :key="index"
                :event="event"
                :sport="sport"
                :columns="selectedMarkets"
                class="geoff-border-bottom-separator"
              />
            </div>
            <!-- </transition-group> -->
          </template>

        </div>
        <div v-else>
          <antepost-events-list
            :events="antepostEvents"
            :sport="sport"
            :sport-code="sport.code"
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import ScrollTabsBar from "@/components/UI/ScrollTabsBar";
import PreLoader from "@/components/UI/PreLoader";
import CompetitionsListItem from "@/components/Sport/Competitions/CompetitionsListItem";
import CompetitionsLegend from "@/components/Sport/Competitions/CompetitionsLegend";
import NoEvents from "@/components/Sport/NoEvents";
import AntepostEventsList from "@/components/Sport/AntepostEventsList";
import MatchSlider from "@/components/Home/MatchSlider";

import TopCompetitonsView from "@/components/UI/SideTreeView/TopCompetitonsView"
import LeaguesTreeView from "@/components/UI/SideTreeView/LeaguesTreeView"

export default {
name: "SportCompetitionsRegion",
  components: { AntepostEventsList, NoEvents, CompetitionsListItem, CompetitionsLegend, PreLoader, ScrollTabsBar, MatchSlider, TopCompetitonsView, LeaguesTreeView},
  props: ['sport'],
  data: ()=>{
    return{
      categories: [],
      loading: false,
      eventCategories: [],
      antepostEvents: [],
      currentCategory: null,
      currentEventCategory: null,
      currentSportCode: '',
      intervalLoad: null,
      mainMarket: '',
      defaultMarket: null,
      columns: [], // all columns to display
      availableMarkets: [], // option list to display in select in additional cols

      selectedEventCategoryName: null,
      selectedRegionName: null

    }
  },
  beforeUnmount() {
    if (this.intervalLoad) clearInterval(this.intervalLoad)
  },
  mounted() {
    const vm = this
    if (this.sport.code) this.categories.push('Featured','Competitions')
    this.currentSportCode = this.sport.code
    if (this.sport.antePostCode) this.categories.push('Futures')
    this.currentCategory = this.$route.query.category 
      || this.$route.query?.selectedCategoryEventName ? 'Competitions' : this.categories[0]
    vm.loadEvents()
    vm.intervalLoad = setInterval(()=>vm.loadEvents(), 5000)
  },
  computed:{
    selectedPath() {
      const result = []
      if (this.$route.query?.region) {
        result.push(this.$route.query.region)
      }
      if (this.$route.query?.selectedCategoryEventName) {
        result.push(this.$route.query.selectedCategoryEventName === "All" ? `All ${this.$route.query?.region}` : this.$route.query.selectedCategoryEventName)
      }
      return result
    },
    showRegionMenu(){
      return this.$store.state.isMobile && this.currentCategory === 'Competitions'&& !this.$route.query?.selectedCategoryEventName
    },
    sortedEventCategories(){
      // TODO
      if ( this.$store.state.isMobile && this.currentCategory === 'Featured') {
        return this.eventCategories
      }
      if (this.$route.query?.selectedCategoryEventName === 'All' &&  this.$route.query?.region){
        return this.eventCategories
          .filter(el => el.countries
            .some(c => c.countryName === this.$route.query.region))
      }
      if (this.$route.query?.selectedCategoryEventName){
        return this.eventCategories
          .filter(el => el.eventName === this.$route.query.selectedCategoryEventName)
      }
      else {
        return this.eventCategories
      }
    },
    colCountClass() {
      return `market-col-${this.columns.length} ${ this.$store.state.isMobile ? 'grid-coupone-view-mobile' : 'grid-coupone-view-desktop' }`
    },
    // string options for select
    availableMarketsOptions() {
      return this.availableMarkets.map(el => el.marketName)
    },
    selectedMarkets() {
      const selectedMarkets = this.columns.map(el => this.availableMarkets.find(m => el === m.marketName ))
      return selectedMarkets;
    },
    isGolf() {
      return this.sport?.code === 'm'
    },

    selectedCategory() {
      return this.categories
        .map(el => el.competitions)
        .find(el => el.competitionName === this.selectedCategoryEventName)
    },
    selectedRegion() {
      return this.categories
        .find(el => el.countryName === this.selectedRegionName)
    }
  },
  watch:{
    currentCategory(category){
      if (category === 'Futures'){
        this.loadEvents(true)
      } else {
        this.loadEvents(false)
      }
    },
    '$route.query.selectedCategoryEventName'(value){
      if (value) this.selectEventCategory(value)
      this.selectEventCategoryName = value
    },

    '$route.query.region'(value){
      this.selectedRegionName = value
    },

    '$route.query.category'(value){
      if (value) {
        this.currentCategory = value
      }
    }
  },
  methods:{
    loadEvents(isAntepost = null){
      let vm = this
      if (!vm.eventCategories.length && !vm.antepostEvents.length) vm.loading = true
      if (isAntepost === null) {
        isAntepost = this.sport.url ? false : true
      }
      let promise = isAntepost ? this.API.getAntepostEvents(this.sport?.antePostCode) : this.API.getEvents(this.sport?.code)
      promise
      .then(response=>{
        // antepost
        if (response.data.length && response.data[0].Meetings){
          vm.antepostEvents = response.data
        // basic events
        } else {
          vm.eventCategories = response.data.map(cat => {
            let category = cat
            category.events = []
            cat.competitions.map(comp => { // casediff
              comp.details.map(ev => {
                let event = ev

                event.fullCard = event.fullCard.map( el => {
                  const parsed = this.API.ParseEventData(el)
                  return {
                    ...el,
                    fullCard: parsed
                  }
                }) 
                
                event.date = comp.date
                event.code = cat.code // casediff
                event.eventName = comp.eventName // casediff
                category.events.push(event)
                // allEvents.push(event)
                return event
              })
            })
            return category
          })
          vm.availableMarkets = response.markets
          vm.defaultMarket =  vm.availableMarkets[0]?.marketName
          if (!vm.columns.length) {
            vm.columns = response.markets.map(el => el.marketName).slice(0, 3)
          }
        }
        vm.loading=false
        if (vm.$route.query.selectedCategoryEventName) vm.selectEventCategory(vm.$route.query.selectedCategoryEventName)
      })
      .catch(error=>{console.log(error), vm.loading=false})
    },
    selectCategory(categoryName){
      if (this.$store.state.isMobile){
        this.currentCategory = categoryName
        // this.antepostEvents = []
        // this.eventCategories = []
        this.$router.push({name: 'sport', params: {sportName: this.sport.title }, query:{category:categoryName}})
      }
    },
    selectEventCategory(selectedEventCategoryName){
      this.currentEventCategory = this.eventCategories.filter(c=>c.eventName===selectedEventCategoryName)[0] // casediff
    },
    openRegionMenu() {
      this.$refs?.regionMenu?.open()
    }
  }

}
</script>

<style scoped lang="scss">
.live-match-item{
  width: auto;
  background: #FFFFFF;
  border-radius: 0.25rem;
}
.flip-list-move{
  transition: transform 0.3s;
}

.market-static {
  font-size: 0.75rem;
  height: 29px;
  line-height: 29px;
  text-align: center;
}
.market-select {
  background-color: var(--geoff-bg-section);
  font-size: 0.75rem;
  height: 29px;

  border-radius: 0.25rem;
  border: 1px solid #858585;
  padding-left: 0.5em;
  padding-right: 1.75em;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  option {
    background-color: transparent;
  }

  &:focus-visible{
    outline: none;
  }
}
.league-name {
  font-size: 0.7rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 400;
}
.sub-header {
  min-height: 2rem;
}

.match-menu {
  height: 2.5rem;
  display: grid;
  // grid-template-columns: 1fr 1rem;
  align-items: center;
  padding: 0 0.5rem;
  font-weight: 700;
  background-color: var(--geoff-bg-subsection);

  .path-item {
    &::after {
      // content: '•';
      content: '\25B8';
      margin: 0 0.5rem;
    }

    &:last-child {
      margin-left: 0;
      &::after {
        content: none;
        margin-right: 0;
      }

    }
  }
  .all-item {
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center
  }
}
</style>