

export const homeSliderList = [
  { id: 1, name: 'Football', isDefault: false },
  { id: 2, name: 'Horse Racing', isDefault: true }
]

export const sortOptions = [
  { id: 1, name: 'Sort by Price', isDefault: true },
  { id: 2, name: 'Sort by Card' }
]

export const oddOptions = [
  { id: 1, name: 'Fractional' },
  { id: 2, name: 'Decimal' }
]

export const carouselRegionOptions = [
  { id: 1, name: 'All' },
  { id: 2, name: 'UK/Ireland' },
  { id: 3, name: 'International' }
]