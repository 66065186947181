<template>
    <!-- 0: 2 col market -->
    <div 
      v-if="market.legendType === 0" 
      class="col-main grid-gap-05"
      :class="marketClass">
      <!-- Abnormal case - 3 events (with empty tie) for 2 cols market -->
      <template v-if="market.events?.length === 3">
        <odd-btn 
          :race="market.events[0]"
          :selection="parseInt(market.events[0].selection)"
          :showEmptyButton="false"
        />
        <odd-btn 
          :race="market.events[2]"
          :selection="parseInt(market.events[2].selection)"
          :showEmptyButton="false"
        />
      </template>

      <!-- Default case -->
      <template v-else>
        <odd-btn 
          v-for="(subEvent, index) in market.events"
          :key="index"
          :race="subEvent"
          :selection="parseInt(subEvent.selection)"
          :showEmptyButton="false"
        />
      </template>
    </div>

    <!-- 1: 3 cols market -->
    <div 
      v-if="market.legendType === 1" 
      class="col-additional grid-gap-05"
      :class="marketClass">

      <!-- Abnormal case: 2 events in 3 cols market -> draw empty in the middle??? -->
      <template v-if="market.events?.length === 2">
        <odd-btn 
          :race="market.events[0]"
          :selection="parseInt(market.events[0].selection)"
          :showEmptyButton="false"
        />
        <!-- 2nd column -->
        <div></div> 
        <odd-btn 
          :race="market.events[1]"
          :selection="parseInt(market.events[1].selection)"
          :showEmptyButton="false"
        />

      </template>
      <!-- Default case -->
      <template v-else>
        <odd-btn 
          v-for="(subEvent, index) in market.events"
          :key="index"
          :race="subEvent"
          :selection="parseInt(subEvent.selection)"
          :showEmptyButton="false"
        />
      </template>
        
    </div>

    <!-- 2: 3 cols market, calculate "CUT" value -->
    <!-- not used -->
    <div 
      v-if="market.legendType === 2" 
      class="col-additional grid-gap-05"
      :class="marketClass">
        <div
          class="odd-add-info"
          v-html="cutValue"
        ></div>

        <odd-btn 
          v-for="(subEvent, index) in market.events"
          :key="index"
          :race="subEvent"
          :selection="parseInt(subEvent.selection)"
          :showEmptyButton="false"
        />
    </div>

    <!-- 3: 3 cols market, calculate "Line" value -->
    <!-- not used -->
    <div 
      v-if="market.legendType === 3" 
      class="col-additional grid-gap-05"
      :class="marketClass">
        <div
          class="odd-add-info"
          v-html="cutValue"
        ></div>

        <!-- Abnormal case - 3 events (with empty tie) -->
        <template v-if="market.events?.length === 3">
          <odd-btn 
            :race="market.events[0]"
            :selection="parseInt(market.events[0].selection)"
            :showEmptyButton="false"
          />
          <odd-btn 
            :race="market.events[2]"
            :selection="parseInt(market.events[2].selection)"
            :showEmptyButton="false"
          />
        </template>
        <!-- Default case - 2 events -->
        <template v-else>
          <odd-btn 
            v-for="(subEvent, index) in market.events"
            :key="index"
            :race="subEvent"
            :selection="parseInt(subEvent.selection)"
            :showEmptyButton="true"
          />
        </template>
    </div>
</template>

<script>
import OddBtn from "@/components/UI/OddBtn";
export default {
name: "CompetiotionsMarketCell",
  components: {
    OddBtn
  },
  props: {
    market: { 
      type: Object,
      required: true
    }
  },
  computed: {
    marketClass() {
      return `market-legend-type-${this.market.legendType}`
    },
    cutValue() {
      // CUT market
      if (this.market.legendType === 2) {
        return this.market.events[0]?.selOption
      }
      // LINE market
      if (this.market.legendType === 3) {
        return this.market.events
          .filter(ev=>ev.price && ev.price !== 'NO')
          .map(el => el.selOption).join('<br/>')
      }
      return ''
    }

  }
}
</script>

<style scoped lang="scss">
  .odd-add-info {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    font-size: 0.85rem;
    color: var(--geoff-txt-note);
    text-align: center;
  }
</style>