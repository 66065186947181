<template>
  <div class="geoff-bg-section" :class="{'rounded-3': !$store.state.isMobile}">
    <div v-if="$store.state.isMobile" 
      @click="openCategoryEvents"
      class="match-menu px-3">
      <div>{{group?.eventName}}</div>
      <div class="ps-3">
        <!-- arrow-down.svg -->
        <svg  class="collapse-btn" :class="{'reverse': showPopup}" width="12" height="8" viewBox="0 0 12 8" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 5.72376L10.862 0.861827L11.8048 1.80464L6.00005 7.60937L0.195312 1.80464L1.13812 0.861828L6.00005 5.72376Z" fill="currentColor"/>
        </svg>
      </div>
    </div>

    <transition name="fade" appear>
      <div
        class="side_popup__wrapper"
        v-if="showPopup"
        @click.self="showPopup = false"
      >
    </div>
    </transition>
    <transition name="slide" appear>
      <div v-show="showPopup" class="side_popup" @click.self="showPopup = false">
        <div v-for="(eventItem, index) in group.events"
          :key="index"
          class="category-event-item geoff-border-bottom-separator"
          :class="{'active': eventItem.eventGroup === $route.params.eg}"
        >

          <div class="cursor-pointer competition-item" @click="showPopup = false; goToEvent(eventItem)">
            <div>{{eventItem.homeTeam}}</div>
            <div v-if="sport.code==='m' && eventItem.secondPlayer" class="fs-14"> {{ eventItem.secondPlayer}}</div>
            <div>{{eventItem.awayTeam}}</div>
            <div>
              <live-icon v-if="eventItem.isLive"></live-icon>
              <span v-else class="time-stamp">{{eventItem.formatDate}}</span>
              <span>&nbsp;</span>
              <span class="time-stamp">{{eventItem.kickoff}}</span>
            </div>
          </div>

        </div>
      </div>
    </transition>
    
  </div>

</template>

<script>
  import LiveIcon from "@/components/UI/LiveIcon";

  export default {
    name: "MatchGroupMenu",
    components: {LiveIcon},
    props: ['group'],

    data:()=>{
      return{
        // match: {},
        homeParticipant: '',
        awayParticipant: '',
        sport: {},
        title: '',
        date: '',
        ef: '',
        eg: '',
        showPopup: ''
      }
    },
    watch:{
      '$route.params'(to){
        if (!to.eg || !to.ef || this.$route.name !== "matchEvent") return
        this.sport = this.API.SPORTS.find(sport=>sport.title.toLowerCase() === this.$route.params.sportName.toLowerCase())
        this.ef = this.$route.params.ef
        this.eg = this.$route.params.eg
        this.title = this.$route.query.title
        this.date = this.$route.query.date.split(' ').join('\n')
      }
    },
    beforeMount() {
      this.sport = this.API.SPORTS.find(sport=>sport.title.toLowerCase() === this.$route.params.sportName.toLowerCase())
      this.ef = this.$route.params.ef
      this.eg = this.$route.params.eg
      this.title = this.$route.query.title
      this.date = this.$route.query.date.split(' ').join('\n')
    },
    methods:{
      openCategoryEvents() {
        this.showPopup = true;
      },
      goToEvent(event){
        this.$router.push({
          name: 'matchEvent',
          params: {
              sportName: this.sport.title,
              ef: event.code,
              eg: event.eventGroup
            },
          query: {
              title: event.eventName,
              homeParticipant: event.homeTeam,
              awayParticipant: event.awayTeam,
              ...( this.sport.code==='m' && event.secondPlayer && !['draw', 'vs', '@', 'tie'].includes(event.secondPlayer.toLowerCase()) ? {secondParticipant: event.secondPlayer,} : {}),
              date: `${event.formatDate} ${event.kickoff}`
            }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
.match-menu {
  height: 2.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.category-event-item {
  font-size: 0.875rem;

  &.active {
    border-left: 4px solid var(--geoff-accent);
    font-weight: 700;
  }
  .competition-item{
    width: auto;
    background: var(--geoff-bg-section);
    padding: 0.3rem 0.7rem;
    line-height: 1.231;

    .time-stamp {
      font-size: .625rem;
      color: var(--geoff-item-timestamp);
    }
  }
}

.side_popup__wrapper{
  position: fixed;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  display: flex;
  justify-content: right;
}
.side_popup{
  position: fixed;
  min-height: 0;
  max-height: calc(100vh - 13.5rem - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  top: calc(10rem + env(safe-area-inset-top));
  left: calc(18vw + env(safe-area-inset-left));
  right: calc(0px + env(safe-area-inset-right));
  bottom: calc(0px + env(safe-area-inset-bottom));
  transform: translate(0, 0);
  z-index: 1051;
  bottom: 3rem;
  overflow-y: auto;
  overflow-y: overlay;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(100vw);
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s;
}

.collapse-btn {
  transition: transform 0.2s;
  transform: rotate(90deg);

  &.reverse{
    transform: rotate(0deg);
  }
}

</style>