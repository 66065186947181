<template>
<div class="mb-2">
  <label class="my-2 fs-14" :class="labelClass">{{ label }}</label>
  <div class="select-wrap">
    <select ref="select" @change="update" :value="modelValue">
      <option disabled value="" class="text-secondary">{{ placeholder }}</option>
      <option v-for="(option, index) in options" :key="index" :value="option" >{{ option }}</option>
    </select>
  </div>
</div>
</template>

<script>
export default {
  name: "VueSelect",
  props: ['modelValue', 'placeholder', 'options', 'label', 'labelClass'],
  methods:{
    update(){
      this.$emit('update:modelValue', this.$refs.select.value)
    }
  }
}
</script>

<style scoped>
.select-wrap{
  height: 3.125rem;
  background: var(--geoff-bg-btn-1);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  width: 100%;
}
/* .select-wrap select:focus-visible, .select-wrap select:hover{
  outline: -webkit-focus-ring-color auto 1px;
} */
select:focus-visible{
  border: none;
  outline: none;
}
select{
  width: 100%;
  height: 100%;
  background: var(--geoff-bg-btn-1);
  border-radius: 4px;
  border: none;
}
</style>