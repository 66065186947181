<template>
  <div class="col-legend">
    <div class="legend" :class="cellClass">
      <div v-for="(lbl, idx) in marketLegend" :key="idx">{{lbl}}</div>
    </div>
  </div>
</template>

<script>
export default {
name: "CompetitionsLegend",
  components: {},
  props: {
    marketType: {
      type: Number,
      default: null
    },
    marketLegend: {
      type: Array,
      default: () => {return []}
    }
  },
  computed: {
    cellClass () {
      return this.marketType === 0 ? 'cell-2' : 'cell-3'
    }
  }
}
</script>

<style scoped lang="scss">
.col-legend {
  .legend {
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 0.6875rem;
    color: var(--geoff-txt-info);
    text-align: center;

    &.cell-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &.cell-2 {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>