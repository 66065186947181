<template>
<collapse-transition>
  <div v-if="show" class="pb-3">
  <div class="container-fluid px-2">
    <div class="row">
      <div @click="$emit('enter', 1)" class="col"><div class="btn-num">1</div></div>
      <div @click="$emit('enter', 2)" class="col"><div class="btn-num">2</div></div>
      <div @click="$emit('enter', 3)" class="col"><div class="btn-num">3</div></div>
      <div @click="$emit('enter', 4)" class="col"><div class="btn-num">4</div></div>
      <div @click="$emit('enter', 5)" class="col"><div class="btn-num">5</div></div>
      <div @click="$emit('enter', '.')" class="col"><div class="btn-num">.</div></div>
      <div @click="$emit('backspace')" class="col">
        <div class="btn-num px-2">
          <!-- img/backspace.svg -->
          <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0003 18H7.78325L2.21825 10L7.78325 2H24.0003V18ZM25.0003 0H7.26125C6.93425 0 6.62725 0.16 6.44025 0.429L0.17925 9.429C-0.05975 9.772 -0.05975 10.228 0.17925 10.571L6.44025 19.571C6.62725 19.84 6.93425 20 7.26125 20H25.0003C25.5523 20 26.0003 19.552 26.0003 19V1C26.0003 0.448 25.5523 0 25.0003 0ZM10.7933 13.957C10.9882 14.152 11.2443 14.25 11.5003 14.25C11.7563 14.25 12.0122 14.152 12.2072 13.957L14.7503 11.414L17.2933 13.957C17.4883 14.152 17.7443 14.25 18.0003 14.25C18.2563 14.25 18.5122 14.152 18.7072 13.957C19.0982 13.566 19.0982 12.934 18.7072 12.543L16.1642 10L18.7072 7.457C19.0982 7.066 19.0982 6.434 18.7072 6.043C18.3162 5.652 17.6843 5.652 17.2933 6.043L14.7503 8.586L12.2072 6.043C11.8162 5.652 11.1842 5.652 10.7933 6.043C10.4023 6.434 10.4023 7.066 10.7933 7.457L13.3362 10L10.7933 12.543C10.4023 12.934 10.4023 13.566 10.7933 13.957Z" fill="currentColor"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="row">
      <div @click="$emit('enter', 6)" class="col"><div class="btn-num"> 6</div></div>
      <div @click="$emit('enter', 7)" class="col"><div class="btn-num"> 7</div></div>
      <div @click="$emit('enter', 8)" class="col"><div class="btn-num"> 8</div></div>
      <div @click="$emit('enter', 9)" class="col"><div class="btn-num"> 9</div></div>
      <div @click="$emit('enter', 0)" class="col"><div class="btn-num">0</div></div>
      <div @click="$emit('confirm')" class="col united">
        <div class="btn-num">
          <!-- img/confirm.svg -->
          <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.24995 14.2125L2.03745 9L0.262451 10.7625L7.24995 17.75L22.25 2.75L20.4875 0.987499L7.24995 14.2125Z" fill="currentColor"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>
</collapse-transition>
</template>

<script>
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'

export default {
  name: "NumericKeyboard",
  components: {CollapseTransition},
  emits: ['enter', 'backspace', 'confirm'],
  props: ['show'],
}
</script>

<style scoped>
.col{
  min-width: 14.28%;
  max-width: 14.29%;
  padding: 0.2rem;
}
.btn-num{
  background: var(--geoff-bg-btn-1);
  color: var(--geoff-text-main);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.3rem;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
}
@media (pointer: fine) {
  .btn-num:active, .btn-num:hover{
    background-color: #d3d3d3;
  }
}
.united{
  min-width: 25.57%;
  max-width: 28.572%;
}
.slide-enter-from,
.slide-leave-to {
  max-height: 0;
}
.slide-enter-active,
.slide-leave-active {
  transition: height 0.2s;
}
</style>