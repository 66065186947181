<template>
  <div class="flex-fill d-flex flex-column">
    <div>
      <video-stream-control v-if="$store.state.isMobile"/>
    </div>
    <div>
      <video-stream-player
        v-if="nextRacesLoaded"
      />
    </div>
    <!-- <div>
      <video-stream-player
        v-if="!'DEBUG TODO' && !$store.state.isMobile && nextRacesLoaded"
      />
    </div>
    <video-stream 
      v-if=" !'DEBUG TODO' && $store.state.isMobile && nextRacesLoaded"
      :next-meetings="nextMeetings"/> -->
  <!--  <div v-if="!highlightsLoaded && !nextRacesLoaded" class="position-relative flex-fill"><pre-loader/></div>-->
    <div v-show="isLoaded" class="d-flex flex-column flex-fill">

      <div class="mb-2 fw-600 fs-6 p-n sub-header geoff-bg-section" :class="{'rounded-3': !$store.state.isMobile}">
        <div class="d-flex align-items-center gap-3 w-100 justify-content-between">
          <div class="d-flex align-items-center gap-3">
            <div>{{ sliderLabel }}</div>
            <toggle-items
              v-if="isHorseRacingToggle"
              :items="horseRacingToggleItems"
              @setActive="toggleHorseRacing" 
              :active="currentItem"/>
          </div>
          <ToggleSlider 
            :sports="['Horse Racing', 'Football']"
            @setActive="toggleSlider" 
            :active="currentSlider"/>
        </div>
      </div>

      <next-events
          v-if="currentSlider === 'Horse Racing'"
          :sport="API.SPORTS[0]"
          :sport-code="currentItem"
          :showToggle="true"
          :showHeader="false"
          :disable-expand="false"
          @dataloaded="nextLoaded"
          @setActiveSlider="setActiveSlider"
          style="flex: 0 1 auto;"
          no-events
      />
      <MatchSlider 
        v-if="currentSlider === 'Football'"
        :showToggle="true"
        :showHeader="false"
        :sportName="currentSlider"
        @dataloaded="sliderLoaded"
        @setActiveSlider="setActiveSlider"
        />
      
      <sport-highlights
          v-show="nextRacesLoaded || highlightsLoaded"
          @dataloaded="highlightsLoaded=true"
          style="flex: 1 1 auto;"
      />

    </div>
  </div>
</template>

<script>

import NextEvents from "@/components/Home/NextEvents";
import SportHighlights from "@/components/Home/SportHighlights";
// import PreLoader from "@/components/UI/PreLoader";
// import VideoStream from "@/components/VideoStream";
import MatchSlider from "@/components/Home/MatchSlider";
import ToggleSlider from "@/components/Home/ToggleSlider";
import ToggleItems from "@/components/Home/ToggleItems";

import VideoStreamPlayer from "@/components/VideoStream/VideoStreamPlayer";
import VideoStreamControl from "@/components/VideoStream/VideoStreamControl";
import { mapGetters } from "vuex";

export default {
  name: 'HomeView',
  components: {
    // VideoStream,
    SportHighlights,
    NextEvents,
    MatchSlider,
    ToggleSlider,
    ToggleItems,
    VideoStreamPlayer,
    VideoStreamControl
  },
  data: ()=>{
    return{
      nextRacesLoaded: false,
      highlightsLoaded: false,
      matchSliderLoaded: false,
      currentSlider: null,
      currentItem: null, //'H,h,o', // init from user prefs
      sliderTitle: '',
      nextMeetings: null,
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'USER']),
    isLoaded() {
      switch (this.currentSlider) {
        case 'Horce Racing': {
          return this.nextRacesLoaded || this.highlightsLoaded;
        }
        case 'Football': {
          return this.nextRacesLoaded || this.matchSliderLoaded;
        }
        default: {
          return true;
        }
      }
    },
    sliderLabel() {
      switch (this.currentSlider) {
        case 'Horse Racing': {
          return `Next Horse Races`
        }
        case 'Football': {
          return 'Featured Matches';
        }
        default: {
          return '';
        }
      }
    },
    isHorseRacingToggle () {
      return  this.currentSlider === 'Horse Racing'
    },
    horseRacingToggleItems() {
      return this.API.SPORTS
        .find(sport => sport.title.toLowerCase() === 'horse racing')
        .subcategories
        .map(el => {
          return {
            label: el.shortTitle,
            value: el.code,
            carouselRegionId: el.carouselRegionId
          }
        })
    },
    userPreferenceCarouselSportCode() {
      return this.horseRacingToggleItems
        .find( el => el.carouselRegionId === this.USER.preferences?.carouselRegion)
        ?.value
    }
  },
  watch: {
      '$store.state.user.preferences.carousel'(){
        this.initDefaultSlider()
      },
     
      'USER.preferences.carouselRegion'() {
        this.setCurrentItem()
      }
  },
  mounted(){
    this.initDefaultSlider()
    this.setCurrentItem()
  },
  methods: {
    initDefaultSlider() {
      const slider = this.isAuthenticated ?
        this.ENUMS.homeSliderList.find(el => el.id === this.USER.preferences?.carousel) :
        this.ENUMS.homeSliderList.find(el => el.isDefault)
      if (slider){
        this.currentSlider = slider.name
      }
    },
    setCurrentItem() {
      this.currentItem = this.isAuthenticated ?
        this.userPreferenceCarouselSportCode :
        this.horseRacingToggleItems[0].value
    },
    setActiveSlider(sport) {
      this.currentSlider = sport
    },
    toggleSlider(sport) {
      this.currentSlider = sport
    },
    toggleHorseRacing(value) {
      this.currentItem = value
    },
    sliderLoaded() {
      this.matchSliderLoaded=true;
    },
    nextLoaded(data){
      // console.log('DEBUG SET NEXT', data)
      this.nextMeetings = data
      this.nextRacesLoaded = true;
      // this.$store.dispatch('SET_VIDEO_NEXT_MEETING', data?.length ? data[0] : null)
      // this.$store.dispatch('SET_SHOW_CONTROLS',true)
      const nextRace = data && data.length ? data[0] : null
      this.$store.dispatch('START_STREAM_CONTROLS', nextRace)
        .then(() =>{
          this.$store.dispatch('SET_SHOW_CONTROLS', true)
        })
    }
  }
}
</script>
<style scoped>
.home-container{
  margin-top: 7.5rem;
  margin-bottom: 4.5rem;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: calc(0 + env(safe-area-inset-top));
  bottom: 0;
  right: 0;
  left: 0
}
.home-content{
  min-height: 100%;
  display: flex;
  flex-flow: column;
}

::-webkit-scrollbar {
    display: none;
}
/* .header-toggle {
  display: flex;
  align-items: center;
  gap: 1rem;
} */
</style>
