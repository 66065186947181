<template>
  <div class="form-page position-relative" :class="{'p-3': $store.state.isMobile}">
    <div v-if="loading || !isUserLoaded">
      <div class="loading-shade" :class="{'compensate': !$store.state.isMobile}"></div>
      <pre-loader></pre-loader>
    </div>

    <div>
      <div class="header">
        <div class="title">User Prefernces - {{ $store.state.user.cust_Ref }}</div>
      </div>
      <hr>
    </div>

    <form @submit.prevent="submit">
      <div>

        <div class="fields-grid-3">
          <div class="field-wr">
            <div class="label">Bet odds format</div>
            <div v-for="(opt) in ENUMS.oddOptions" :key="opt.id">
              <label>
                <input class="radio geoff-radio" type="radio" :value="opt.id" v-model="userPrefModel.selectedOdd">
                <span>{{ opt.name }}</span>
              </label>
            </div>
          </div>

          <div class="field-wr">
            <div class="label">Default carousel</div>
            <div v-for="(opt) in ENUMS.homeSliderList" :key="opt.id">
              <label>
                <input class="radio geoff-radio" type="radio" :value="opt.id" v-model="userPrefModel.selectedCarousel">
                <span>{{ opt.name }}</span>
              </label>
            </div>
          </div>

          <div class="field-wr">
            <div class="label">Default Sorting</div>
            <div v-for="(opt) in ENUMS.sortOptions" :key="opt.id">
              <label>
                <input class="radio geoff-radio" type="radio" :value="opt.id" v-model="userPrefModel.selectedSorting">
                <span>{{ opt.name }}</span>
              </label>
            </div>
          </div>

          <div class="field-wr">
            <div class="label">Default Carousel Region</div>
            <div v-for="(opt) in ENUMS.carouselRegionOptions" :key="opt.id">
              <label>
                <input class="radio geoff-radio" type="radio" :value="opt.id" v-model="userPrefModel.carouselRegion">
                <span>{{ opt.name }}</span>
              </label>
            </div>
          </div>
        </div>

        <div class="fields-grid-21">
          <vue-button cls="main" class="my-5">Submit</vue-button>
        </div>

      </div>
    </form>

  </div>
</template>

<script>
  import VueButton from "@/components/UI/VueButton";
  import PreLoader from "@/components/UI/PreLoader";

  export default {
    name: "UserPreferencesView",
    components: {
      PreLoader, 
      VueButton, 
    },
    data:()=>{
      return {
        loading: false,
        userPrefModel: {
          selectedOdd: 1, 
          selectedCarousel: 1, 
          selectedSorting: 1,
          carouselRegion: 1
        }

      }
    },
    computed: {
      isUserLoaded() {
        return (this.$store.state.user.preferences?.carousel &&
          this.$store.state.user.preferences?.oddFormat &&
          this.$store.state.user.preferences?.sortRacing &&
          this.$store.state.user.preferences?.carouselRegion)
      }
    },
    mounted() {
      const vm = this
      vm.loading = true
      if (this.isUserLoaded) {
        this.setInitValues()
        this.loading = false
      }
    },
    watch:{
      isUserLoaded(val) {
        if (val) {
          this.setInitValues()
          this.loading = false
        }
      },
      email(value, lastValue){
        if (value !== lastValue && lastValue) {
          console.log('cur - ', value, 'last - ', lastValue)
          this.emailWasChanged = true
        }
      },

    },
    methods:{
      setInitValues(){
        this.userPrefModel = {
          selectedOdd: this.$store.state.user.preferences?.oddFormat,
          selectedCarousel: this.$store.state.user.preferences?.carousel,
          selectedSorting: this.$store.state.user.preferences?.sortRacing,
          carouselRegion: this.$store.state.user.preferences?.carouselRegion
        }
      },
    
      submit(){
        const vm = this
        vm.loading = true
        const model = {
            OddFormat: this.userPrefModel.selectedOdd,
            Carousel: this.userPrefModel.selectedCarousel,
            SortRacing: this.userPrefModel.selectedSorting,
            CarouselRegion: this.userPrefModel.carouselRegion
        }
        this.API.setUserPreferencesAzure(model)
          .then(() => {
            vm.$store.dispatch('LOGIN', JSON.parse(localStorage.getItem('userCreds')))
              .then(() => {
                vm.$toast.success("Your information has been submitted successfully.")
              })
              .finally(()=>vm.loading = false)
          })
      },
    }
  }
</script>

<style scoped lang="scss">
// TODO: move to global?
.form-page {
  .header {
    .title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
  .section {
    margin-bottom: 2rem;
    * {
      margin-bottom: 0.5rem;
    }
  }
  .subheader {
      font-weight: 700;
  }
  .question {
    font-style: italic;
  }
}

.field-wr {
  margin-bottom: 1rem;
  .label {
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .radio {
    margin-right: 0.5rem;
  }

}

.fields-grid-3 {

  @media screen and (min-width: 640px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;
  }
}
.fields-grid-2 {

@media screen and (min-width: 640px) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}
}
</style>