<template>
  <div class="geoff-bg-main h-100">
  <router-view/>
  </div>
</template>

<script>
export default {
  name: "AccountBaseView",
}
</script>

<style scoped>
</style>