<template>

  <div>
    <!-- simple view: credit, withdrawl elc transactions  -->
    <div v-if="isMoneyTransaction" class="transaction-container font-14 my-2">

      <div class="bottom-separator flex-between geoff-text-secondary p-2">
        <div class="separated-row">
          <span>{{ transaction.ticketnumber }}</span>
          <span>{{ transaction.transactionDate }}</span>
          <span>{{ transaction.transactionTime }}</span>
        </div>
        <div>{{ transaction.status }}</div>
      </div>

      <div class="bets p-2" >
          
          <div class="bet-item">
            <div class="flex-between ">
              <!-- selection -->
              <div><b>Amount</b></div>
              <!-- odd -->
              <div >
                <b>£{{ transaction.resultAmount }}</b>
              </div>
            </div>
          </div>
          <div class="bet-item">
            <div class="flex-between ">
              <!-- selection -->
              <div><b>Balance</b></div>
              <!-- odd -->
              <div >
                <b>£{{ transaction.customerBalance }}</b>
              </div>
            </div>
          </div>

        </div>

    </div>

    <!-- else: bet transaction -->
    <div v-else-if="transaction?.wagersInfo" class="transaction-container font-14 my-2">
      <div class="bottom-separator flex-between geoff-text-secondary p-2">
        <div class="separated-row">
          <span>{{ transaction.ticketnumber }}</span>
          <span>{{ transaction.transactionDate }}</span>
          <span>{{ transaction.transactionTime }}</span>
        </div>
        <div>{{ transaction.status }}</div>
      </div>

      <!-- plain view -->
      <div v-if="collapse">
        <!-- bets list -->
        <div class="bets p-2">
          
          <div v-for="(bet, idx) in transaction.wagersInfo" class="bet-item" :key="idx">
            <div class="flex-between ">
              <!-- selection -->
              <div><b>{{ bet.selectionName }}</b></div>
              <!-- odd -->
              <div >
                <b v-if="bet.betType !== 'SP'">{{ glb.formatPrice(bet.betPrice) }}</b>
                <b v-else>SP</b>
              </div>
            </div>
          </div>

        </div>

        <div class="font-12 geoff-text-secondary p-2">
            <div>{{ transactionTypeDescription }} </div>
        </div>

        <div class="bottom-separator flex-between font-12 flex p-2">
            <div class=""><b>Total Stake £{{this.transaction.stakePlaced.toFixed(2)}}</b></div>
            <div class=""><b>{{ transactionReturn }}</b></div>
        </div>
      </div>

      <!-- detailed view -->
      <collapse-transition :duration="200">

        <div v-if="!collapse">
          <!-- bets -->
          <div class="bottom-separator px-2">
            <div  v-for="(bet, idx) in transaction.wagersInfo" :key="idx" class="bottom-separator py-2">
              <div class="flex-between">
                <div><b>{{ bet.selectionName }}</b></div>
                <div >
                  <b v-if="bet.betType !== 'SP'">{{ glb.formatPrice(bet.betPrice) }}</b>
                  <b v-else>SP</b>
                </div>
              </div>
              <div class="flex-between align-end">
                <div >
                  <div>{{ bet.meetingName }} {{ bet.meetingDate }} {{ bet.eventTime }}</div>
                  <div>{{ bet.eventName }}</div>
                  <div v-if="bet?.placeRule?.placeRuleText"> {{ bet.placeRule.placeRuleText }}</div>
                </div>
                <div><!--<b>{Lost - no data}</b>--></div>
              </div>
            </div>
          </div>
          
          <!-- bet-type -->
          <div class="bottom-separator p-2">
            <div>
              <b>Bet Type: {{ transaction.wagerType }}</b>
            </div>
          </div>


          <!-- bet lines -->
          <div class="bottom-separator font-12 p-2">
            
            <div class="flex-between">
              <div>No. of Bets</div>
              <div>{{ transaction.numbersOfLines }}</div>
            </div>
            <div class="flex-between">
              <div>Unit Stake</div>
              <div>£{{ transaction.stake.toFixed(2) }}</div>
            </div>
            <div class="flex-between">
              <div>Total Stake</div>
              <div>£{{ transaction.stakePlaced.toFixed(2) }}</div>
            </div>
          </div>

          <!-- Stake result -->
          <div v-if="transactionReturn" class="info-section bottom-separator p-2">
            <div><b>{{ transactionReturn }}</b></div>
            <!-- <div class="font-10 geoff-text-secondary">disclaimer</div> -->
          </div>
          <!-- <div class="info-section bottom-separator p-2">
            <div class="font-10 geoff-text-secondary">disclaimer</div>
          </div> -->
        </div>
      </collapse-transition>

      <div class="more-info flex-between font-12 geoff-text-secondary p-2" @click="() => {this.collapse = !this.collapse}">
        <div>Bet details</div>
        <div>
          <svg  class="details-arrow" :class="{'opened':!collapse}" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 5.72376L10.862 0.861827L11.8048 1.80464L6.00005 7.60937L0.195312 1.80464L1.13812 0.861828L6.00005 5.72376Z" fill="currentColor"/>
          </svg>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'

export default {
  name: "TransactionNewItem",
  components: {
    CollapseTransition
  },
  props: ['transaction'],
  data: ()=>{
    return{
      collapse: true,
      transactionDetails: null
    }
  },
  computed: {
    transactionTypeDescription() {
      return `${this.transaction.wagerType}, ${this.transaction.numbersOfLines} bet(s) *  £${this.transaction.stake.toFixed(2)}`
    },
    transactionReturn(){
      switch(this.transaction.status) {
        case 'Open': {
          return `Potential Return £${this.transaction.potentialProfit.toFixed(2)}`
        }
        case 'Loss':
        case 'Profit': {
          return `Return: £${this.transaction.totalProfit.toFixed(2)}`
        }
        case 'Void': {
          return ``
        }
        default: {
          return '----'
        }
      }
    },
    isMoneyTransaction() {
      // TransactionTypesEnum.Adjustmentup - 7 Status: Account Credit
      // TransactionTypesEnum.Cashtoyou - 8 Status: Overdeposit Paid to customer
      // TransactionTypesEnum.Chequetoyou - 9 Status: Withdrawal from account
      // TransactionTypesEnum.Cashreceived - 10 Status: Customer Overdeposit
      // TransactionTypesEnum.Chequereceived - 11 Status: Deposit to account
      // TransactionTypesEnum.Adjustmentdown - 14 Status: Account Debit
      return [7, 8, 9, 10, 11, 14].includes(parseInt(this.transaction.transactionTypeId))
    }  
  },
  methods:{
    showDetails(){
      this.collapse = !this.collapse
    }
  }
}
</script>

<style scoped lang="scss">
  .transaction-container {
    width: 100%;
    border-radius: 0.25rem;
    background-color: var(--geoff-bg-section);  
    font-size: 0.825rem;

    .font-10 {
      font-size: 0.625rem;
    }
    .font-12 {
      font-size: 0.75rem;
    }
    .font-14 {
      font-size: 0.825rem;
    }
    .font-16 {
      font-size: 1rem;
    }
    .flex-between {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.align-end {
        align-items: flex-end;
      }
    }
    .bottom-separator {
      border-bottom: 1px solid var(--geoff-separator);
    }


    .more-info {
      cursor: pointer;
      .details-arrow {
        transform: rotate(-90deg);
        transition: transform 0.2s;
        &.opened {
          transform: rotate(-180deg);
        }
      }
    }

    .separated-row {
      >span {
        margin-left: 0.5rem;
        &::before {
          content: '|';
          margin-right: 0.5rem;
        }

        &:first-child {
          margin-left: 0;
          &::before {
            content: none;
            margin-right: 0;
          }
        }
      }
    }

  }
</style>