<template>
  <div class="form-page position-relative" :class="{'p-3': $store.state.isMobile}">
    <div v-if="loading || !isUserLoaded">
      <div class="loading-shade" :class="{'compensate': !$store.state.isMobile}"></div>
      <pre-loader></pre-loader>
    </div>
    <div>
      <div class="header">
        <div class="title">Your Geoff Banks Net Deposit Limit</div>
        <div>If you wish to set up or alter your account to include a maximum <b>Net Deposit Limit</b> please complete the short form below.</div>
      </div>
      <hr>
      <div class="section">
        <div class="subheader">What is a Net Deposit Limit?</div>
        <div>Limit your deposits for one day, one week or one month with this simple tool will limit the net amount you are able to deposit into your account.</div>
        <div>You can decide the amount you wish to set. Deposit limits can help you to control your spending if you ensure you set responsible limits which indicate funds which you can afford to lose.</div>
        <div>If you wish to decrease your net limit you can do this at any time and it will be effective immediately. To increase your limit however, it will take 24 hours to take effect on your account.</div>
      </div>

      <div class="section">
        <div class="subheader">How do Net Deposit Limits work?</div>
        <div>Deposit limits work on a net amount, for example if you have set up a £50 daily deposit limit and deposit £50 on Monday and do not withdraw you will not be able to deposit into your account until Tuesday. However, If you deposit £50 on Monday and withdraw £20 back to your Bank Account you will be able to deposit a further £20 on Monday.</div>
      </div>

      <div class="section">
        <div class="subheader">Net Deposit Limit FAQ's</div>

        <div>
          <div class="question">I have made a deposit but it's not showing up on my account.</div>
          <div>This is likely to be due to the fact that your deposit has taken you above your net deposit limit. Please check your email linked to your account and you should see an email from Geoff Banks Support showing you your options.</div>
        </div>

        <div>
          <div class="question">I have increased my limit, but it hasn't updated, why?</div>
          <div>Its takes 24 hours to increase your net deposit limit. We are unable to override this.</div>
        </div>
      </div>
    </div>

    <form @submit.prevent="submitForm" autocomplete="off" novalidate="novalidate">
      
      <div class="field-wr">
        <div class="label">Account Name</div>
        <div class="money-container max-width-field">
          <vue-input
            class="money-input"
            name="deposit-limit"
            type="text"
            v-model="firstName"
            placeholder="First Name"
          />
          <vue-input
            class="money-input"
            name="deposit-limit"
            type="text"
            v-model="lastName"
            placeholder="Last Name"
          />
        </div>
      </div>
      
      <div class="field-wr">
        <div class="label">Net Deposit Limit Period</div>
        <div v-for="(opt) in limitOptions" :key="opt">
          <label>
            <input class="radio geoff-radio" type="radio" :value="opt" v-model="selectedLimit">
            <span>{{ opt }}</span>
          </label>
        </div>
      </div>

      <div class="field-wr">
        <div class="label">Net Deposit Limit</div>
        <div class="money-container">
          <vue-input
            class="money-input"
            name="deposit-limit"
            type="number"
            min="0"
            pattern="\d*"
            v-model="pound"
            placeholder="Pounds"
          />
          <vue-input
            class="money-input"
            name="deposit-limit"
            type="number"
            min="0"
            pattern="\d*"
            placeholder="Pence"
            v-model="pence"
          />
        </div>
      </div>

      <div class="field-wr">
        <div class="label">Proof Of Funding</div>
        <vue-file-input
          class="file-input"
          type="file"
          name="proof-of-funding"
          :value="fileName"
          @change="loadFile($event)"
          ref="photoId"
        />
        <div class="note">Not mandatory, but to support higher deposit levels you might want to confidentially share some proof of your source of funding.</div>
      </div>

      <div class="field-wr">
        <vue-checkbox 
          class="checkbox-input"
          v-model="tcAgree"
          description="'terms'"
          name="cbx-agree"
        >
          <div class="agree-terms">I agree to the Geoff Banks Net Deposit Limit <router-link class="footer-link" :to="{name:'info', params: { infoType: 'Terms And Conditions' }}">Terms & Conditions</router-link></div>
        </vue-checkbox>
      </div>

      <vue-button cls="main" class="my-5">Submit</vue-button>

    </form>
  </div>
</template>

<script>
  import PreLoader from "@/components/UI/PreLoader";
  import VueInput from "@/components/UI/VueInput";
  import VueFileInput from "@/components/UI/VueFileInput";
  import VueButton from "@/components/UI/VueButton";
  import VueCheckbox from "@/components/UI/VueCheckbox";

  export default {
    name: "UpdateDepositView",
    components: {
      PreLoader,
      VueInput,
      VueFileInput,
      VueButton,
      VueCheckbox
    },
    data:()=>{
      return{
        loading: false,
        fileName: null,
        fileData: null,
        pound: {
          type: Number,
          default: null
        },
        pence: {
          type: Number,
          default: null
        },
        selectedLimit: 'Daily',
        limitOptions: ['Daily', 'Weekly', 'Monthly'],
        tcAgree: false,
        firstName: '',
        lastName: ''
      }
    },
    computed: {
      isUserLoaded() {
        return (this.$store.state.user.userInfoLoaded && this.$store.state.user.userDetailsLoaded)
      }
    },
    watch:{
      isUserLoaded(val) {
        if (val) {
          this.setInitValues()
          this.loading = false
        }
      }
    },
    mounted(){
      if (this.isUserLoaded) {
        this.setInitValues()
        this.loading = false
      }
    },
    methods: {
      setInitValues() {
        this.firstName = this.$store.state.user.first_name
        this.lastName = this.$store.state.user.last_name
      },
      submitForm() {
        // validate
        // validate value
        const pound = parseInt(this.pound) || 0
        const pence = parseInt(this.pence) || 0
        const amount = parseFloat(`${pound}.${pence}`)

        if(!this.firstName || !(this.firstName.trim())) {
          this.$toast.warning("Please, enter First Name!")
          return
        }

        if(!this.lastName || !(this.lastName.trim())) {
          this.$toast.warning("Please, enter Last Name!")
          return
        }

        if(amount <= 0 ) {
          this.$toast.warning("Please, enter valid Net Deposit Amount!")
          return
        }

        if(amount < 10) {
          this.$toast.warning("Minimum limit should be at least £10!")
          return
        }
        // validate file required
        // if(!this.fileData  && !this.fileName ) {
        //   this.$toast.warning("Please, attach Proof Of Funding!")
        //   return
        // }

        // validate file size (8mb)
        const maxLimit = 8 * 1024 *1024
        if(this.fileData && this.fileData.size > maxLimit) {
          this.$toast.warning("The attached file exceeds the max of 8.0M!")
          return
        }

         // validate terms
        if(!this.tcAgree ) {
          this.$toast.warning("You must agree to terms and conditions to continue!")
          return
        }

        let formData = new FormData()
        // user info
        formData.append("Firstname", this.firstName)
        formData.append("Lastname", this.lastName)
        formData.append("Username", this.$store.state.user.cust_Ref)
        formData.append("Email", this.$store.state.user.email)
        // period: value, amount (pounds/pence)
        formData.append("NetDepositLimitPeriod", this.selectedLimit)
        formData.append("Pounds", pound)
        formData.append("Pence", pence)
        // file
        if (this.fileData)  {
          formData.append("OriginalFileName", this.fileName)
          formData.append("FileData", this.fileData)
        }
        formData.append("IsGeoffBanksDepositLimitAgreement", this.tcAgree)
        

        this.loading = true;
        this.API.updateDepositLimit(formData)
          .then(() => {
            this.$toast.success("Update deposit limit succesfully submited!<br/> You will receive email confirmation shortly.")
            this.clearForm()
          })
          .finally(()=>{
            this.loading = false
          })
      },

      loadFile(e){
        let files = e.target.files
        if (!files.length) return
        this.fileName = files[0].name
        this.fileData = files[0]
      },

      clearForm() {
        this.fileName = null
        this.fileData = null
        this.pound = null
        this.pence = null
        this.tcAgree = false
        this.selectedLimit = 'Daily'
      }
    }
  }
</script>

<style scoped lang="scss">

.form-page {
  .header {
    .title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
  .section {
    margin-bottom: 2rem;
    * {
      margin-bottom: 0.5rem;
    }
  }
  .subheader {
      font-weight: 700;
  }
  .question {
    font-style: italic;
  }
}

.field-wr {
  margin-bottom: 1rem;
  .label {
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .radio {
    margin-right: 0.5rem;
  }
  .note {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--geoff-txt-info);
  }
  // file input
  .file-input {
    .file-label {
      padding: 0.5rem 4rem 0.5rem 1rem !important;
    }
  }
}

.money-container {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  .money-input {
    margin: 0 0 !important;
  }
}
.agree-terms {
  line-height: 22px;
}
</style>