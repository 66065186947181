<template>
<div class="bg-main rounded-3 w-100">
  <div class="fs-16 fw-600 mb-2">Download the Geoff Banks App for Android and IOS</div>
  <div class="fs-13">Make sure you have downloaded the latest version of the Geoff Banks mobile app. The App has been improved based on customer feedback and is the best way to bet with Geoff Banks on the move. By downloading the Geoff Banks App you will also be eligible for App only price boosts and specials as well as being able to access the same markets and specials as on our desktop site. Download the Geoff Banks App from the Google Play Store or the Apple App Store and log in with your usual account details.</div>
  <div class="col d-flex justify-content-between mt-3 mb-3">
    <div class="me-1"><a href="https://play.google.com/store/apps/details?id=com.geoff_banks.geoffbanks" target="_blank">
      <img src="@/assets/download_play.svg"/></a>
    </div>
    <div class="ms-1"><a href="https://apps.apple.com/gb/app/geoff-banks-betting/id881898186" target="_blank">
      <img src="@/assets/download_apple.svg"/></a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "DownloadApp"
}
</script>

<style scoped>

</style>