<template>
<div 
  class=" race-item p-1 py-2"
  :class="{ 
    'cursor-clickable': isExpandable,
    'm-2': $store.state.isMobile,
    'my-2': !$store.state.isMobile
    }"
  @click="toggleExpand"
>
  <!-- main info -->
  <div class="d-flex justify-content-between align-items-center">
    <div class="fs-14 ms-2 d-flex align-items-center">
      <div class="d-flex align-items-center">
        <race-number  class="me-2" :race-number="raceNumber" :sport="sport"/>
        <div v-if="race.additionalInfo?.silkLogoUrl" class="racer-image-wr">
          <img :src="race.additionalInfo?.silkLogoUrl" alt="">
        </div>
        <div>
          <div>
            {{ race?.selectionName }}
          </div>
          <div v-if="race.additionalInfo?.horseAge || race.additionalInfo?.weight" class="geoff-text-note fs-12 text-start mt-1 add-info-wr">
              <span v-if="race.additionalInfo?.horseAge">Age: {{ race.additionalInfo.horseAge }}</span>
              <span v-if="race.additionalInfo?.weight">Weight: {{ race.additionalInfo.weight }} 
                <span v-if="race.additionalInfo?.weightWithHandicap">({{ race.additionalInfo.weightWithHandicap }})</span>
              </span>
          </div>
          <div v-if="race.jockey" class="geoff-text-note fs-12 text-start mt-1 add-info-wr">
            <span>{{race.jockey}}</span>
            <span v-if="race.additionalInfo?.trainerFullName">
              {{ race.additionalInfo.trainerFullName }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex" v-if="!hideOdds">
      <div class="price-history geoff-text-note">
        <span v-if="$store.state.isMobile">{{ priceHistoryStringShort }}</span>
        <span v-if="!$store.state.isMobile">{{ priceHistoryString }}</span>
      </div>
      <odd-btn-normalize
        @click.stop
        v-if="race.price && race.gPrice && race.priceLink1 !== 'NR'"
        class="me-1 odd-btn"
        :bog="race.tooltip.toLowerCase()==='best odds guaranteed'"
        :race="race"
        :selection="parseInt(race.selectionIdx)"
      />
      <odd-btn-normalize
        @click.stop
        class="me-1 odd-btn"
        :race="race"
        sp
      />

      <div class="mx-2 collapse-btn-wr d-flex align-items-center" v-if="expandable">
        <!-- arrow-down.svg -->
        <svg v-if="isExpandable" class="collapse-btn" :class="{'reverse':expanded}" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 5.72376L10.862 0.861827L11.8048 1.80464L6.00005 7.60937L0.195312 1.80464L1.13812 0.861828L6.00005 5.72376Z" fill="currentColor"/>
        </svg>
      </div>

    </div>
    
  </div>

  <!-- collapsed info -->
  <collapse-transition :duration="200">
    <div 
      class="ms-2 me-1 mt-2 geoff-text-secondary fs-11 collapse-content" 
      v-if="isExpandable && expanded"
    >
      <div class="price-history-2-col"  v-if="$store.state.isMobile && priceHistoryString" > <b>Price History:</b> {{ priceHistoryString }}</div>
      <div v-if="race.additionalInfo?.performanceComment"><b>Last Run: </b>{{ race.additionalInfo?.performanceComment }}</div>
      <div v-if="race.additionalInfo?.analystsComments"><b>Analyst Comment: </b>{{ race.additionalInfo?.analystsComments }}</div>
      <div v-if="race.additionalInfo?.performances?.length" class="horse-history-wr">
        <div  class="horse-history-tbl">
          <template v-for="(historyRace, idx) in race.additionalInfo.performances" :key="idx">
              <race-history-item :historyRace="historyRace"></race-history-item>
          </template>
        </div>
        
      </div>
    </div>
  </collapse-transition>
</div>



</template>

<script>
import RaceNumber from "@/components/UI/RaceNumber";
import OddBtnNormalize from "@/components/UI/OddBtnNormalize";
import RaceHistoryItem from "@/components/Event/RaceHistoryItem";
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'

export default {
name: "RaceItem",
  components: {OddBtnNormalize, RaceNumber, RaceHistoryItem, CollapseTransition},
  props:['race', 'sport', 'hideOdds', 'expandable'],
  data: () => {
    return {
      expanded: false
    }
  },
  computed:{
    raceNumber(){
      return parseInt(this.race.selectionNumber)
    },
    isExpandable() {
      return this.race.additionalInfo?.performanceComment || this.race.additionalInfo?.analystsComments
    },
    priceHistoryString() {
      return this.race?.additionalInfo?.priceHistory
        .map(el => this.glb.formatPrice(el))
        // .join(', ')
        .join(' \u{25B8} ')
    },
    priceHistoryStringShort() {
      // return ''
      return this.race?.additionalInfo?.priceHistory
        .slice(Math.max(this.race?.additionalInfo?.priceHistory?.length - 1, 0)) // 1 last element
        .map(el => this.glb.formatPrice(el))
        // .join(', ')
        .join(' \u{25B8} ')
    }
  },
  methods:{
    toggleExpand() {
      if (this.race.additionalInfo?.performanceComment || this.race.additionalInfo?.analystsComments) {
        this.expanded = !this.expanded
      }
    }
  }
}
</script>

<style scoped lang="scss">
.race-item{
  width: auto;
  /* background: #FFFFFF; */
  background-color: var(--geoff-bg-section);
  border-radius: 0.25rem;
}

.racer-image-wr {
  max-width: 2rem;
  margin-right: 0.5rem;
}

.cursor-clickable {
  cursor: pointer;
}

.collapse-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  font-size: 0.825rem;
  line-height: 1.1;

  .price-history-2-col {
    grid-column: 1/3;
  }

  @media only screen and (max-width: 620px){
    grid-template-columns: 1fr;

    .price-history-2-col {
      grid-column: 1;
    }
  }
}

.add-info-wr {
  >span {
    margin-left: 0.25rem;
    &::before {
      // content: '•';
      content: '\2022';
      margin-right: 0.25rem;
    }

    &:first-child {
      margin-left: 0;
      &::before {
        content: none;
        margin-right: 0;
      }

    }
  }
}

.collapse-btn-wr {
  width: 12px;
  .collapse-btn {
    transition: transform 0.2s;

    &.reverse{
      transform: rotate(180deg);
    }
  }
}

.price-history {
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  white-space: nowrap;
}

.horse-history-wr {
  grid-column: 1 / 3;
  @media only screen and (max-width: 620px){
    grid-column: 1;
  }

  .horse-history-tbl {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: auto auto auto auto auto auto;
  }
}
</style>