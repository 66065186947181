<template>
  <div class="slider-toggle-wr">
    <div
      v-for="(sport, index) in sportsData"
      :key="index"
      class="slider-toggle-btn"
      :class="{'active': sport.title === active}"
      @click="toggleSlider(sport)"
      v-html="sport.svg"></div>
  </div>
</template>

<script>

export default {
  name: "ToggleSlider",
  components: {},
  props: {
    sports: {
      type: Array,
      default: () => { return  ['Horse Racing','Football']}
    },
    active: {
      type: String,
      default: 'Horse Racing'
    }
  },
  data: ()=>{
    return {
      sportsData: []
    }
  },
  beforeMount() {
    this.sportsData = this.sports.map(el => {
      return this.API.SPORTS.find(sport => sport.title.toLowerCase() === el.toLowerCase())
    })
  },

  methods:{
    toggleSlider(sport) {
      this.$emit('setActive', sport.title)
    }
  },
}
</script>

<style scoped lang="scss">
</style>