<template>
  <div>
  <races-list-menu
      v-if="meeting.events"
      :races="meeting.events"
      :current-race="selectedEvent"
      @selectrace="selectRace"
  />
  
    <pre-loader v-if="loading"/>
  <div v-else >
    <video-stream-control v-if="$store.state.isMobile"/>
    <video-stream-player />
    <!-- <video-stream-player v-if="!'DEBUG TODO' && !$store.state.isMobile"/>
    <video-stream
        v-if=" !'DEBUG TODO' && $store.state.isMobile && currentRace.MeetingName && ['h,H', 'H,h', 'g,q', 'vh', '$'].includes(sport.code)"
        :sport-code="sport.code"
        :location-name="currentRace.MeetingName.split(' ')[0]"
        :event-time="currentRace.MeetingName.split(' ')[currentRace.MeetingName.split(' ').length-1]"
        :event-file="eventFile"
        :event-name="currentRace.EventName"
        :event-league="eventLeague"
      /> -->
      <div class="d-flex flex-column h-100">
        <div class="col" >
            <race-event
                v-if="currentRace.events && currentRace.status!=='Result'"
                :race="currentRace"
                :sport="sport"
            />
            <race-event-results
              v-if="currentRace.status==='Result'"
              :race="currentRace"
              :sport="sport"
            />
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import { GetMeetingTimeOfDay } from "@/http/utils"
  import PreLoader from "@/components/UI/PreLoader";
  import RacesListMenu from "@/components/Event/RacesListMenu";
  import RaceEvent from "@/components/Event/RaceEvent";
  import RaceEventResults from "@/components/Event/RaceEventResults";
  // import VideoStream from "@/components/VideoStream";
  
  import VideoStreamPlayer from "@/components/VideoStream/VideoStreamPlayer";
  import VideoStreamControl from "@/components/VideoStream/VideoStreamControl";
  
  export default {
  name: "RaceEventView",
    components: {
      // VideoStream,
      VideoStreamControl,
      VideoStreamPlayer,
      RaceEventResults,
      RaceEvent,
      RacesListMenu,
      PreLoader
    },
    data:()=>{
      return{
        meeting: {},
        selectedEvent:{},
        currentRace: {},
        loading: false,
        sport: {},
        eventFile: '',
        eventGroup: '',
        eventLeague: '',
        intervalLoad: null
      }
    },
    beforeMount() {
      this.sport = this.API.SPORTS.filter(sport => sport.title.toLowerCase() === this.$route.params.sportName.toLowerCase())[0]
      this.eventFile = this.$route.params.ef
      this.eventGroup = this.$route.params.eg
    },
    beforeUnmount() {
      if (this.intervalLoad) clearInterval(this.intervalLoad)
    },
    mounted() {
      const vm = this
      vm.loadData()
      vm.intervalLoad = setInterval(()=>vm.loadData(), 5000)
    },
    methods:{
    loadData(){
      let vm = this
      if (!vm.meeting.meetinName) vm.loading = true
      let sportCode = this.$route.query.sportCode? this.$route.query.sportCode: this.sport.code
      this.API.getEvents(sportCode)
        .then((data)=>{
          for (let event of data){
            for (let meeting of event.meetings){
              if (meeting.eventFile === this.eventFile && meeting.eventGroup === this.eventGroup){
                vm.meeting = meeting
                vm.eventLeague = event.eventName
                break
              }
            }
            if (vm.meeting.events) break
          }
          vm.loading = false
          let eventIdx = this.$route.query.ev?this.$route.query.ev:0
          vm.selectedEvent = vm.meeting.events[eventIdx]
          vm.getRaceDetails(vm.meeting.events[eventIdx])
        })
    },
    getRaceDetails(event){
      let vm = this
      if (!vm.meeting.meetinName) vm.loading = true
      this.API.getSingleEvent(event.eventFile, event.eventGroup, event.eventIdx, (data)=>{
        if (['g,q', '$'].includes(vm.sport.code) && data.status!=='Result'){
          let sortedRace = {...data}
          sortedRace.events = data.events.sort((a,b)=>{
            if (parseInt(a.selectionIdx) < parseInt(b.selectionIdx)) return -1
            return 1
          })
          data = sortedRace
        }
        vm.currentRace = data
        this.triggerVideo()
        vm.loading = false
      })
    },
    triggerVideo() {
      const data = {
        locationName: this.currentRace.meetingName,
        eventTime: GetMeetingTimeOfDay(this.currentRace.hour, this.currentRace.minute),
        eventFile: this.eventFile,
        eventName: this.currentRace.eventName,
        eventLeague: this.eventLeague
      }
      this.$store.dispatch('SET_STREAM_EVENT', data)
        .then(() =>{
          this.$store.dispatch('SET_SHOW_CONTROLS', true)
        })
  
    },
    selectRace(race){
      this.selectedEvent = race
      this.getRaceDetails(race)
      this.$router.push(
          {
            name: 'raceEvent',
            params: {
              sportName: this.sport.title,
              ef: this.eventFile,
              eg: this.eventGroup
            },
            query:
              {
                ev: this.selectedEvent.eventIdx,
                sportCode: this.$route.query.sportCode ? this.$route.query.sportCode : this.sport.code
              }
          })
    }
  }
  }
  </script>
  
  <style scoped>
  
  </style>