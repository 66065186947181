<template>
<div>
<div v-if="loading" class="h-100 bg-dark"><pre-loader/></div>
<div v-show="!loading">
<main-header/>
  <div class="geoff-bg-main position-relative11" :class="$store.state.isMobile?'content':'content-desktop'">
    <!-- <div class="logo-container d-flex justify-content-center align-items-center">
      <div class="logo" :style="{backgroundImage: 'url('+require('../../assets/logo.svg')+')'}"></div>
    </div> -->
    <div class="position-relative">
      <div class="d-flex justify-content-center ">
        <register-form
          class="p-4 pb-5 register-form-wr"
          @start-loading="loading=true"
          @finish-loading="loading=false"
        />
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
import MainHeader from "@/components/MainHeader";
import PreLoader from "@/components/UI/PreLoader";
import RegisterForm from "@/components/Auth/RegisterForm";

export default {
  name: "RegisterView",
  components: {RegisterForm, PreLoader, MainHeader},
  data: ()=>{
    return{
      loading: false,
    }
  },
}
</script>

<style scoped>
.content{
  height: 100vh;
  width: 100vw;
  padding-top: 3.4rem;
  overflow: scroll;
}
.content-desktop{
  padding-top: 5rem;
}
.logo-container{
  height: 6rem;
  width: 100%;
  background-color: #09080C;
}
.logo{
  height: 4rem;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.register-form-wr {
  max-width: 800px;
  min-width: 320px;
  width: 100%
}
</style>