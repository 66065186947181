<template>
<div
  class="transaction-container my-2 p-2 d-flex flex-column justify-content-center"
  @click="showDetails"
>
  <div class="d-flex my-2">
    <div class="d-flex align-items-center overflow-hidden" :class="$store.state.isMobile?'w-40':'w-25'">
      <img height="18" :src="getTypeIcon()">
      <span class="ms-2 fs-17 fw-600">{{transaction.TransactionSummary.ResultAmount<0?'-':''}}£{{transaction.TransactionSummary.ResultAmount.toString().replace('-', '')}}</span>
    </div>
    <div class="d-flex align-items-center justify-content-between" :class="$store.state.isMobile?'w-60':'w-75'">
      <div class="fs-14" :class="$store.state.isMobile?'':'d-flex justify-content-between w-75'">
        <div><span class="fw-600"> {{ transaction.TransactionSummary.WagerInfo.EventFullName }}</span> <span class="text-secondary fw-400">({{transaction.TransactionSummary.SportName}})</span></div>
        <div>{{transaction.TransactionSummary.Date.split(" ")[0]}}</div>
      </div>
      <div class="d-flex justify-content-between align-items-center overflow-hidden">
        <div class="overflow-hidden text-nowrap text-truncate ms-2 fs-12">{{getTransactionType()}}</div>
        <div class="ms-2">
          <!-- arrow-down.svg -->
          <svg  class="collapse-btn" :class="{'reverse':!collapse}" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 5.72376L10.862 0.861827L11.8048 1.80464L6.00005 7.60937L0.195312 1.80464L1.13812 0.861828L6.00005 5.72376Z" fill="currentColor"/>
          </svg>
        </div>
      </div>
    </div>
  </div>

  <collapse-transition>
  <div v-if="!collapse">
    <div class="d-flex justify-content-between mt-3">
      <div class="fw-600">Ticket Number</div>
      <div>{{transaction.TransactionSummary.RefNo}}</div>
    </div>
    <div class="d-flex justify-content-between mt-3">
      <div class="fw-600">Balance</div>
      <div>£{{transaction.TransactionSummary.Balance}}</div>
    </div>
    <div v-if="transaction.TransactionSummary.DebitAmount" class="d-flex justify-content-between mt-3">
      <div class="fw-600">Stake</div>
      <div>£{{parseInt(transaction.TransactionSummary.DebitAmount)}}</div>
    </div>
    <div v-if="transactionDetails.wagers">
      <div v-for="(wager, index) in transactionDetails.wagers" :key="index" class="mt-2">
<!--        <div class="">{{ wager.EventName }}</div>-->
        <div v-if="wager.WagerText">{{ replaceEuro(wager.WagerText) }}</div>
        <div v-else class="d-flex justify-content-between align-items-center">
          <div>{{ wager.SelectionName +' @ ' + wager.Price }}</div>
          <odd-icon v-if="wager.Odds" :price="wager.Odds" />
        </div>
      </div>
    </div>
    <div v-else-if="transaction.TransactionSummary.WagerInfo.EventFullName" class="d-flex justify-content-between mt-2">
      <div>
        <div class="fs-14 fw-600">{{transaction.TransactionSummary.WagerInfo.Selection.split(' v ').join(' - ')}}</div>
<!--        <div class="fs-12 mt-2">{{transaction.TransactionSummary.WagerInfo.EventFullName}}</div>-->
      </div>
      <odd-icon v-if="transaction.TransactionSummary.WagerInfo.Odds" :price="transaction.TransactionSummary.WagerInfo.Odds"/>
    </div>

    <div v-else class="d-flex justify-content-between mt-2">
      <div class="fs-14">{{transaction.TransactionSummary.SportName}}</div>
      <div class="fs-14" v-if="transaction.RelatedTransaction">{{transaction.RelatedTransaction.TranType}}</div>
    </div>
  </div>
  </collapse-transition>
</div>
</template>

<script>
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'
import OddIcon from "@/components/UI/OddIcon";

export default {
  name: "TransactionItem",
  components: {OddIcon, CollapseTransition},
  props: ['transaction'],
  data: ()=>{
    return{
      collapse: true,
      transactionDetails: null
    }
  },
  methods:{
    getTypeIcon(){
      if (this.transaction.TransactionSummary.IsOpen){
        if (this.getTransactionType() === 'Account Credit' && this.transaction.TransactionSummary.HasProfit) {
          return require('../../assets/img/transactions/+.svg')
        } else if (this.getTransactionType() === 'Account Credit' && !this.transaction.TransactionSummary.HasProfit){
          return require('../../assets/img/transactions/-.svg')
        } else if (this.getTransactionType() === 'Payment to Customer') {
          return  require('../../assets/img/transactions/out.svg')
        } else if (this.getTransactionType() === 'Payment from Customer'){
          return  require('../../assets/img/transactions/in.svg')
        }
          return require('../../assets/img/transactions/check.svg')
      }else if (["2"].includes(this.transaction.MainTransaction.Type) && this.transaction.TransactionSummary.HasProfit){
          return  require('../../assets/img/transactions/+.svg')
      }else if (["1", "4", "7", "9", "14", "17"].includes(this.transaction.MainTransaction.Type)){
          return  require('../../assets/img/transactions/warn.svg')
      }else if (["11"].includes(this.transaction.MainTransaction.Type)){
          return  require('../../assets/img/transactions/in.svg')
      }else if (["10"].includes(this.transaction.MainTransaction.Type)){
          return require('../../assets/img/transactions/out.svg')
      }else {
          return  require('../../assets/img/transactions/-.svg')
      }
    },
    getTransactionType() {
      if (this.transaction.MainTransaction.Type != "1" && this.transaction.MainTransaction.Type != "2") {
        switch (this.transaction.MainTransaction.Type) {
          case "10":
            return "Reverse Withdrawal"
          case "1":
            return "Stake"
          case "2":
            return "Payout"
          case "4":
            return "Voided"
          case "7":
            return "Account Credit"
          case "14":
            return "Account Debit"
          case "9":
            return "Payment to Customer"
          case "11":
            return "Payment from Customer"
          case "17":
            return this.transaction.MainTransaction.TranType
          default:
            return this.transaction.MainTransaction.Type
        }
      } else if (this.transaction.TransactionSummary.IsOpen) {
        return 'Pending'
      } else {
        return this.transaction.TransactionSummary.HasProfit ? 'Profit' : 'Loss'
      }
    },
    showDetails(){
      const vm = this
      if (!vm.collapse) {
        vm.collapse = true
        return
      }
      let officeNo = vm.transaction.MainTransaction.OfficeNo
      let refNo = vm.transaction.MainTransaction.RefNo
      vm.API.getWagerInfo(officeNo, refNo)
      .then(res=>{
        let trans = {...this.transaction}
        trans.wagers = res.Wagers
        vm.transactionDetails = trans
        vm.collapse=false
      })

    },
    replaceEuro(wagerText){
      return wagerText.replace("&euro;", "£")
    }
  }
}
</script>

<style scoped>
.transaction-container{
  width: 100%;
  height: max-content;
  min-height: 3.125rem;
  border-radius: 0.25rem;
  background-color: #FFFFFF;
  background-color: var(--geoff-bg-section);
}
.w-40{
  width: 35%;
}
.w-60{
  width: 65%;
}
.collapse-enter-active, .collapse-leave-active {
    transition: height 0.2s ease;
}
.collapse-enter-to, .collapse-leave-from{
    height: max-content;
    transition: height 0.2s ease-out;
}

.collapse-btn {
  transition: transform 0.2s;
}
.reverse{
  transform: rotate(180deg);
}
</style>