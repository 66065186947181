import {authApiService} from "@/services/betslip/_services";
import {AUTH_TOKEN_KEY, AUTH_TOKEN_EXPIRED_AT, BETSLIP_ID_BACKUP} from "@/services/betslip/_constants";


const state = {
    authToken: null,
    authTokenExpiredAt: null,
    backupId: null,
    betsLength: 0,
    betslip: {
        bets:[],
        wagers:[]
    },
    betslipAdditionInfo: []
}
const getters = {
    BETSLIP_ID(state){
        return state.backupId
    },
    BETS_LENGTH(state){
        return state.betsLength
    },
    BETSLIP(state){
        return state.betslip
    },
    BETSLIP_AUTH_TOKEN(state) {
        return state.authToken
    },
    BETSLIP_ADDITIONAL_INFO(state){
        const result = state.betslip?.bets.map(el => {
            const info = state.betslipAdditionInfo.find(addItem => 
                el.eventFileName === addItem.eventFile &&
                el.eventGroup === addItem.eventGroup &&
                el.eventsNumber === addItem.eventIdx)
            const eachWayStr = info?.oddsnum && info?.oddsden && info?.numberOfPlaces ? `${info.oddsnum}/${info.oddsden} 1-${info.numberOfPlaces}` : null

            return {
                ...el,
                ...(eachWayStr ? { eachWayStr: eachWayStr } : {})
            }
        })
        return result
    },
}
const mutations = {
    SET_STATE(state, data){
      for (let key in data){
        state[key] = data[key]
      }
    }
}
const actions = {
    async BETSLIP_LOGIN({commit}, authData){
        return new Promise((resolve, reject)=>{
            if (localStorage.getItem(AUTH_TOKEN_KEY)) resolve()
            authApiService
                .login({login: authData.username, password: authData.password})
                .then((res)=> {
                    if (!res?.access_token) {
                        reject("Your account setup is incomplete and requires assistance from our team. Please contact support for help in completing the setup.")
                    }
                    localStorage.setItem(AUTH_TOKEN_KEY, res.access_token)
                    localStorage.setItem(AUTH_TOKEN_EXPIRED_AT, res.expires_at)
                    commit('SET_STATE', {authToken: res.access_token} )
                    commit('SET_STATE', {authTokenExpiredAt: res.expires_at} )
                    resolve()
                })
                .catch(error=>reject(error))
        })
    },
    BETSLIP_LOGOUT({commit}){
      commit('SET_STATE', {
          authToken: null,
          authTokenExpiredAt: null,
          backupId: null,
      })
        localStorage.removeItem(AUTH_TOKEN_KEY)
        localStorage.removeItem(AUTH_TOKEN_EXPIRED_AT)
        localStorage.removeItem(BETSLIP_ID_BACKUP)
    },
    async SET_BETSLIP_ID({commit}, value){
        return new Promise(resolve=>{
            commit('SET_STATE', {backupId: value} )
            localStorage.setItem(BETSLIP_ID_BACKUP, value)
            resolve()
        })
    },
    async SET_BETSLIP({commit}, value){
        return new Promise(resolve=>{
            commit('SET_STATE', {betslip: value})
            resolve()
        })
    },
    SET_BETSLIP_ADDITIONAL_INFO({commit}, value){
        commit('SET_STATE', {betslipAdditionInfo: value})
    },
    SET_BETS_LENGTH({commit}, value){
        commit('SET_STATE', {betsLength: value})
    },
    CLEAR_BETSLIP({commit}){
        commit('SET_STATE', {betslip: {bets:[], wagers:[]}})
        commit('SET_STATE', {betsLength: 0})
        commit('SET_STATE', {backupId: null})
        localStorage.removeItem(BETSLIP_ID_BACKUP)
    }
}

export default { state, getters, mutations, actions }
