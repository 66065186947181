<template>
<div class="h-100 py-1">
  <div class="d-flex w-100 justify-content-between align-items-center px-2">
    <div class="d-flex justify-content-center align-items-center">
      <div class="fw-600 item-title">
        <span> {{ meetingPlaceTime }} </span>
        <span class="fs-14 geoff-text-secondary" v-if="raceTitle">{{ raceTitle }}</span>
      </div>
      <info-icon
        v-if="race.guarantee.toLowerCase()==='best odds guaranteed'"
        class="fs-12 ms-2"
        data-toggle="tooltip"
        data-placement="top"
        title="Best odds guaranteed"
      >BOG</info-icon>

      <div class="fs-14 ms-2">{{ race.status }}</div>
    </div>
    <div class="text-center m-2 fs-14 fst-italic">{{race.plText}}</div>
  </div>

  <div v-if="isSortable" class="race-sub-header geoff-bg-section my-2 p-2">
    <div>
      <div v-if="isAnyRowExpands" class="geoff-text-note fs-12 text-start mt-1 add-info-wr">Click selection for Timeform Verdict</div>
    </div>
    <select v-model="selectedSort" class="opt-select px-2 py-1 fs-14">
      <option v-for="opt in ENUMS.sortOptions" :key="opt.id" :value="opt.id">{{ opt.name }}</option>
    </select>
  </div>
  <!-- <div class="mx-2 geoff-text-secondary fs-14" v-if="race.raceTitle">{{ race.raceTitle }}</div> -->
  <!--  <div v-if="race.Status !== 'off'"></div>-->
    <!-- v-for="rc in race.Races.filter(r=> (race.Status === 'Off' || r.price!=undefined))" -->

    <race-item
      v-for="rc in sortedItems"
      :key="rc.idx"
      :sport="sport"
      :race="rc"
      :hide-odds="race.status === 'Off'"
      :expandable="isAnyRowExpands"
    />

    <div 
      v-if="analystVerdict" 
      class="fs-14 geoff-text-secondary"
      :class="{'m-2': $store.state.isMobile}">
      <b>Timeform Analyst Verdict:</b> {{ analystVerdict }}
    </div>
</div>

</template>

<script>
import { GetMeetingTimeOfDay } from "@/http/utils"
import RaceItem from "@/components/Event/RaceItem";
import InfoIcon from "@/components/UI/InfoIcon";
import { mapGetters } from "vuex";

export default {
name: "RaceEvent",
  components: {InfoIcon, RaceItem},
  props:['race', 'sport'],
  data:()=>{
    return {
      selectedSort: null
    }
  },
  mounted() {
    // default sort option: from user or default
    this.selectedSort = this.USER?.preferences?.sortRacing || this.ENUMS.sortOptions.find(el => el.isDefault)?.id
  },
  computed: {
    ...mapGetters(['USER']),
    isSortable () {
      return this.sport?.isSortable
    },
    isAnyRowExpands () {
      return this.race.events.some(el => el.additionalInfo?.performanceComment || el.additionalInfo?.analystsComments)
    },
    sortedItems () {
      const result =  this.race.events
        .filter(r=> (this.race.status === 'Off' || r.price!=undefined))

      if (!this.isSortable) {
        return result
      }

      switch (this.selectedSort) {
        case 2: {
          return result
            .sort((a,b) => {
              if ( a.selectionNumber < b.selectionNumber ){
                return -1;
              }
              if ( a.selectionNumber > b.selectionNumber ){
                return 1;
              }
              return 0;
            })
        }
        default: {
          return result
        }
      }
    },
    raceTitle() {
      return this.race?.events[0]?.additionalInfo?.raceTitle
    },
    analystVerdict () {
      return this.race?.events[0]?.additionalInfo?.raceAnalystVerdict
    },
    meetingPlaceTime() {
      return `${this.race.meetingName} ${GetMeetingTimeOfDay(this.race.hour, this.race.minute)}`
    }
  },
  methods:{}
}
</script>

<style scoped lang="scss">
  .item-title {
    span {
      display: inline-block;
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .race-sub-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius:  0.25rem;
    font-style: italic;
  }

  .opt-select {
    background-color: var(--geoff-bg-active);
    border-radius: 0.25rem;
    border: none;
    font-style: normal;

    &:focus-visible,
    &:focus{
      outline: none;
    }
  }
</style>