<template>
<no-events v-if="!event.meetings" :title="sport.title" />
<div v-else class="p-n geoff-bg-section mb-2">
  <div>{{event.EventName || event.eventName}}</div>
      <!-- v-for="(meeting, index) in event.Meetings" -->
  <sport-races-event-meeting
      v-for="(meeting, index) in event.meetings"
      :key="index"
      :meeting="meeting"
      :sport="sport"
      :sport-code="sportCode"
      class="my-2"
  />
</div>
</template>

<script>
import SportRacesEventMeeting from "@/components/Sport/Races/RacesEventMeeting";
import NoEvents from "@/components/Sport/NoEvents";
export default {
name: "SportRacesEvent",
  components: {NoEvents, SportRacesEventMeeting},
  props: ['event', 'sport', 'sportCode']
}
</script>

<style scoped>
</style>