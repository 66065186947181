<template>
  <!-- <div v-if="!price || ['NO',''].indexOf(price)!==-1" class="blank"></div>
  <div v-else -->
    <div v-if="isHide && showEmptyBtn" class="blank"></div>
    <div v-else-if="isHide && !showEmptyBtn"></div>
    <div v-else
        ref="button"
        class="odd-btn fw-500 fs-16 cursor-pointer"
        :class="{
          'color': color,
          'selected': isSelected.length,
          'best-odds-guaranteed':
          bog,
          'hoverable': !$store.state.isMobile,
          'is-add-info': showAddInfo && race?.selOption
          }"
        @click="addBet"
    >
      <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span v-else class="text-center">
        <span v-if="showAddInfo && race?.selOption" class="odd-add-info-val">{{ race.selOption }}</span>
        <span>{{glb.formatPrice(price)}}</span>
      </span>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import {authApiService, betslipApi} from "@/services/betslip/_services";
import {mapActions, mapGetters} from "vuex";

export default {
name: "OddBtn",
props: {
  color:{
    type: Boolean,
    default: false
  },
  bog:{
    type: Boolean,
    default: false
  },
  sp:{
    type: Boolean,
    default: false
  },
  race: {
    type: Object,
    required: true
  },
  selection:{
    type: Number,
  },
  showEmptyBtn: {
    type: Boolean,
    default: true
  },
  showAddInfo: {
    type: Boolean,
    default: true
  }
},
data:()=>{
  return{
    loading: false,
  }
},
setup(){
  const toast = useToast()
  return { toast }
},
computed:{
  ...mapGetters(['BETSLIP']),
  isSelected(){
    if (!this.BETSLIP) return false
    const vm = this
    let bets = []
    if ((this.race.Code || this.race.code || this.race.EventGroup) && this.race.awayTeam){ // casediff
      let eventGroup = parseInt(vm.race.eventGroup?vm.race.eventGroup:vm.race.EventGroup)
      let eventFileName = vm.race.eventfile
      if (vm.race.eventFileName){
        eventFileName = vm.race.eventFileName
      } else if (vm.race.Code || vm.race.code){ // casediff
        eventFileName = vm.race.Code || vm.race.code // casediff
      }
      let eventsNumber = vm.race.Events?parseInt(vm.race.Events):0
      bets = this.BETSLIP.bets.filter(b=>{
        return (b.eventFileName === eventFileName &&
        b.selectionNumber === vm.selection &&
        b.eventsNumber === eventsNumber &&
        b.eventGroup === eventGroup)
      })
    } else {
      bets = this.BETSLIP.bets.filter(b=>{
        return (b.eventFileName === vm.race.eventfile &&
        b.selectionNumber === parseInt(vm.race.selection) &&
        b.eventsNumber === parseInt(vm.race.Events) &&
        b.eventGroup === parseInt(vm.race.EventGroup) &&
        ((b.priceCode==='S' && this.sp) || (b.priceCode!=='S' && !this.sp)))
      })
    }
    return bets
  },
  price(){
    if (this.sp) return  'SP'
    if (this.race.price) return this.race.price
    let price
    switch (this.selection) {
      case 0:
        price = this.race.firstodds?this.race.firstodds:this.race.homeodds
        break
      case 1:
        price = this.race.secondodds?this.race.secondodds:this.race.drawodds
        break
      case 2:
        price = this.race.thirdodds?this.race.thirdodds:this.race.awayodds
        break
    }
    return price
  },
  isHide() {
    return !this.price || ['NO',''].indexOf(this.price)!==-1
  }
},
methods:{
  ...mapActions(['SET_BETSLIP']),
  logout(){
    this.$store.dispatch('LOGOUT')
  },
  addBet(){
    const vm = this
    if (vm.loading || !vm.price || ['NO',''].indexOf(vm.price)!==-1) return
    vm.loading = true
    if (vm.isSelected.length){
      let bet = vm.isSelected[0]
      betslipApi(authApiService.logout).deleteBetFromBetslip(this.BETSLIP.id, bet.id, true)
        .then(data=>vm.SET_BETSLIP(data))
        .catch(err=>{
          vm.toast.error(err.toString())
          console.error(err)
        })
        .finally(()=>vm.loading = false)
    } else {
      let data = {}
      if (this.sp){
        data = {
            eventfile: this.race.eventfile?this.race.eventfile:this.race.EventFile,
            EventGroup: this.race.EventGroup,
            Events: this.race.Events,
            selection: this.race.selection,
            coi: this.race.coi,
            pricecode: "S",
            price: this.race.price,
        };
      } else {
        let price = this.race.homeodds || this.race.firstodds;
        let priceCode = this.race.homepricecode;
        let gPrice = this.race.home_gprice;
        if (this.selection === 1) {
            price = this.race.drawodds || this.race.secondodds;
            priceCode = this.race.drawpricecode;
            gPrice = this.race.draw_gprice;
        }
        if (this.selection === 2) {
            price = this.race.awayodds || this.race.thirdodds;
            priceCode = this.race.awaypricecode;
            gPrice = this.race.away_gprice;
        }
        // let eventId = this.race.EventId ? this.race.EventId : this.race.Id;
        let eventId = (this.race.EventId || this.race.eventId) ? (this.race.EventId || this.race.eventId) : this.race.Id; // casediff
        if (eventId == null || typeof eventId == "undefined") eventId = this.race.eventId;
        if (eventId == null || typeof eventId == "undefined")
            eventId = this.race.Events;
        if (this.race.SelName || this.race.selName || this.race.ALT) { // casediff
            if (typeof this.race.eventfile != "undefined") {
                eventId = this.race.Events;
                gPrice = this.race.gprice;
                gPrice = gPrice.substring(0, 1);
                data = {
                    eventfile: this.race.eventfile,
                    EventGroup: this.race.EventGroup,
                    Events: eventId,
                    selection: this.selection,
                    pricecode: this.race.pricecode,
                    price: this.race.price,
                    gpricecode: this.race.gpricecode,
                    gprice: gPrice,
                    coi: this.race.coi,
                };
            } else {
                gPrice = this.race.gprice;
                gPrice = gPrice.substring(0, 1);
                data = {
                    eventfile: this.race.EventFile,
                    EventGroup: this.race.EventGroup,
                    Events: this.race.Events,
                    selection: this.selection,
                    pricecode: this.race.pricecode,
                    price: this.race.price,
                    gpricecode: this.race.gpricecode,
                    gprice: gPrice,
                };
            }
        }
        else {
            if (eventId == null || undefined) {
                eventId = "0";
            }
            if (gPrice == null || undefined) {
                gPrice = "0";
            }
            data = {
                eventfile: this.race.Code || this.race.code, // casediff
                EventGroup: this.race.eventGroup,
                Events: "0",
                selection: this.selection,
                pricecode: priceCode,
                price: price,
                gpricecode: priceCode,
                gprice: gPrice,
            };
        }
        //FIX EVENS
        if (data.price === "EVENS") data.price = "Evens"; // converts to 1-1 on the server side
      }
      betslipApi(this.logout)
          .addBetToBetslip(
              vm.convertOldFormattedBet(data),
              this.$store.state.betslipStore.backupId,
              true)
          .then(data=>{
            vm.SET_BETSLIP(data)
            if (this.$refs?.button?.getBoundingClientRect && this.isSelected.length){
              this.emitter.emit('odd-btn-click', this.$refs.button.getBoundingClientRect())
            }
          })
          .catch(err =>{
            vm.toast.error(err.toString())
            console.error(err)
          })
          .finally(() => {
            vm.loading = false
          })
    }
  },
  convertOldFormattedBet(oldFormattedBet) {
    if (!oldFormattedBet) {
      return null;
    }
    return {
      coi: oldFormattedBet.coi || "000",
      eventFileName: oldFormattedBet.eventfile,
      eventGroup: !isNaN(Number(oldFormattedBet.EventGroup)) ? Number(oldFormattedBet.EventGroup) : 0,
      eventsNumber: !isNaN(Number(oldFormattedBet.Events)) ? Number(oldFormattedBet.Events) : 0,
      gPrice: !isNaN(Number(oldFormattedBet.gprice)) ? Number(oldFormattedBet.gprice) : 0,
      gPriceCode: oldFormattedBet.gpricecode,
      isAvailable: false,
      isEachWay: false,
      price: oldFormattedBet.price,
      priceCode: oldFormattedBet.pricecode,
      selectionNumber: !isNaN(Number(oldFormattedBet.selection)) ? Number(oldFormattedBet.selection) : 0,
    };
  }
}
}
</script>

<style scoped lang="scss">
.odd-btn{
  /* width: 4rem; */
  width: 3.125rem;
  height: 3.125rem;
  /* padding: 0.5rem 0.25rem; */
  border-radius: 0.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--geoff-bg-btn-1);
  gap: 0.125rem;

  .odd-add-info-val {
    color: var(--geoff-txt-note);
    font-size: 0.825rem;
    display: block;
    text-align: center;
  }
}

.match-odd {
  width: 4rem;
  height: 1.9rem;
}

.slider-odd {
  width: 100%;
  height: 2rem;
  line-height: 1.25;

  &.is-add-info {
    height: 2.5rem;
  }
}

.blank{
  width: 3.125rem;
  height: 100%;
}
.color{
  background: var(--geoff-bg-btn-2);
}
/* .best-odds-guaranteed{
  background: var(--geoff-accent);
  color: var(--geoff-txt-invert);
}

.best-odds-guaranteed.selected, */
.selected{
  /* color: #FFFFFF; */
  color: var(--geoff-text-main);

  background: var(--geoff-bg-btn-3);
  box-shadow: inset 0 0 0 1px var(--geoff-outline-1);
  font-weight: 700;
}
@media (pointer: fine) {
  .odd-btn:active,
  .odd-btn:hover{
    transition: 0.1s;
    background: #FFD60A;
  }
}

</style>