<template>
  <div class="promo-slider-content">
    <swiper
      v-if="promos.length && !$store.state.isMobile"
      :navigation="false"
      :pagination="{
        clickable: true,
      }"
      :modules="modules"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
      :loop="true">
      
      <swiper-slide 
        v-for="(promo, index) in promos"
        :key="index"
        class="slide-item"
      >
        <div class="slide-content" @click="goToPromoLink(promo)">
          <img class="slide-image" :src="promo.image.data.full_url">
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay , Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

export default {
  name: "PromoSlider",
  components: {Swiper, SwiperSlide},
  data: () => {
    return {
      promos: []
    }
  },
  setup() {
    return {
      pagination: {
        clickable: true
      },
      modules: [ Autoplay, Navigation, Pagination],
    };
  },
  mounted() {
    this.load()
  },
  methods: {
    load(){
      const vm = this
      vm.loading = true
      vm.API.getPromotions()
      .then(data=>{
        vm.promos = data.data.filter(el => el.image?.data?.full_url)
      })
      .finally(()=>vm.loading=false)
    },
    goToPromoLink(promo){
      if (promo.isExternalUrl){
        window.open(promo.link)
      } else {
        this.$router.push({name: 'promo', params:{page: promo.pageUrl}})
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .promo-slider-content {
    background-color: var(--geoff-bg-section);
    border-radius: 0.25rem;
    overflow: hidden;

    .slide-item {
      cursor: pointer;

      .slide-content {
        position: relative;
      }
    }
  }
</style>