<template>
  <div class="text-ellipsies">{{ historyRace.position }}</div>
  <div class="text-ellipsies">{{ historyRace.courseName }}</div>
  <div class="text-ellipsies">{{ formatedDate }}</div>
  <div class="text-ellipsies">Class {{ historyRace.race.raceClass }}</div>
  <div class="text-ellipsies">{{ historyRace.race.numberOfRunners }} Ran</div>
  <div class="text-ellipsies">{{ historyRace.race.distanceText }} </div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: "RaceHistoryItem",
    props:['historyRace'],
    computed:{
      formatedDate() {
        return moment(this.historyRace.race.meetingDate).format('DD MMM YY')
      }
    }
  }
</script>