<template>
  <div
      v-for="sport in API.SPORTS"
      :key="sport.title"
      @click="$router.push({name: 'sport', params: {sportName: sport.title}})"
      class="w-100 px-3 text-capitalize cursor-pointer geoff-border-bottom border-1"
  >
    <div class="d-flex justify-content-between py-3 px-1 align-items-center">
      <div class="d-flex align-items-center justify-content-center">
        <!-- <div class="sport-icon me-2" :style="{backgroundImage: 'url('+sport.img+')'}"></div> -->
        <div class="sport-icon me-2" v-html="sport.svg"></div>
        <div>{{sport.title}}</div>
      </div>
      <img :src="require('../assets/to-right-arrow.svg')">
    </div>
  </div>
</template>

<script>
export default {
name: "SportsView",
}
</script>

<style scoped>
.sport-icon{
  height: 1.75rem;
  width: 1.75rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  /* filter: brightness(0) drop-shadow(0px 0px 0px black); */
  color: #fff;
}
</style>