<template>
<div class="p-3">
  <pre-loader v-if="loading"/>
  <form v-show="!loading" @submit.prevent="submit" novalidate="novalidate">
  <div class="fs-18 fw-600 mt-4 mb-3">Change Password</div>
  <div class="fs-12 text-secondary mb-3">Only letters and numbers are allowed.</div>
  <vue-input
    name="current-password"
    autocomplete="current-password"
    label="Current Password"
    type="password"
    pattern="[a-zA-Z0-9]+"
    required
    v-model="oldPassword"
  />
  <vue-input
    name="new-password"
    autocomplete="new-password"
    label="New Password"
    type="password"
    pattern="[a-zA-Z0-9]+"
    required
    v-model="newPassword"
  />
  <vue-input
    name="confirm-password"
    autocomplete="new -password"
    label="Confirm Password"
    type="password"
    pattern="[a-zA-Z0-9]+"
    required
    v-model="confirmPassword"
  />
  <vue-button cls="main" class="my-5">
    Change My Password
  </vue-button>
  </form>
</div>
</template>

<script>
import VueInput from "@/components/UI/VueInput";
import VueButton from "@/components/UI/VueButton";
import PreLoader from "@/components/UI/PreLoader";
export default {
  name: "ChangePasswordView",
  components: {PreLoader, VueButton, VueInput},
  data: ()=>{
    return{
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      loading: false,
    }
  },
  methods:{
    submit(){
      const vm = this
      const userCreds = JSON.parse(localStorage.getItem('userCreds'))
      if (vm.oldPassword !== userCreds.password){
        vm.$toast.warning("Current password isn't correct!")
        return
      }
      if(vm.newPassword.length < 5 ) {
        vm.$toast.warning("New password must be at least 5 characters long!")
        return
      }
      if(vm.newPassword.length > 12 ) {
        vm.$toast.warning("New password must contains maximum 12 characters!")
        return
      }
      if(!/^[a-zA-Z0-9]+$/.test(vm.newPassword) ) {
        vm.$toast.warning("New password must contains only alpha and numeric characters!")
        return
      }
      if (vm.newPassword !== vm.confirmPassword){
        vm.$toast.warning("Confirmed password isn't match with new password!")
        return
      }
      vm.loading = true
      vm.API.changePassword(
          userCreds.username,
          vm.oldPassword,
          vm.newPassword,
          vm.confirmPassword,
      ).then((res)=>{
        console.log(res)
        localStorage.setItem('userCreds', JSON.stringify({username:userCreds.username, password:vm.newPassword}))
        vm.$toast.success('Password was changed successfully!')
      })
      .catch(error=>vm.$toast.error(error))
      .finally(()=>vm.loading=false)
    }
  }
}
</script>

<style scoped>

</style>