<template>
  <div :class="{'d-none': noEvents && !races.length}">
    <div v-if="!column && showHeader" class="mb-2 fw-600 fs-6 p-n sub-header geoff-bg-section" :class="{'rounded-3': !$store.state.isMobile}">
      <div class="d-flex align-items-center gap-3">
        <div>Next {{sport.titleSlider ? sport.titleSlider : sport.title }}</div>
        <div>
          <slot name="subtoggle"></slot>
        </div>
      </div>
    </div>
  
    <div class="next-events-wrap position-relative" :class="{'next-events-wrap-show-full': showFull, 'next-events-wrap-desktop':!$store.state.isMobile}">
      <pre-loader v-if="loading"/>
      <div
        v-else-if="races.length" 
        class="next-events-container pb-2"
        :class="{'px-2': $store.state.isMobile, 'd-flex': !column }">
        <div
          v-for="(eventRace, index) in races"
          :key="index" class="next-event-wr"
        >
          <div class="next-event" :class="{'me-0': column}">
            <div
              class="next-event__header d-flex justify-content-between align-items-center p-n cursor-pointer"
              @click="goToFullRacePage(eventRace)"
            >
              <div class="next-event__name text-nowrap fs-13 fw-600">{{eventRace.meetingName}} {{eventRace.meetingTimeStr}}
              </div>
              <div v-if="$store.state.isMobile" class="fst-italic fs-8 mx-2" style="max-width: 55%">{{ eventRace.plText }}</div>
              <div class="d-flex">
                <info-icon
                  v-if="eventRace.isBOG"
                  class="fs-12 me-1"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Best odds guaranteed"
                >BOG</info-icon>
                <RaceCountdown :time="eventRace.timeText"/>
              </div>
            </div>
            <div class="next-event-races" v-if="eventRace.events" :class="{'next-event-races-show-full': showFull}">
              <div v-for="race in eventRace.events" :key="race.idx" class="d-flex justify-content-between align-items-center p-n">
                <div class="fs-14 d-flex align-items-center next-race-item-title-wrap">
                  <div class="d-flex align-items-center w-100">
                    <race-number class="me-2" :race-number="race.selectionNum" :sport="sport"/>
                    <div v-if="race.additionalInfo?.silkLogoUrl" class="racer-image-wr">
                      <img :src="race.additionalInfo.silkLogoUrl" alt="">
                    </div>
                    <div class="next-race-item-title text-truncate">
                      <div class="text-truncate" data-bs-toggle="tooltip" data-bs-placement="top" :title="race.selectionName">{{ race.selectionName }}</div>
                      <div v-if="race.jockey" class="text-secondary fs-11 text-start mt-1">{{ race.jockey }}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex race-odds-wr">
                  <odd-btn-normalize
                    class=" odd-btn odd-btn-desktop"
                    v-if="race.price && race.gPrice && race.priceLink1 !== 'NR'"
                    :bog="race.isBOGEvent"
                    :race="race"
                    :selection="parseInt(race.selectionIdx)"
                  />
                  <odd-btn-normalize
                    class="odd-btn odd-btn-desktop"
                    :race="race"
                    :selection="parseInt(race.selectionIdx)"
                    sp
                  />
                </div>
              </div>
            </div>
            <div v-if="$store.state.isMobile" @click="goToFullRacePage(eventRace)" class="next-event__footer cursor-pointer fs-14 p-n text-secondary"><span>View Full Race</span></div>
            <div v-else class="next-event__footer next-event__footer-desktop d-flex justify-content-between p-n">
              <div class="fst-italic fs-10 me-2 text-truncate">{{ eventRace.plText }}</div>
              <vue-button @click="goToFullRacePage(eventRace)" cls="base" class="p-1 w-auto" sm>View Full Race</vue-button>
            </div>
          </div>
        </div>
      </div>
      <no-events v-else-if="!column" :title="sport.title"/>
    </div>
  </div>
</template>
  
<script>
  
  import { GetMeetingTimeOfDay } from "@/http/utils"
  import { getNextRaces } from "@/http/api";
  import RaceNumber from "@/components/UI/RaceNumber";
  import OddBtnNormalize from "@/components/UI/OddBtnNormalize";
  import PreLoader from "@/components/UI/PreLoader";
  import NoEvents from "@/components/Sport/NoEvents";
  import RaceCountdown from "@/components/UI/RaceCountdown";
  import VueButton from "@/components/UI/VueButton";
  import InfoIcon from "@/components/UI/InfoIcon";
  
  
  export default {
  name: "NextEvents",
    components: {
      InfoIcon,
      VueButton,
      RaceCountdown,
      NoEvents,
      PreLoader,
      OddBtnNormalize,
      RaceNumber
    },
    props:{
      sport: {
        type: Object,
        required: true
      },
      sportCode: {
        type: String,
      },
      disableExpand: {
        type: Boolean,
        default: true
      },
      column:{
        type: Boolean,
        default: false
      },
      noEvents:{
        type: Boolean,
        default: false
      },
      showToggle:{
        type: Boolean,
        default: false
      },
      showHeader:{
        type: Boolean,
        default: true
      },
    },
    data: ()=>{
    return {
      showFull: false,
      loading: true,
      mounted: true,
      races: [],
      intervalLoad: null,
      toggleSport: {}
    }
  },
  watch:{
    sportCode(){
      this.loadData()
    }
  },
  mounted() {
    const vm = this
    vm.loadData()
    vm.intervalLoad = setInterval(()=>vm.loadData(), 5000)
    this.toggleSport = this.API.SPORTS.find(sport => sport.title.toLowerCase() === 'football')
  },
  beforeUnmount(){
    this.mounted = false
    if (this.intervalLoad) clearInterval(this.intervalLoad)
  },
  methods:{
    loadData:function(){
      const vm = this
      getNextRaces(this.sportCode? this.sportCode: this.sport.code)
        .then((liveraces)=>{
          // precalculate some field
          liveraces = liveraces.map(el => {
            return {
              ...el,
              meetingTimeStr: GetMeetingTimeOfDay(el.hour, el.minute),
              isBOG: el.guarantee?.toLowerCase() === 'best odds guaranteed',
              events: el.events.map(racer => {
                return {
                  ...racer,
                  selectionNum: parseInt(racer.selectionNumber), // as number (not string)
                  isBOGEvent: racer.tooltip.toLowerCase()==='best odds guaranteed'
                  
                }
              })
            }
          })
          // sort racers by racer number for greyhounds
          if (['g,q', '$'].includes(vm.sport.code)){
            liveraces.forEach(race=>{
              const sortedEvents = race.events.sort((a,b)=>{
                if (a.selectionNum < b.selectionNum) return -1
                else return 1
              })
              race.events = sortedEvents
            })
          } 
          vm.loading = false

  
          vm.races = liveraces;
          this.nextLoaded(liveraces)
          
        })
        .catch(error=> {
          console.error('NEXT RACES',error)
          vm.$emit('dataloaded', null)
          // const timeout = 500
          // setTimeout(()=>{
          //   vm.loadData()
          // }, timeout)
        })
      // .finally(()=>vm.$emit('dataloaded'))
    },
  
    nextLoaded(races){
          let nextraces = null
          // horse racing:
          // SD365 - Racing UK stream
          // TRP - ATR stream
          if (['H,h,o'].includes(this.sport.code)) {
            nextraces = [...races].map(el => {
              let type = ''
              if (el.eventFile) {
                if (el.eventFile.toLowerCase().includes("HGB00".toLowerCase())) {
                  type = "SD365"
                } else if (el.eventFile.toLowerCase().includes("BFTRP".toLowerCase())) {
                  type = "TRP"
                }
              }
              return {
                name: `${el?.meetingName} ${el?.meetingTimeStr}`, // name and time
                type: type 
              }
            })
            this.nextRacesLoaded = true;
          }
          // grayhounds
          // SD365 races - SIS stream
          // other (AUS and TRP) - ATR stream
          if (['g,q'].includes(this.sport.code)) {
            nextraces = [...races].map(el => {
              const type = el.events && el.events[0].eventFile && el.events[0].eventFile.toLowerCase().includes('DGB00'.toLowerCase()) ? 'SIS' : 'TRP'
              return {
                name: `${el?.meetingName} ${el?.meetingTimeStr}`, // el?.MeetingName,
                type: type
              }
            })
            this.nextRacesLoaded = true;
          }
          this.$emit('dataloaded', nextraces)
    },
    getSelName(selName){
      if (selName && selName.includes('.')){
        return selName.split('.')[1]
      } else {
        return selName
      }
    },
    goToFullRacePage(eventRace){
      if (!this.disableExpand) {
        this.showFull=!this.showFull
        return
      }
      this.$router.push(
         {
            name:'raceEvent',
            params: {
              sportName:this.sport.title,
              ef:eventRace.eventFile,
              eg:eventRace.eventGroup},
            query: {
              ev:eventRace.eventIdx,
              sportCode: this.sportCode? this.sportCode: this.sport.code}
         })
    },
    setActive(sport) {
      this.$emit('setActiveSlider', sport)
    }
  },
  }
  </script>
  
  <style scoped lang="scss">
  .next-events-wrap{
    background-color: var(--geoff-bg-main);
    overflow-x: scroll;
    /*min-height: 9.625rem;*/
    transition: height 0.2s ease;
  }
  .next-events-wrap-desktop{
  
    .next-event-wr {
      width: calc((100% - 1rem)/3);
      width: calc(100% / 3);
    }
    .next-event{
      width: 100%;
      height: auto;
      max-width: unset;
    }
  }
  .next-events-container{
    min-width: max-content;
    // grid-gap:0.5rem;
  }
  .next-event-wr{
    max-width: min(24rem, calc(100vw - 2rem));
  
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  
      &:first-child {
        padding-left: 0rem;
      }
      &:last-child {
        padding-right: 0rem;
      }
  }
  .next-event{
    width:100%;
    background-color: var(--geoff-bg-section);
    border-radius: 0.25rem;
    transition: height 2s ease;
    overflow: hidden;
  }
  
  .next-event__header{
    height: 2.375rem;
    border-bottom: 1px solid var(--geoff-menu-item-separator);
  }
  .next-event__footer{
    border-top: 1px solid var(--geoff-menu-item-separator);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .next-event__start_at{
    color: #FFFFFF;
    background-color: #FD7760;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    text-align: center;
  }
  .next-event-races{
    overflow-y: scroll;
    max-height: 9.9rem;
    transition: max-height 0.2s ease;
  }
  .next-event-races-show-full{
    /*overflow: hidden;*/
    max-height: 100vh;
  }
  .odd-btn{
    width: 3.75rem;
    height: 2.7rem;
    margin-left: 0.5rem;
  
    &:first-child {
      margin-left: 0rem;
    }
    
  }
  .racer-image-wr {
    max-width: 2rem;
    margin-right: 0.5rem;
  }
  .next-race-item-title-wrap{
    width: calc(100% - 9rem)
  }
  .next-race-item-title{
    width: calc(100% - 1rem);
  }
  @media only screen and (max-width: 1368px){
    .odd-btn-desktop{
      width: 3rem;
    }
    .next-race-item-title-wrap{
      width: calc(100% - 7rem)
    }
  }
  </style>