<template>
<sport-header title="Promos"/>
<!--<sports-bar/>-->
  <pre-loader v-if="loading"/>
  <div v-else>
    <div
      v-for="promo in promos"
      :key="promo.id" class=""
      @click="goToPromoLink(promo)"
    >
      <div class="geoff-bg-main">
      <img :src="promo.image.data.full_url" class="w-100">
      <div class="p-3">
      <div class="fw-600">{{ promo.description_heading }}</div>
      <div class="">{{ promo.description_content }}</div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import SportHeader from "@/components/Sport/SportHeader";
import PreLoader from "@/components/UI/PreLoader";
export default {
  name: "PromosView",
  components: {PreLoader, SportHeader},
  data:()=>{
    return{
      loading: false,
      promos: []
    }
  },
  mounted() {
    this.load()
  },
  methods:{
    load(){
      const vm = this
      vm.loading = true
      vm.API.getPromotions()
      .then(data=>{
        vm.promos = data.data
      })
      .finally(()=>vm.loading=false)
    },
    goToPromoLink(promo){
      if (promo.isExternalUrl){
        window.open(promo.link)
      } else {
        this.$router.push({name: 'promo', params:{page: promo.pageUrl}})
      }
    }
  }
}
</script>

<style scoped>
</style>