<template>
  <div class="slider-toggle-wr">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="slider-toggle-btn width-text"
      :class="{'active': item.value === active}"
      @click="toggleSlider(item)">
      {{ item.label }}
    </div>
  </div>
</template>

<script>

export default {
  name: "ToggleItems",
  components: {},
  props: {
    items: {
      type: Array,
      default: () => { return  []}
    },
    active: {
      type: String,
      default: ''
    }
  },
  data: ()=>{
    return {
      sportsData: []
    }
  },

  methods:{
    toggleSlider(item) {
      this.$emit('setActive', item.value)
    }
  },
}
</script>

<style scoped lang="scss">
</style>