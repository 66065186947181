<template>
  <!-- <div>
    <h1>Account Overview</h1>
  </div> -->
    <div class="geoff-bg-section rounded-3 mb-2 p-3">
      <div class="geoff-border-bottom d-flex justify-content-between pb-2">
        <div class="fw-600">Account Overview</div>
        <refresh-balance :dark="true"/>
      </div>
      <div v-if="$store.state.user.funddetail" class="container-fluid">
        <div class="row">
          <div class="col-6 d-flex justify-content-center align-items-center geoff-border-end">
            <div class="d-flex my-4">
              <!-- <div class="balance-icon-wrap" :style="{'background-image': 'url('+require('../assets/wallet.svg')+')'}"></div> -->
              <div class="balance-icon-wrap" :style="{'background-image': 'url('+require('@/assets/wallet.svg')+')'}"></div>
              <div>
                <div class="fs-14 text-secondary">Current Balance</div>
                <div class="fs-24 fw-600">{{$store.state.user.funddetail.balance}}</div>
              </div>
            </div>
          </div>
          <div class="col-6 d-flex justify-content-center align-items-center">
            <div class="d-flex my-4">
              <!-- <div class="balance-icon-wrap" :style="{'background-image': 'url('+require('../assets/card-tick.svg')+')'}"></div> -->
              <div class="balance-icon-wrap" :style="{'background-image': 'url('+require('@/assets/card-tick.svg')+')'}"></div>
              <div>
                <div class="fs-14 text-secondary">Available Credit</div>
                <div class="fs-24 fw-600">{{$store.state.user.funddetail.availableFunds}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
  import RefreshBalance from "@/components/Sevice/RefreshBalance";
  export default {
    name: "AccoutOverviewView",
    components: {
      RefreshBalance
    }
  }
</script>

<style scoped>
  .balance-icon-wrap{
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: #FFD60A;
    margin-right: 2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 2rem;
  }
</style>