<template>
  <div v-if="competitions.length">
    <div class="side-menu">
      <div 
        class="link-item title"
        @click="()=> { if (isCollapsible){ isExpanded = !isExpanded }}"
      >
        Top Competitions
        <svg  
          v-if="isCollapsible"
          class="icon-expand"
          :class="{'opened': this.isExpanded}"
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 5.72376L10.862 0.861827L11.8048 1.80464L6.00005 7.60937L0.195312 1.80464L1.13812 0.861828L6.00005 5.72376Z" fill="currentColor"/>
          </svg>
      </div>
      <collapse-transition :duration="200">
        <div v-if="this.isExpanded" class="sub-menu">
          <div 
            class="link-item no-img"
            :class="{'active': $route.query.selectedCategoryEventName === lg.competitionName}"
            v-for="(lg, idx) in competitions"
            :key="idx" 
            @click="goToItem(lg)"
          >
            <span>{{ lg.competitionName }}</span>
          </div>

        </div>
      </collapse-transition>
      
    </div>
  </div>

    

</template>

<script>
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'

export default {
  name: "TopCompetitonsView",
  components: {
    CollapseTransition,
  },
  props: {
    sport: {
      type: Object,
      default: () => { return  null}
    },
    isCollapsible: {
      type: Boolean,
      default: true
    }
   
  },
  data: ()=>{
    return{
      isExpanded: true,
      competitions: [],
      expanded: [],
    }
  },
  watch:{
    '$route.query'(val){
      if (val?.region && val?.selectedCategoryEventName) {
        this.expanded.push(val?.region)
      }
    }
  },
  mounted() {
    this.loadData()
  },
  beforeUnmount(){
  },
  computed: {
  },
  methods:{
    loadData() {
      this.API.getSportEventsTopList(this.sport.code)
        .then( (response) => {
          this.competitions = response
        })
    },

    goToItem(item = null) {
      this.$router.push({
        name: 'sport', 
        params:{
          sportName: this.sport.title}, 
          query:{
            category: "Competitions",
            selectedCategoryEventName: item ? item.competitionName : 'All'
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">
  .side-menu {
    font-size: 0.875rem;
  

    .link-item {
      display: grid;
      grid-template-columns: 1.5rem 1fr 1rem 1rem;
      grid-gap: 0.25rem;
      padding: 0 0.5rem;
      align-items: center;
      height: 2.5rem;
      border-top: 1px solid var(--geoff-menu-item-separator);
      cursor: pointer;

      @media (pointer: fine) {
          &:hover {
            background-color: var(--geoff-item-hover);
          }
        }

      &:first-child {
        border-top: none;
      }

      .link-item-logo {
        width: 1rem;
        border-radius: 0.5rem;
      }

      .icon-expand {
        transition: transform 0.2s;
          transform: rotate(-90deg);
        &.opened {
          transform: rotate(0deg);
        }
      }

      &.active{
        box-shadow: inset 2px 0px 0 var(--geoff-accent);
        background-color: var(--geoff-item-active);

        @media (pointer: fine) {
          &:hover {
            background-color: var(--geoff-item-hover);
          }
        }
      }

      &.no-img {
        grid-template-columns: 1fr 1rem;
      }

      &.title {
        font-size: 1rem;
        font-weight: 700;
        grid-template-columns: 1fr 1rem;

      }
    }

  }
</style>