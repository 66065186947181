<template>
  <!-- <div class="hidden"><pre>{{ streamPlayEvent }}</pre></div> -->
    <div v-if="SHOW_CONTROLS" class="mb-2 fs-6 p-n geoff-bg-section" :class="{'rounded-3': !$store.state.isMobile}">
  
      <div>
        <div v-if="!$store.state.authToken" class="p-2 text-center fs-12">Please login to your account to view live streams of games/races.</div>
  
        <div v-else>
          <!-- Title Row -->
          <div class="stream-title d-flex h-100 align-items-center w-100 justify-content-between">
            <div class="fw-600">Live Stream</div>
            <div class="d-flex align-items-center">
              <div class="mx-2 fs-10">Show stream</div>
              <vue-switch v-model="show"/>
            </div>
          </div>
  
          <!-- Selects Row -->
          <div
            v-if="show"
            ref="streamWrap"
            id="streamWrap"
            class="select-row-wr py-1 align-items-center"
            :class="{
              'justify-content-between': show,
              'justify-content-end': !show,
              'rounded-3': !$store.state.isMobile
            }"
          >
            <div v-if="show" class="flex-fill h-100 d-flex justify-content-center align-items-center cursor-pointer pe-2XXX" >
              <select v-model="selectedStream" class="h-100 d-flex fs-12 justify-content-between w-100 border-0 p-2 rounded-3 geoff-bg-btn-1 ">
                <!-- availableStreamTypes -->
                <option v-for="(type, idx) in availableStreamTypes" :value="type.id" :key="idx"> {{ type.name }}</option>
              </select>
            </div>
  
            <div v-if="streamEvents.length && show" class="flex-fill h-100 d-flex justify-content-center align-items-center">
  
              <select v-if="selectedStream==='SD365' && selectedEvent && loaded" @change="selectEvent" class="h-100 d-flex fs-12 justify-content-between w-100 border-0 p-2 rounded-3 geoff-bg-btn-1">
                <option
                    v-for="ev in validStreamEvents"
                    :key="ev.fixtureId"
                    :value="ev.fixtureId"
                    :selected="ev.fixtureId===selectedEvent.fixtureId"
                >{{ ev.label }}</option>
              </select>
  
              <!-- <select v-if="selectedStream==='SD365_1' && selectedEvent && loaded" @change="selectEvent" class="h-100 d-flex fs-12 justify-content-between w-100 border-0 p-2 rounded-3 geoff-bg-btn-1">
                <option
                    v-for="ev in validStreamEvents"
                    :key="ev.id"
                    :value="ev.id"
                    :selected="ev.id===selectedEvent.id"
                >{{ ev.location +' '+  displayEventTime(ev.startDateTime)}}</option>
              </select> -->
  
              <select v-if="selectedStream==='SIS' && selectedEvent && loaded" @change="selectEvent" class="h-100 d-flex fs-12 justify-content-between w-100 border-0 p-2 rounded-3 geoff-bg-btn-1">
                <option
                    v-for="ev in validStreamEvents"
                    :key="ev.streamId"
                    :value="ev.streamId"
                    :selected="ev.streamId===selectedEvent.streamId"
                >{{ ev.competition + ' ' + ev.startDate.split(' ')[0] }}</option>
              </select>
  
              <select v-if="selectedStream==='TRP' && selectedEvent && loaded" @change="selectEvent" class="h-100 d-flex fs-12 justify-content-between w-100 border-0 p-2 rounded-3 geoff-bg-btn-1">
                <option
                    v-for="ev in validStreamEvents"
                    :key="ev.ID"
                    :value="ev.ID"
                    :selected="ev.ID===selectedEvent.ID"
                >{{ ev.Location +' '+ getATRTime(ev.StartDateTime)}}</option>
              </select>
            </div>
          </div>
  
        </div>
      </div>
  
    </div>
  
  </template>
  
  <script>
  import _ from "lodash"
  import VueSwitch from "@/components/UI/VueSwitch";
  import {useToast} from "vue-toastification";
  import {mapGetters} from "vuex";
  
  export default {
    name: "VideoStreamControl",
    components: {
      VueSwitch
    },
    data:()=>{
      return{
        // show: localStorage.getItem('showStream')? JSON.parse(localStorage.getItem('showStream')) : true,
        loaded: true,
        streamSrc: null,
        streamPlayEvent: null, // {type, id}
        streamId: undefined,
        atrHistory: [], // {'id': number, url: string } - save requested list of id streams to prevent repeated request for the same stream
        streamEvents: [],
        selectedEvent: null,
        RmgAllEvents: [],
        selectedStream: null,
        streamTypes: [
            {id: "TRP", name: "AtTheRaces"},
            {id: "SD365", name: "Racing TV"},
            {id: "SIS", name: "SIS Stream"},
            // {id: "SD365_2", name: "Racing TV 2.0"},
        ],
        HorseRacing: ["1","1001", "1002", "1003", "1009", "1012", "1013", "1014"],
        GreyHoundRacing: ["1202", "1203", "1206", "1207"],
        intervalLoad: null,
        calculatedWidth: 300,
        clickArr: [],
        sportCode: '',
        widthChanged: false
      }
    },
    setup(){
      const toast = useToast()
      return { toast }
    },
    computed:{
  
      show: {
        get () {
          return this.STREAM_SHOW
        },
        set (val) {
          this.$store.dispatch('SET_SHOWN', val)
  
          if (this.intervalLoad) clearInterval(this.intervalLoad)
          this.startStreaming()
          localStorage.setItem('showStream', val)
  
        }
      },
  
      ...mapGetters(['isMobile']),
      ...mapGetters(['STREAM_SHOW', 'STREAM_SRC']),
      ...mapGetters(['SHOW_CONTROLS']),
      ...mapGetters(['VIDEO_WIDTH']),
      ...mapGetters(['NEXT_MEETING']),
      ...mapGetters(['SINGLE_EVENT']),
      ...mapGetters(['STREAM_LOADED']),
  
  
      validStreamEvents(){
        switch (this.selectedStream) {
          case 'SD365': {
            return this.streamEvents.filter(ev => ev.fixture.id && ev.asset && ev.asset[0].location && ev.fixture.time)
          }
          // case 'SD365_1': {
          //   return this.streamEvents.filter(ev => ev.id && ev.location && ev.startDateTime)
          // }
          case 'SIS': {
            return this.streamEvents.filter(ev => ev.streamId && ev.competition && ev.startDate)
          }
          case 'TRP': {
            return this.streamEvents.filter(ev => ev.ID && ev.Location && ev.StartDateTime)
          }
          default: {
            return this.streamEvents
          }
        }
      },
      availableStreamTypes() {
        //
        // let available = ['SD365', 'SIS', 'TRP', 'SD365_2']
        let available = ['SD365', 'SIS', 'TRP']
        switch(this.sportCode) {
          case 'g,q':
          case 'vh':
          case '$': {
            available = ['TRP', 'SIS']
            break
          }
          default: {
            // available = ['SD365', 'TRP', 'SIS', 'SD365_2']
            available = ['SD365', 'TRP', 'SIS']
          }
        }
  
        return available.map( el => {
          return this.streamTypes.find(st => st.id === el)
        })
      },
      eventType() {
        if (!this.SINGLE_EVENT?.eventLeague) {
          return null
        }
        if (/^(.*?(\btrp\b)[^$]*)$/m.test(this.SINGLE_EVENT.eventLeague.toLowerCase())) {
          return 'TRP'
        }
        if (/^(.*?(\bsd365\b)[^$]*)$/m.test(this.SINGLE_EVENT.eventLeague.toLowerCase())) {
          return 'SD365'
        }
        return ''
      }
    },
    watch:{
  
      '$store.state.authToken'(){
        if (this.intervalLoad) clearInterval(this.intervalLoad)
        setTimeout(()=> {
          this.startStreaming();
        })
      },
      // stream provider change
      selectedStream(){
        this.streamId = null
        this.selectedEvent = null
        this.init()
      },
      // stream event change
      selectedEvent(value, oldValue){
        if (oldValue) this.init()
      },
  
      'SINGLE_EVENT.eventTime'(){
        this.streamId = null
        this.selectedEvent = null
        this.init()
      },
  
      'VIDEO_WIDTH'() {
        this.widthChanged = true
      },
  
      "$route.params.sportName"(){
        this.sportCodeUpdate()
      },
    },
    mounted() {
  
      this.sportCodeUpdate()
      this.startStreaming()
    },
    beforeUnmount() {
      if (this.intervalLoad) clearInterval(this.intervalLoad)
    },
    methods:{
      sportCodeUpdate() {
        if (!this.$route.params.sportName) {
          this.sportCode = null
        }
        else {
          const sport = this.API.SPORTS.find(sport=>sport.title.toLowerCase() === this.$route.params.sportName.toLowerCase())
          this.sportCode = sport.code || ''
        }
      },
      startStreaming(){
        const vm = this
        if (vm.show && vm.$store.state.authToken){
          // preset stream provider (stream type)
          if (this.NEXT_MEETING?.type) {
            this.selectedStream = this.NEXT_MEETING.type
          }
          if(!this.selectedStream) {
            switch(this.sportCode) {
              case 'g,q': {
                if (this.SINGLE_EVENT?.eventFile && this.SINGLE_EVENT.eventFile.includes("DGB00")) {
                  this.selectedStream = "SIS"
                }
                else {
                  this.selectedStream = 'TRP'
                }
                break
              }
              case '$':
              case 'vh': {
                this.selectedStream = 'SIS'
                break
              }
              case 'H,h,o': {
                if (this.SINGLE_EVENT?.eventFile) {
                  if (this.SINGLE_EVENT.eventFile.includes("HGB00")) {
                    this.selectedStream = "SD365"
                  } else if (this.SINGLE_EVENT.eventFile !== null && this.SINGLE_EVENT.eventFile.includes("BFTRP")) {
                    this.selectedStream = "TRP"
                  } else if (this.SINGLE_EVENT.eventName){
                    if (this.SINGLE_EVENT.eventName.includes("SD365")){
                      this.selectedStream = "SIS"
                    }
                  }
                }
                else {
                  this.selectedStream = 'SD365'
                }
                break
              }
              default: {
                this.selectedStream = 'SIS'
                this.selectedStream = vm.eventType === 'TRP' ? 'TRP' : 'SD365'
              }
            }
          }
          vm.intervalLoad = setInterval(()=> {
            vm.init()
              .then(() => {
                this.$store.dispatch('SET_STREAM_DATA', {
                    src: this.streamSrc,
                    type: this.selectedStream
                  })
                  .then(() => {
                    vm.$store.dispatch('SET_STREAM_LOADED', true)
                  })
              })
          }, 5000)
        } else {
          if (vm.intervalLoad) clearInterval(vm.intervalLoad)
        }
      },
      init() {
        const vm = this
        if (!vm.selectedStream) {
          vm.loaded = false
          vm.$store.dispatch('SET_STREAM_LOADED', false)
        }
  
        const initProm = new Promise((resolve)=>{
          // obsolete RGM
           if  (vm.selectedStream === "SD365") {
            // NEW RGMLSTART
            vm.API.getRMGEventListNew()
              .then((data) => {
                const cb = {
                  mccItem: data.mccItem?.map(el => {
                      return {
                        ...el,
                        fixtureId: el.fixture.id,
                        assetId: el.asset[0].id,
                        location: el.asset[0].location,
                        label: `${el.asset[0].location} ${vm.displayEventTime(el.asset[0].availableFrom)}`
  
                      }
                    }),
                  currentMccItem: data.currentMccItem?.map(el => {
                      return {
                        ...el,
                        fixtureId: el.fixture.id,
                        assetId: el.asset[0].id,
                        location: el.asset[0].location,
                        label: `${el.asset[0].location} ${vm.displayEventTime(el.asset[0].availableFrom)}`
                      }
                    })
  
                }
                vm.RmgAllEvents = cb.mccItem || [] //Set empty array if undefined
                vm.streamEvents = cb.currentMccItem || []
  
                // add archived event to events list if needed (finished event is opened)
                if (vm.SINGLE_EVENT && vm.SINGLE_EVENT.locationName && vm.SINGLE_EVENT.eventTime && vm.sportCode === "H,h,o") {
                  const isEventInCurrentList = this.streamEvents.some(el => {
                    `${vm.SINGLE_EVENT.locationName} ${vm.SINGLE_EVENT.eventTime}`.toLowerCase() === `${el.label}`.toLowerCase()
                  })
                  if (!isEventInCurrentList) {
                    const archiveEvent = this.RmgAllEvents.find(el => {
                      return `${vm.SINGLE_EVENT.locationName} ${vm.SINGLE_EVENT.eventTime}`.toLowerCase() === `${el.label}`.toLowerCase()
                    })
                    if (archiveEvent) {
                      this.streamEvents = [archiveEvent, ...this.streamEvents]
                    }
                  }
                }
                // single event
                if (vm.SINGLE_EVENT && vm.SINGLE_EVENT.locationName && vm.SINGLE_EVENT.eventTime && !vm.selectedEvent){
                    let singleHorseRace = vm.streamEvents.find(r => 
                        `${ vm.SINGLE_EVENT.locationName} ${vm.SINGLE_EVENT.eventTime}`.toLowerCase() === r.label.toLowerCase() && vm.sportCode === "H,h,o"
                    )
                  if (singleHorseRace){
                      vm.selectedEvent = singleHorseRace
                  }
                  else {
                      vm.selectedEvent = vm.streamEvents[0]
                  }
                }
  
                // next event
                if (vm.NEXT_MEETING && !vm.selectedEvent) {
  
                  let singleHorseRace = vm.streamEvents.find( (r) =>  `${r.label}`.toLocaleLowerCase() === this.NEXT_MEETING.name.toLocaleLowerCase() )
                  if (singleHorseRace){
                      vm.selectedEvent = singleHorseRace
                  } else {
                      vm.selectedEvent = vm.streamEvents[0]
                  }
                }
  
                if (vm.streamEvents.length < 1) {
                  resolve(vm.loaded=true)
                } else {
  
                  // event is over -> set new first event
                  let isSelectedEventAlive = vm.getStreamTypeByEvent(vm.selectedEvent) === 'SD365' && vm.streamEvents.some(el => el.fixtureId === vm.selectedEvent.fixtureId)
  
                  if (!vm.selectedEvent || !isSelectedEventAlive){
                    // console.log('DEBUG is Alive')
                    vm.selectedEvent = vm.streamEvents[0]
                  }
  
                  const data = {
                      fixtureId: vm.selectedEvent.fixtureId, // ----> mccItem.fixture.id с предведущего запроса необходимого эвента
                      assetId: vm.selectedEvent.assetId,    // ----> mccItem.asset.id  c предведущего запроса необходимого эвента
                      userId: vm.$store.state.user.cust_Ref,
                  }
                  
                  if (!vm.widthChanged && vm.streamPlayEvent && vm.streamPlayEvent.type === 'SD365' && vm.streamPlayEvent.id == vm.selectedEvent?.fixtureId) {
                    resolve(vm.loaded=true)
                  }
                  else {
                    vm.widthChanged = false
                    vm.API.getRMGStreamNew(data)
                      .then((stream) => {
                        const searchParams = new URLSearchParams(stream);
                        vm.streamPlayEvent = {
                          id: vm.selectedEvent.fixtureId,
                          type: 'SD365'
                        }
  
                        const video_url =  `${this.API.stream_url_rmg}?${searchParams.toString()}`
  
                        vm.streamSrc = video_url + '&width=' + this.VIDEO_WIDTH
                      })
                      .finally(()=>{
                        resolve(vm.loaded=true)
                      })
                  }
                }
              })
  
          } else if (vm.selectedStream === "TRP") {
              vm.API.getStreamEvents()
                .then((cb) => {
                  let data = cb.Events
                  console.log(cb, "ATR EVENTS VIDEO")
  
                  // CHECKING HERE FOR PAGE/EVENT BASED ON WHICH WE COMPARE THE CONTENT ID OF HORSE RACING AND GREYHOUND AND SET SPORT EVENTS.
                  let sportEvents = []
                  if (vm.sportCode == "H,h,o") {
                      sportEvents = _.filter(data, (e) =>
                          _.some(vm.HorseRacing, (h) => {
                              if (parseInt(e.ContentTypeID) === parseInt(h)) return e
                          })
                      )
                  } else if (vm.sportCode == "g,q") {
                      sportEvents = _.filter(data, (event) =>
                          _.some(vm.GreyHoundRacing, (h) => {
                              if (parseInt(event.ContentTypeID) === parseInt(h)) return event
                          })
                      )
                  } else sportEvents = data
  
                  // single event
                  if (vm.SINGLE_EVENT && vm.SINGLE_EVENT.locationName && vm.SINGLE_EVENT.eventTime && !vm.selectedEvent){
                    const single = sportEvents.find(e =>
                      e.Location.toLowerCase() === vm.SINGLE_EVENT.locationName.toLowerCase()
                      && vm.getATRTime(e.StartDateTime) === vm.SINGLE_EVENT.eventTime
                    )
  
                    if (single) {
                      vm.selectedEvent = single
                    }
                  }
  
                  // next event
                  if (vm.NEXT_MEETING && !vm.selectedEvent ){
                    const single = sportEvents.find(e =>
                      `${e.Location.toLowerCase()} ${vm.getATRTime(e.StartDateTime)}`.toLowerCase() === vm.NEXT_MEETING.name.toLocaleLowerCase()
                    )
  
                    if (single) {
                      vm.selectedEvent = single
                    }
                  }
  
  
                  if (!sportEvents.length){
                    vm.streamPlayEvent = null
                    vm.streamSrc = null
                    // TODO: CLEAR???
                    this.$store.dispatch('CLEAR_STREAM_SRC');
                    vm.selectedEvent = null
  
                    resolve(vm.loaded = true)
                  } else {
                    let isCurrentAlive = vm.getStreamTypeByEvent(vm.selectedEvent) === 'TRP' && sportEvents.some(el => el.ID === vm.selectedEvent.ID)
                    vm.streamEvents = sportEvents
                    if (!vm.selectedEvent || !isCurrentAlive) {
                      vm.selectedEvent = vm.streamEvents[0]
                    }
  
                    // let val =`${partnerCode}:${vm.selectedEvent.ID}:L:${vm.$store.state.user.cust_Ref}:${seedKey}`
                    // let valMd5 = CryptoJS.MD5(val).toString()
                    // const video_url = 'https://bw197.attheraces.com/ps/player/default.aspx?partnercode=pkgc197&eventid='+vm.selectedEvent.ID+'&eventtype=live&userid='+vm.$store.state.user.cust_Ref+'&key='+valMd5
                    // const video_url = 'https://geoffbanksuidev.z33.web.core.windows.net/atr/atr.html?partnercode=pkgc197&eventid='+vm.selectedEvent.ID+'&eventtype=live&userid='+vm.$store.state.user.cust_Ref+'&key='+valMd5
  
                    // console.log("TRP STREAM URL", video_url)
  
                    const historyEvent = vm.atrHistory.find(el => el.id === vm.selectedEvent.ID)
                    if (historyEvent) {
                      vm.streamPlayEvent = {
                        id: vm.selectedEvent.ID,
                        type: 'TRP'
                      }
                      const stream = historyEvent.url
                      const video_url =  `${this.API.stream_url_atr}?stream=${encodeURIComponent(stream)}`
  
  
                      vm.streamSrc = video_url
                      resolve(vm.loaded=true)
                    }
                    else {
                      vm.API.getATRStreamUrl(vm.selectedEvent.ID, vm.$store.state.user.cust_Ref)
                        .then(data => {
                          if (!data.isOK) {
                            this.$toast.error(data.error.errorMessage)
                            resolve(vm.loaded=true)
                            // vm.$store.dispatch('SET_STREAM_LOADED', true)
                            return;
                          }
                          const stream = data.eventInfo.streams.find(el => el.bitrateLevel === 'Adaptive')?.url
                          vm.atrHistory.push({
                            id: vm.selectedEvent.ID,
                            url: stream
                          })
                          vm.streamPlayEvent = {
                            id: vm.selectedEvent.ID,
                            type: 'TRP'
                          }
                          const video_url =  `${this.API.stream_url_atr}?stream=${encodeURIComponent(stream)}`
                          vm.streamSrc = video_url
  
                        })
                        .finally(()=> resolve(vm.loaded = true))
                    }
  
                  }
              })
          } else if (vm.selectedStream === "SIS") {
              vm.API.getSISEvents(vm.sportCode)
                .then((cb) => {
                  let data = cb.currentEvents.length === 0 ? cb.events : cb.currentEvents;
                  let sportEvents = []
                  console.log(
                      data,
                      vm.sportCode,
                      "SIS EVENTS"
                  )
                  data = data.filter((n, i, a) => n === a.find(m => m.streamId === n.streamId))
                  vm.streamEvents = data
                  if (vm.sportCode === "H,h,o") {
                      sportEvents = _.filter(data, (e) => {
                          if (e.sportName === "Horse Racing" || e.sportName === "Other")
                              return e
                      })
                  } else if (vm.sportCode === "g,q") {
                      sportEvents = _.filter(data, (event) => {
                          if (event.sportName === "Greyhounds" || event.sportName === "Other")
                              return event
                      })
                  }
                  if (vm.sportCode){
                      if (sportEvents.length > 0) vm.streamEvents = sportEvents
                  }
  
                  if (!vm.streamEvents.length) {
                      // if(vm.sportCode == "vh" || vm.sportCode =="$") resolve(vm.loaded=true)
                      // vm.toast.warning('No SIS streaming at the moment.')
                      this.$store.dispatch('CLEAR_STREAM_SRC');
                      vm.streamPlayEvent = null
                      vm.streamSrc = null
                      // vm.selectEvent = null
  
                      resolve(vm.selectedStream = "TRP")
                  }
  
                  if (!vm.selectedEvent) {
                    vm.selectedEvent = vm.streamEvents[0]
                  }
                  data = {
                    streamId: vm.selectedEvent.streamId
                  }
                  const prevVal = vm.streamId
  
                  vm.API.getSISStream(data)
                    .then((stream) => {
  
                        if (stream.error) {
                          vm.toast.warning(stream.error)
                          console.error(stream.error)
                        }
                        const video_url = stream.phenixEmbedUrl ? stream.phenixEmbedUrl : ''
                        if (video_url === "") vm.selectedEvent = null
                        if (prevVal !== stream.streamId) {
                          vm.streamPlayEvent = {
                            id: vm.selectedEvent.streamId,
                            type: 'SIS'
                          }
                          vm.streamSrc = video_url
                          vm.streamId = stream.streamId
                        }
                        console.log('vm.streamSrc >>>> ', vm.streamSrc)
                    })
                    .finally(()=>{
                      resolve(vm.loaded=true)
                    })
              })
          }
        })
  
        return initProm
      },
      selectStream(name){
        this.selectedStream = this.streamTypes.filter(el=>el.name===name)[0].id
      },
      displayEventTime(startDateTime) {
        return new Date(startDateTime).toLocaleTimeString(
                "en-GB",
                {
                  timeZone: "Europe/London",
                  hour: '2-digit',
                  minute: '2-digit'}
            )
      },
      getATRTime(val) {
        let data = val;
        data = data.split("(")[1].split(")")[0];
        data = parseInt(data);
        let startTime = new Date(data);
        startTime = startTime.toISOString();
        startTime = startTime.split("T")[1].split(":");
        startTime = parseInt(startTime[0])+1 + ":" + startTime[1];
        return startTime;
      },
      selectEvent(e){
        if (this.selectedStream === 'SD365_1'){ // obsolete integration
          this.selectedEvent = this.streamEvents.filter(el=>parseInt(el.id) === parseInt(e.target.value))[0]
        } else if (this.selectedStream === 'SD365'){
          this.selectedEvent = this.streamEvents.find(el=>el.fixtureId === e.target.value)
        } else if (this.selectedStream === 'SIS'){
          console.log('SELECT SIS STREAM', e.target.value)
          this.selectedEvent = this.streamEvents.filter(el=>el.streamId==e.target.value)[0]
          console.log('SELECTED SIS STREAM', this.selectedEvent)
        } else if (this.selectedStream === 'TRP'){
          this.selectedEvent = this.streamEvents.filter(el=>el.ID==e.target.value)[0]
        }
      },
  
      getStreamTypeByEvent(ev) {
        if (ev?.fixtureId) {
          return 'SD365'
        }
        // if (ev?.id) {
        //   return 'SD365_1'
        // }
        if (ev?.streamId) {
          return 'SIS'
        }
        if (ev?.ID) {
          return 'TRP'
        }
        return ''
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  
  .stream-title {
    // min-height: 2.5rem;
    min-height: 1.9rem;
  }
  
  .select-row-wr {
    display: grid;
    grid-template-columns: minmax(25%, 50%) 1fr;
    grid-gap: 1rem;
  
    select {
      width: 100%;
    }
  }
  .spinner-border-sm{
    height: 0.75rem;
    width: 0.75rem;
  }
  .stream-container{
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s ease;
  }
  iframe{
    border: none;
  }
  
  select:focus-visible,
  select:focus{
    outline: none;
  }
  
  
  .no-stream {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.85rem;
  
    .logo {
      background-size: contain;
      width: 60px;
      height: 60px;
      background-repeat: no-repeat;
    }
  }
  </style>