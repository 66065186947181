<template>
<div>
<scroll-tabs-bar
  v-if="categories.length && $store.state.isMobile"
  class="competitons-wr"
  :items="categories"
  :selected-item="currentCategory"
  @selectitem="selectCategory"
></scroll-tabs-bar>
<pre-loader v-if="loading" />
<div v-show="!loading">
  <video-stream-control class="mt-2" v-if="$store.state.isMobile"/>
  <video-stream-player/>
  <!-- <video-stream-player v-if="!$store.state.isMobile"/>
  <video-stream
  v-if="$store.state.isMobile && ['h,H', 'H,h', 'g,q', 'vh', '$'].includes(sport.code) && events.length && nextRacesLoaded"
  :sport-code="sport.code"
  :next-meetings="nextMeetings"
  /> -->

  <best-odds-guaranteed-info v-if="events.length && sport?.bogInfoPath" :bog-info-path="sport.bogInfoPath" class="mb-2"></best-odds-guaranteed-info>
  
  <next-events
    v-if="currentSport && currentCategory!=='Futures'"
    @dataloaded="nextLoaded"
    :sport="sport"
    :sportCode="currentItem"
    no-events
  >
    <template v-slot:subtoggle>
      <toggle-items
        v-if="isHorseRacingToggle"
        :items="horseRacingToggleItems"
        @setActive="toggleHorseRacing" 
        :active="currentItem"/>
    </template>
  </next-events>
  

  <no-events v-if="!events.length" :title="currentCategory"/>
  <div v-else-if="currentCategory!=='Futures'">
    <transition-group name="list" tag="div" class="position-relative">
      <sport-races-event
          v-for="event in sortedEvents"
          :key="event.eventName"
          :event="event"
          :sport="sport"
          :sport-code="currentSport.code"
      ></sport-races-event>
    </transition-group>
  </div>
  <div v-else-if="currentCategory==='Futures'">
      <antepost-events-list
        :events="events"
        :sport="sport"
        :sport-code="currentSport.antePostCode"
      />
  </div>
</div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import SportRacesEvent from "@/components/Sport/Races/RacesEvent";
import ScrollTabsBar from "@/components/UI/ScrollTabsBar";
import NextEvents from "@/components/Home/NextEvents";
import PreLoader from "@/components/UI/PreLoader";
import AntepostEventsList from "@/components/Sport/AntepostEventsList";
// import VideoStream from "@/components/VideoStream";
import NoEvents from "@/components/Sport/NoEvents";

import VideoStreamPlayer from "@/components/VideoStream/VideoStreamPlayer";
import VideoStreamControl from "@/components/VideoStream/VideoStreamControl";

import ToggleItems from "@/components/Home/ToggleItems";
import BestOddsGuaranteedInfo from "@/views/BestOddsGuaranteedInfo"

export default {
  name: "RacesMain",
  components: {
    NoEvents,
    // VideoStream,
    VideoStreamControl,
    VideoStreamPlayer,
    AntepostEventsList,
    PreLoader,
    NextEvents,
    ScrollTabsBar,
    SportRacesEvent,
    ToggleItems,
    BestOddsGuaranteedInfo
  },
  props: ['sport'],
  data:()=>{
    return{
      events: [],
      categories: [],
      currentEventCategory: null,
      currentCategory: 'All',
      currentSlider: null,
      currentItem: null,
      // currentSportCode: '',
      currentSport: null, // main or subcategory
      loading: false,
      nextRacesLoaded: false,
      nextMeetings: null
    }
  },
  computed:{
  ...mapGetters(['isAuthenticated', 'USER']),
    sortedEvents(){
      if (this.currentEventCategory){
        let sortedEventCategories = this.events
            // .filter(c=>c.EventName!==this.currentEventCategory.EventName)
            .filter(c=>c.eventName!==this.currentEventCategory.eventName) // race-casediff
        sortedEventCategories.unshift(this.currentEventCategory)
        return sortedEventCategories
      } else {
        return this.events
      }
    },
    horseRacingToggleItems() {
      return this.API.SPORTS
        .find(sport => sport.title.toLowerCase() === 'horse racing')
        .subcategories
        .map(el => {
          return {
            label: el.shortTitle,
            value: el.code,
            title: el.title,
            carouselRegionId: el.carouselRegionId
          }
        })
    },
    isHorseRacingToggle() {
      return this.sport.title.toLowerCase() === 'horse racing'
    },
    userPreferenceCarouselSportCode() {
      return this.horseRacingToggleItems
        .find( el => el.carouselRegionId === this.USER.preferences?.carouselRegion)
        ?.value
    }
  },
  watch:{
    currentCategory(){
      // let url
      if (this.currentCategory !== 'Futures') {
        if (this.sport.subcategories) {
          let subCategory = this.sport.subcategories.find(subc => subc.title === this.currentCategory)
          this.currentSport = subCategory
          // set selected subtoggle
          // logged user - setting comes from prefs and doesn't depend on current subcategory
          if (!this.isAuthenticated) {
            this.currentItem = subCategory.code 
          }
        } 
      }
      this.events = []
      this.loadEvents()
    },
    '$route.query.selectedOption'(val){
      if(this.categories.includes(val)){
        this.currentCategory = val
      }
    },
    '$route.query.selectedCategoryEventName'(value){
      if (value) this.selectEventCategory(value)
    },
    'USER.preferences.carouselRegion'() {
      this.setCurrentItem()
    }
  },
  mounted() {
    this.currentSport = this.sport
    if (this.sport.subcategories){
      this.categories = this.sport.subcategories.map(sc=>sc.title)
      this.currentSport = this.sport.subcategories[0]
    } else if (this.sport.url){
      this.categories = ['Races']
    } else {
      this.categories = []
    }

    this.setCurrentItem()

    if (this.sport.antePostCode){
      this.categories = this.categories.concat(['Futures'])
    }
    this.currentCategory = this.$route.query.selectedOption || this.categories[0]
    // isAntepost = this.currentCategory === 'Futures'
    // this.initCategories()
    this.loadEvents()
  },
  methods:{
    loadEvents(url=null){
      let vm = this
      vm.loading = true

      let isAntepost = this.currentCategory === 'Futures'
      if (!url) url = this.sport.url 
      let promise = isAntepost ? this.API.getAntepostEvents(this.sport?.antePostCode) : this.API.getRaceEvents(this.currentSport.code)
      promise.then(response=>{
        console.log('EVENTS >>>', response)
        vm.events = response.data === '[{]}]' ? [] : response.data
        vm.loading = false
      })
      .catch(error=>console.log(error))
    },
    selectCategory(categoryName){
      this.currentCategory = categoryName
    },
    selectEventCategory(selectedEventCategoryName){
      this.currentEventCategory = this.events.filter(ev=>ev.eventName === selectedEventCategoryName)[0] //race-casediff
    },
    // set slider toggle
    setCurrentItem() {
      if (this.sport?.isCarouselRegion) {
        this.currentItem = this.isAuthenticated ?
        this.userPreferenceCarouselSportCode :
        this.horseRacingToggleItems[0].value
      }
      else {
        this.currentItem = this.sport.code
      }
    },
    nextLoaded(data){
      this.nextMeetings = data
      this.nextRacesLoaded = true;
      
      const nextRace = data && data.length ? data[0] : null
      this.$store.dispatch('START_STREAM_CONTROLS', nextRace)
        .then(() =>{
          this.$store.dispatch('SET_SHOW_CONTROLS', true)
        })
      

    },
    toggleHorseRacing(value) {
      this.currentItem = value
    },
  }
}
</script>

<style scoped>
.list-move{
  transition: transform 0.3s ease;
}

</style>