import store from "@/store";
export const global_objects = {
    formatPrice(price){
        if (!price) return price
        if (price?.toLowerCase() === 'evens') {
            return 'EVS'
        }
        if (store.state.user.preferences?.oddFormat=== 2 && price.includes('-')) { // 2 - decimal
            return (price.split('-')[0]/price.split('-')[1] + 1).toFixed(2)
        } else if (store.state.user.preferences?.oddsFormat=== 2 && price.includes('/')) { // 2 - decimal
            return (price.split('/')[0]/price.split('/')[1] + 1).toFixed(2)
        } else {
            return price
        }
        // if (store.state.user.oddsFormat==='decimal' && price.includes('-')) {
        //     return (price.split('-')[0]/price.split('-')[1] + 1).toFixed(2)
        // } else if (store.state.user.oddsFormat==='decimal' && price.includes('/')) {
        //     return (price.split('/')[0]/price.split('/')[1] + 1).toFixed(2)
        // } else {
        //     return price
        // }
    },
    convertInputFileToBase64(file){
      return new Promise((resolve)=>{
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = ()=>{
            resolve(reader.result)
            // let fileInfo = {
            //   name: file.name,
            //   type: file.type,
            //   size: Math.round(file.size / 1000) + " kB",
            //   base64: reader.result,
            //   file: file,
            // }
          }
      })
    },
} 