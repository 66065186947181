<template>

<div 
  v-if="showFlyInd"
  class="fly-in"
  :style="styleObj"
></div>
</template>

<script>
// import {mapGetters} from "vuex";
export default {
  name: "FlyInAnim",
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    top: {
      type: Number,
      default: 0
    },
    left: {
      type: Number,
      default: 0
    },
    domRectObj: {
      type: DOMRect,
      default: null
    } 
    
  },
  data() {
    return {
      showFlyInd: false,
      styleObj: null
    }
  },
  // watch: {
  //   '$store.state.betslipStore.betslip.bets'(newVal, oldVal){
  //     console.log('DEBUG this.BETSLIP.bets.length', newVal, oldVal)
  //   }
  // },
  mounted(){
  },
  unmounted () {
  },
  methods: {
    play(domRectObj) {
      if (domRectObj) {
        this.styleObj = {
          width: `${domRectObj?.width}px`,
          height: `${domRectObj?.height}px`,
          top: `${domRectObj?.top < 0 ? 0 : domRectObj?.top > window.innerHeight - domRectObj?.height ? window.innerHeight - domRectObj?.height : domRectObj?.top}px`,
          left: `${domRectObj?.left < 0 ? 0 : domRectObj?.left > (window.innerWidth - domRectObj?.width) ? (window.innerWidth - domRectObj?.width) : domRectObj?.left}px`,
        }
        this.showFlyInd = true;
      }
      setTimeout(() => { this.showFlyInd = false}, 400)

    }
  }
}
</script>

<style scoped lang="scss">
  .fly-in {
    background-color: orange;
    opacity: 0.5;
    position: fixed;
    z-index: 90;
    animation: flyin .4s ease-in infinite;
    margin-top: env(safe-area-inset-top);
  }

  @keyframes flyin {
    0% {

    }
    30%{
      border-radius: 20%;
      width: 20px;
      height: 20px;
      opacity: 0.5;
    }
    100% {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      opacity: 1;
      top: calc(100vh - 40px); // TODO: check viewport
      left: calc(50% - 10px)
    }

  }
</style>