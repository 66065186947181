<template>
  <vue-modal ref="modal">
    <template v-slot:header>
      
      
    </template>
    <template v-slot:default>

      <div v-if="loading">
        <div class="loading-shade"></div>
        <pre-loader></pre-loader>
      </div>

      <div class="verify-container">
        <div class=verify-header>
          <!-- @/assets/img/sms-tracking.svg -->
          <svg class="verify-img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5H7" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2 16.5H8" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2 12.5H5" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <div class="verify-title">Verify its you.</div>
        </div>

        <div class="verify-content">
          
          
          <template v-if="verifyModel?.emailIsNotAllowed">
            <div>Email <b>{{ email }}</b> has already been used.</div>
          </template>
          <template v-else-if="verifyModel?.serviceIsNotAvailable">
            <div>Service is unavailable. Please try again later.</div>
          </template>

          <!-- normal enter code view -->
          <template v-else>
            <div class="verify-text">
              <div>Please check veriffication code to {{ email }}.</div>
              <div>Please check your inbox and enter the code below.</div>
              <div>If you don't see the email, check your Spam folder.</div>  
            </div>

            <div class="verify-text">6-digits code*</div>
            
            <div class="verify-item">
              <vue-input
                name="security-answer"
                type="text"
                placeholder="Enter 6-digits code"
                autocomplete="false"
                v-model="code"
                :update-on-input="true"
              />
            </div>

            <div class="errors">
              <div v-if="verifyCodeResp?.codeIsNotValid"> Code is not valid</div>
              <div v-if="verifyCodeResp?.codeIsNotActual"> The code has expired. {{ tryAgainCountdownText }}</div>
            </div>
            
            <div class="verify-item">
              <vue-button 
                @click="verifyCode"
                class="verify-item"
                :cls="isCodeValid ? 'main' : 'disable'"
                :disabled="!isCodeValid"
              >
                Verify code
              </vue-button>
            </div>

            <div class="verify-text">
              Didn't receive an email? 
              <a 
                @click="resendCode" 
                :class="[
                  'link-try', 
                  {'inactive': isCountDown}
                ]"
              >
                {{ tryAgainCountdownText }}
              </a>
              
            </div>

            <div class="verify-text">If you haven't received it within 15 minutes, please send an email to support from the same email address you used for registration. We'll then manually verify it for you.</div>
          </template>
        </div>
      </div>
    </template>
  </vue-modal>
</template>

<script>
import VueModal from "@/components/UI/VueModal";
import VueButton from "@/components/UI/VueButton";
import VueInput from "@/components/UI/VueInput";
import PreLoader from "@/components/UI/PreLoader";

export default {
  name: "VerifyEmailModal",
  components: {
    VueModal,
    VueButton,
    VueInput,
    PreLoader
  },
  emits: [
    'verificationSuccess'
  ],
  data: ()=>{
    return {
      loading: false,

      code: null,
      email: '',
      id: '',

      verifyModel: null,
      secondsLeft: null,
      countdownInterval: null,

      verifyCodeResp: null
    }
  },
  computed: {
    isCountDown() {
      return this.secondsLeft > 0
    },
    tryAgainCountdownText() {
      if (this.isCountDown) {
        return `Try again in ${this.secondsLeft} second(s).`
      }
      return 'Try again.'
    },
    isCodeValid() {
      return /^[0-9]{6}$/.test(this.code?.trim())
    }
  },
  mounted() {
    // if (!localStorage.getItem('appOffer')){
    //   setTimeout(()=>this.$refs.modal.open(), 15000)
    // }
  },
  beforeUnmount() {
    clearInterval(this.countdownInterval)
  },
  methods:{
    open(email) {
      this.loading = true
      this.email = email
      // clear code value
      this.code = null
      // clear errors
      if (this.verifyModel?.emailIsNotAllowed) {
        this.verifyModel.emailIsNotAllowed = null
      }
      this.verifyCodeResp = null
      if (this.verifyModel?.serviceIsNotAvailable) {
        this.verifyModel.serviceIsNotAvailable = null
      }
      this.sendEmailCode()
      this.$refs.modal.open()
      
    },
    close(){
      localStorage.setItem('appOffer', 'true')
      this.$refs.modal.close()
    },

    sendEmailCode() {
      this.API.sendEmailCode(this.email, this.verifyModel?.id)
        .then(resp => {
          this.verifyModel = resp
          this.setCountDown()
          this.loading = false
        })
        .catch(() => {
          console.error('DEBUG sendEmailCode ERROR')
        })
    },
    verifyCode() {
      const model = {
        email: this.email,
        id: this.verifyModel?.id,
        code: parseInt(this.code)
      }
      this.API.validateEmailCode(model)
        .then(resp =>  {
          this.verifyCodeResp = resp

          if (resp.isConfirmed) {
            this.$emit('verificationSuccess', resp)
          }
        })
    },
    setCountDown() {
      clearInterval(this.countdownInterval)
      this.secondsLeft = Math.ceil(this.verifyModel?.resendCodeSec)
      this.countdownInterval = setInterval(()=>{
            if (this.secondsLeft>0) {
              this.secondsLeft-=1
            } else {
              clearInterval(this.countdownInterval)
            }
          }, 1000)
    },
    resendCode() {
      if (this.isCountDown) {
        return
      }
      this.loading = true
      // clear errors
      if (this.verifyModel?.emailIsNotAllowed) {
        this.verifyModel.emailIsNotAllowed = null
      }
      this.verifyCodeResp = null
      if (this.verifyModel?.serviceIsNotAvailable) {
        this.verifyModel.serviceIsNotAvailable = null
      }
      this.sendEmailCode()
    }
  }
}
</script>

<style scoped lang="scss">
  .verify-container{
    max-width: 430px;
  }

  .verify-header {
    text-align: center;

    .verify-img {
      width: 4rem;
      height: 4rem;
      margin-bottom: 0.5rem;
    }

    .verify-title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
  .verify-content {
    text-align: center;

    

    
    .verify-text {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    .errors {
      margin-bottom: 1rem;
      color: var(--geoff-danger)
    }

    .verify-item {
      margin-bottom: 1rem;
    }

    .link-try {
      color: var(--geoff-accent);
      text-decoration: none;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      &.inactive {
        color: inherit;
        &:hover {
          cursor: default;
          text-decoration: none;
        }
      }


    }

  }
</style>