<template>
<pre-loader v-if="loading"/>
<div v-else class="geoff-bg-section" :class="{'rounded-3': !$store.state.isMobile}">
  <MatchGroupMenu :group="category"></MatchGroupMenu>

  <!-- 3 teams/players view (like in golf) -->
  <div
    v-if="matchData?.secondPlayer && !['draw', 'vs', '@', 'tie'].includes(matchData?.secondPlayer.toLowerCase())"
    class="match-header text-white text-center d-flex flex-column position-relative text-shadow-accent"
    :style="{backgroundImage: 'url('+sport.bg+')'}"
  >
    <div class="start-0 end-0 top-0 my-2 fs-14 col-auto position-absolute text-center">{{title}}</div> <!--TODO: remove because empty???-->
    <div class="d-flex flex-column col justify-content-center align-items-center">
      <div class="three-palayer-match-list fs-3">
        
        <div class="team-wr " :class="{'content-over': $store.state.isMobile }">
          <div v-if="matchData?.homeLogo" class="team-image"  >
            <img :src="matchData.homeLogo" />
          </div>
          <div class="team-name fs-3 text-ellipsies">{{matchData?.homeTeam}}</div>
        </div>
        <div class="team-wr" :class="{'content-over': $store.state.isMobile }">
          <div v-if="matchData?.secondPlayerLogo" class="team-image"  >
            <img :src="matchData.secondPlayerLogo" />
          </div>
          <div class="team-name fs-3 text-ellipsies">{{matchData?.secondPlayer}}</div>
        </div>
        <div class="team-wr" :class="{'content-over': $store.state.isMobile }">
          <div v-if="matchData?.awayLogo" class="team-image"  >
            <img :src="matchData.awayLogo" />
          </div>
          <div class="team-name fs-3 text-ellipsies">{{matchData?.awayTeam}}</div>
        </div>
      </div>
      <div class="text-center m-2 w-100 fs-12 fw-500 ">{{ formatedDate }}</div>
    </div>
  </div>
  <!-- 2 teams/player view -->
  <div
    v-else
    class="match-header text-white text-center d-flex flex-column position-relative text-shadow-accent"
    :style="{backgroundImage: 'url('+sport.bg+')'}"
  >
    <div class="start-0 end-0 top-0 my-2 fs-14 col-auto position-absolute text-center">{{title}}</div>
    <div class="d-flex justify-content-center align-items-center col p-2">

      <div class="team-wr w-50" :class="{'content-over': $store.state.isMobile }">
        <div v-if="matchData?.homeLogo" class="team-image"  >
          <img :src="matchData.homeLogo" />
        </div>
        <div class="team-name fs-3 text-ellipsies">{{matchData?.homeTeam}}</div>
      </div>

      <div class="m-2 match-date fs-12 fw-500 ">{{ formatedDate }}</div>

      <div class="team-wr w-50" :class="{'content-over': $store.state.isMobile }">
        <div v-if="matchData?.awayLogo" class="team-image">
          <img :src="matchData.awayLogo" />
        </div>
        <div class="team-name fs-3 text-ellipsies">{{ matchData?.awayTeam }}</div>
      </div>

    </div>
  </div>

  <div v-if="sport.tabs" >
    <div >
      <div>
        <scroll-tabs-bar
          v-if="tabs.length"
          :items="tabs"
          :selected-item="selectedTab"
          @selectitem="selectTab"
        ></scroll-tabs-bar>
      </div>

      <div class="p-3 geoff-col-section"
        :class="{
          'rounded-3': !$store.state.isMobile,
          'screen-columns': (tabContent.length > 1)
        }
        ">
        <div >
          <div v-for="(type, index) in tabContent" :key="index" class="match-table section-item rounded-3">
            <div class="match-title sub-header p-n">{{type.elRespose}}</div>
            <div class="match-row p-n" v-for="(res, index) in type?.events?.filter(ev=>ev.price)" :key="index">
              <div class="d-flex justify-content-between align-items-center">
                <div>{{ res.selName }}</div>
                <odd-btn :show-add-info="false" :race="res" :selection="parseInt(res.selection)" class="match-odd"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="p-3 geoff-col-section"
    :class="{
      'rounded-3': !$store.state.isMobile,
      'screen-columns': matchData?.fullcard?.length > 1
    }
    ">
    <div v-for="(type, index) in matchData.fullcard" :key="index" class="match-table section-item rounded-3">
      <div class="match-title sub-header p-n">{{type.elRespose}}</div>
      <div class="match-row p-n" v-for="(res, index) in type?.events?.filter(ev=>ev.price)" :key="index">
        <div class="d-flex justify-content-between align-items-center">
          <div>{{ res.selName }}</div>
          <odd-btn :show-add-info="false" :race="res" :selection="parseInt(res.selection)" class="match-odd"/>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import PreLoader from "@/components/UI/PreLoader";
import OddBtn from "@/components/UI/OddBtn";
import MatchGroupMenu from "@/views/MatchGroupMenu"
import ScrollTabsBar from "@/components/UI/ScrollTabsBar";
import * as Utility from "@/http/utils"

export default {
  name: "MatchEventView",
  components: {OddBtn, PreLoader, MatchGroupMenu, ScrollTabsBar},
  data:()=>{
    return{
      loadingData: true,
      loadingEvents: true,
      matchData: null,
      sport: {},
      title: '', // ???
      ef: '',
      eg: '',
      category: {},
      currentTab: '',
      intervalLoadData: null
    }
  },
  watch:{
    '$route.params'(to){ 
      if (!to.eg || !to.ef || this.$route.name !== "matchEvent") return
      this.sport = this.API.SPORTS.find(sport=>sport.title.toLowerCase() === this.$route.params.sportName.toLowerCase())
      this.ef = this.$route.params.ef
      this.eg = this.$route.params.eg
      this.title = this.$route.query.title
      this.currentTab = ''
      this.initLoadDataInterval()
    }
  },
  computed: {
    loading(){
      return this.loadingData || this.loadingEvents
    },

    tabs() {
      if(this.matchData?.fullcard?.length) {
        const availableMarkerts = this.matchData?.fullcard.map(el => el.elRespose.toLowerCase());
        const availableTabs = this.sport.tabs
          .filter(el => el.values
              .map(el => el.toLowerCase())
              .some(el => availableMarkerts.includes(el)))
          .map(el => el.label)
        return this.isOther ? [...availableTabs, 'Other'] : availableTabs
      }
      return []
    },

    tabContent() {
      if (this.selectedTab === 'Other') {
        const knownMarkets = this.sport.tabs
          .map(el => [...el.values.map(v => v.toLowerCase())])
          .flat();
        const otherMarkets = this.matchData?.fullcard?.filter(el => !knownMarkets.includes(el.elRespose.toLowerCase()))
        return otherMarkets;
      }
      else {
        const tabContent = this.matchData?.fullcard
          ?.filter(el => this.sport.tabs
            .find(t => t.label === this.selectedTab).values
            .map(v => v.toLowerCase())
            .includes(el.elRespose.toLowerCase()))
        return tabContent
      }
    },

    isOther() {
        if (this.matchData?.fullcard?.length) {
          const knownMarkets = this.sport.tabs
            .map(el => el.values)
            .flat()
            .map(el => el.toLowerCase())
          return  this.matchData?.fullcard
            .map(el => el.elRespose.toLowerCase())
            .some(el => !knownMarkets.includes(el))
        }
        return false;
    },
    selectedTab() {
      return this.currentTab || this.tabs[0];
    },
    formatedDate () {
      return Utility.FormatDateTime(this.matchData.date, this.matchData.time, true)
    }
  },
  beforeMount() {
    this.sport = this.API.SPORTS.find(sport=>sport.title.toLowerCase() === this.$route.params.sportName.toLowerCase())
    this.ef = this.$route.params.ef
    this.eg = this.$route.params.eg
    this.title = this.$route.query.title
  },
  mounted() {
    this.initLoadDataInterval()
  },
  beforeUnmount(){
    this.clearLoadDataInterval()
  },
  methods:{
    loadData(showLoading = false){
      let vm = this
      if (showLoading) {
        vm.loadingData = true
        vm.loadingEvents = true
      }
      console.log('>>>>>> SETTINGS', this.$store.state.settings)

      this.API.getSportSingleMatch(this.ef, this.eg)
      .then((data)=>{
        const parsedData = {...data}
        parsedData?.fullcard?.forEach((mrk) => {
          const parsed = this.API.ParseEventData(mrk) // get rid of parse ( when all event conmes in same case and names)
          mrk = {...mrk, ...parsed}
        })
        vm.matchData = data
      })
      .finally(()=>vm.loadingData = false)

      // events short
      this.API.getSportEventsList(this.sport.code, false)
      .then(response=>{
        vm.eventCategories = response.map(cat => {
          let category = cat
          category.events = []
          cat.competitions.map(comp => { // casediff
            comp.details.map(ev => {
              let event = ev
              event.date = comp.date
              event.formatDate = Utility.FormatDate(event.date)
              event.code = cat.code // casediff
              event.eventName = comp.eventName // casediff
              category.events.push(event)
              return event
            })
          })
          return category
        })

        vm.category = this.eventCategories
          .find(el => el.code === this.ef) // casediff
        vm.loadingEvents = false
      })
      .catch(error=>{
        console.log(error); 
        vm.loadingEvents = false
      })
      

    },

    initLoadDataInterval() {
        this.loadData(true)
      clearInterval(this.intervalLoadData);
      this.intervalLoadData = setInterval(this.loadData, 5000);
    },
    clearLoadDataInterval(){
      if (this.intervalLoadData) clearInterval(this.intervalLoadData)
    },
    selectTab(tab) {
      this.currentTab = tab;
    }
  }

}
</script>

<style scoped lang="scss">
.match-header{
  padding: 1rem;
  height: 9rem;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.event-type-results{
  width: auto;
  background: var(--geoff-bg-section);
  border-radius: 0.25rem;
}
.match-date{
  width: min-content;
  text-align: center;
  white-space: pre;
  line-height: 1.25rem;
}

.team-wr {
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  grid-gap: 0 1rem;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  overflow: hidden;

  &.reverse {
    flex-direction: row-reverse;
  }

  &.content-over {
    flex-direction: column;
  }

  .team-image {
    width: 2.8125rem;
    height: 2.8125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }

  .team-name {
    max-width: 100%;
  }
}

.three-palayer-match-list {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
}
</style>