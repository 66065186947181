<template>
<div>
  <div v-if="!loading">
    <MatchSlider 
        :sportName="this.sport.title"
        @dataloaded="matchSliderLoaded=true" 
        />
  </div>
<div><scroll-tabs-bar
    v-if="categories.length && $store.state.isMobile"
    :items="categories"
    :selected-item="currentCategory"
    @selectitem="selectCategory"
    class=" geoff-border-bottom competitons-wr"
  ></scroll-tabs-bar>
<pre-loader v-if="loading"/>
<no-events v-else-if="eventCategories.length===0 && antepostEvents.length===0" :title="sport.title+ ' matches'"/>
<div v-else>
  <div v-if="currentCategory==='All'"  class="geoff-bg-section" :class="{'rounded-3 mb-2': !$store.state.isMobile}" >
    
    <div>
      <div class="sub-header p-n grid-coupon-view"
        :class="colCountClass"
      >
        <div></div>

        <!-- single market -->
        <template v-if="availableMarketsOptions.length === 1">
          <div class="market-static">{{ defaultMarket }}</div>
        </template>

        <!-- few markets -->
        <template v-else>
          <div v-for="(col, idx) in columns" :key="idx">
            <select
              :disabled="availableMarketsOptions.length <= 0"
              v-model="columns[idx]" class="market-select" name="" id="">
              <option
                v-for="(item, midx) in availableMarketsOptions"
                :key="midx"
                :value="item">{{ item }}</option>
            </select>
          </div>
        </template>
      </div>
    </div>

    <transition-group name="flip-list" tag="div" class="position-relative">
    <div
      v-for="category in sortedEventCategories"
      :key="category.id" 
      :class="$store.state.isMobile?' geoff-border-bottom-separator':''"
    >
      <div
        class=" fw-700 sub-header p-n grid-coupon-view geoff-bg-subsection"
        :class="colCountClass"
      >
        <div class="league-name">{{category.eventName}}</div>
        <!-- cols legends -->
        <competitions-legend
          v-for="(colMarket, idx) in selectedMarkets"
          :key="idx"
          :market-name="colMarket.marketName"
          :market-type="colMarket.legendType"
          :market-legend="colMarket.legends"
        />
      </div>
      <competitions-list-item
        v-for="(event, index) in category.events"
        :key="index"
        :event="event"
        :sport="sport"
        :columns="selectedMarkets"
        class="geoff-border-bottom-separator"
      />
    </div>
    </transition-group>
  </div>
  <div v-else>
    <antepost-events-list
      :events="antepostEvents"
      :sport="sport"
      :sport-code="sport.code"
    />
  </div>

</div>
</div>
</div>
</template>

<script>
import ScrollTabsBar from "@/components/UI/ScrollTabsBar";
import PreLoader from "@/components/UI/PreLoader";
import CompetitionsListItem from "@/components/Sport/Competitions/CompetitionsListItem";
import CompetitionsLegend from "@/components/Sport/Competitions/CompetitionsLegend";
import NoEvents from "@/components/Sport/NoEvents";
import AntepostEventsList from "@/components/Sport/AntepostEventsList";
import MatchSlider from "@/components/Home/MatchSlider";
export default {
name: "SportCompetitions",
  components: { AntepostEventsList, NoEvents, CompetitionsListItem, CompetitionsLegend, PreLoader, ScrollTabsBar, MatchSlider},
  props: ['sport'],
  data: ()=>{
    return{
      categories: [],
      loading: false,
      eventCategories: [],
      antepostEvents: [],
      currentCategory: null,
      currentEventCategory: null,
      currentSportCode: '',
      intervalLoad: null,
      mainMarket: '',
      defaultMarket: null,
      columns: [], // all columns to display
      availableMarkets: [], // option list to display in select in additional cols
    }
  },
  beforeUnmount() {
    if (this.intervalLoad) clearInterval(this.intervalLoad)
  },
  mounted() {
    this.emitter.on('select-event-category', this.selectEventCategory)
    const vm = this
    if (this.sport.code) this.categories.push('All')
    this.currentSportCode = this.sport.code
    if (this.sport.antePostCode) this.categories.push('Futures')
    this.currentCategory = this.$route.query.category || this.categories[0]
    vm.loadEvents()
    vm.intervalLoad = setInterval(()=>vm.loadEvents(), 5000)
  },
  computed:{
    sortedEventCategories(){
      if (this.currentEventCategory){
        let sortedEventCategories = this.eventCategories
            .filter(c=>c.eventName!==this.currentEventCategory.eventName) // casediff
        sortedEventCategories.unshift(this.currentEventCategory)
        return sortedEventCategories
      } else {
        return this.eventCategories
      }
    },
    colCountClass() {
      return `market-col-${this.columns.length} ${ this.$store.state.isMobile ? 'grid-coupone-view-mobile' : 'grid-coupone-view-desktop' }`
    },
    // string options for select
    availableMarketsOptions() {
      return this.availableMarkets.map(el => el.marketName)
    },
    selectedMarkets() {
      const selectedMarkets = this.columns.map(el => this.availableMarkets.find(m => el === m.marketName ))
      return selectedMarkets;
    },
    isGolf() {
      return this.sport?.code === 'm'
    }
  },
  watch:{
    currentCategory(category){
      if (category === 'Futures'){
        this.loadEvents(true)
      } else {
        this.loadEvents(false)
      }
    },
    '$route.query.selectedCategoryEventName'(value){
      if (value) this.selectEventCategory(value)
    }
  },
  methods:{
    loadEvents(isAntepost = null){
      let vm = this
      if (!vm.eventCategories.length && !vm.antepostEvents.length) vm.loading = true
      if (isAntepost === null) {
        isAntepost = this.sport.url ? false : true
      }
      let promise = isAntepost ? this.API.getAntepostEvents(this.sport?.antePostCode) : this.API.getEvents(this.sport?.code)
      promise
      .then(response=>{
        // antepost
        if (response.data.length && response.data[0].Meetings){
          vm.antepostEvents = response.data
        // basic events
        } else {
          vm.eventCategories = response.data.map(cat => {
            let category = cat
            category.events = []
            cat.competitions.map(comp => { // casediff
              comp.details.map(ev => {
                let event = ev

                event.fullCard = event.fullCard.map( el => {
                  const parsed = this.API.ParseEventData(el)
                  return {
                    ...el,
                    fullCard: parsed
                  }
                }) 
                
                event.date = comp.date
                event.code = cat.code // casediff
                event.eventName = comp.eventName // casediff
                category.events.push(event)
                // allEvents.push(event)
                return event
              })
            })
            return category
          })
          vm.availableMarkets = response.markets
          vm.defaultMarket =  vm.availableMarkets[0]?.marketName
          if (!vm.columns.length) {
            vm.columns = response.markets.map(el => el.marketName).slice(0, 3)
          }
        }
        vm.loading=false
        if (vm.$route.query.selectedCategoryEventName) vm.selectEventCategory(vm.$route.query.selectedCategoryEventName)
      })
      .catch(error=>{console.log(error), vm.loading=false})
    },
    selectCategory(categoryName){
      if (this.$store.state.isMobile){
        this.currentCategory = categoryName
        this.antepostEvents = []
        this.eventCategories = []
        this.$router.push({name: 'sport', params: {sportName: this.sport.title }, query:{category:categoryName}})
      }
    },
    selectEventCategory(selectedEventCategoryName){
      this.currentEventCategory = this.eventCategories.filter(c=>c.eventName===selectedEventCategoryName)[0] // casediff
    }
  }

}
</script>

<style scoped lang="scss">
.live-match-item{
  width: auto;
  background: #FFFFFF;
  border-radius: 0.25rem;
}
.flip-list-move{
  transition: transform 0.3s;
}

.market-static {
  font-size: 0.75rem;
  height: 29px;
  line-height: 29px;
  text-align: center;
}
.market-select {
  background-color: var(--geoff-bg-section);
  font-size: 0.75rem;
  height: 29px;

  border-radius: 0.25rem;
  border: 1px solid #858585;
  padding-left: 0.5em;
  padding-right: 1.75em;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  option {
    background-color: transparent;
  }

  &:focus-visible{
    outline: none;
  }
}
.league-name {
  font-size: 0.7rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 400;
}
.sub-header {
  min-height: 2rem;
}
</style>