<template>
<div class="tabs-wrap geoff-bg-main" ref="tabs">
    <div class="tabs-container d-flex justify-content-center "> <!--py-2 px-3-->
      <div
          class="tabs__item desk-c-pointer"  
          v-for="(item, index) in items"
          :key="index" :class="{'active-tab': item===selectedItem}"
          @click="selectItem($event, item)"
      >{{item}}</div> <!--px-3-->
    </div>
</div>
</template>

<script>
export default {
name: "ScrollTabsBar",
emits: ['selectitem'],
props: ['items', 'selectedItem'],
mounted() {
  let selectedItem = document.getElementsByClassName('active-tab')[0]
  this.$refs.tabs.scrollLeft=selectedItem.offsetLeft - window.visualViewport.width / 2
},
methods:{
  selectItem(event, item){
    this.$refs.tabs.scrollLeft=event.target.offsetLeft - window.visualViewport.width / 2
    this.$emit('selectitem', item)
  }
}
}
</script>

<style scoped lang="scss">
.tabs-wrap{
  overflow-x: scroll;
  scroll-behavior: smooth;
  background-color: var(--geoff-bg-section);
}
.tabs-container{
  min-width: max-content;
}
.tabs-container .active-tab{
  /* border-radius: 2rem;
  border: 2px solid #FFC549; */
  border-bottom: 3px solid var(--geoff-accent);
  font-weight: 700;
  height: 2.5rem;
}
.tabs__item{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.75rem;
  font-size: 0.875rem;
}

.competitons-wr {
  margin-bottom: 0.5rem; 

  .tabs__item {
    font-size: 1rem;
  }
}
</style>