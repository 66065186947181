import * as betcruncher from "betcruncher"
import * as oddsconverter from './oddsconverter';
import { k_combinations } from "./combinations"

export const getProfitMessage = (betList, wagers, index, currentWagerStake, eachWay) => {
    const valueString = currentWagerStake || 0;
    const stake = Number(valueString);

    if (isNaN(stake) || stake === 0 || stake < 1) {
      return '';
    }

    const wagerTypeName = wagers[index].name.toLocaleLowerCase();
    const wagerInfo = wagersCruncher.find(el => el.name === wagerTypeName)
    const bets = getRunners(betList.map(el => {return {...el}}))

    // calculate with cruncher
    if (wagerInfo) {
      const slip = {
        type: wagerInfo.chruncher,
        stake: stake,
        eachWay
      };
      const combinations = k_combinations(bets, wagerInfo.selection);
      const profit = combinations.map(bet => betcruncher(slip, bet))
        .map(el => el.returns)
        .reduce((partialSum, a) => partialSum + a, 0)

      return round(profit)
    }
    else {
      switch (wagerTypeName) {
        case 'acc on all selns': {
          // custom multiplier of each line
          const profit = bets
            .map(r => oddsconverter(r.odds).decimal)
            .reduce( (a,b) => a * b )
          return round(profit * stake)
        }
        default: {
          //specific bet, calculation is not implemented
          return ''
        }
      }
    }
};

function round(number) {
  return Math.round(number * 100) / 100;
}

export const getRunners = (betList) => {
  const runners = betList
    .map(bet => {
      let odds = bet.price.trim().replace('-', '/');
      if (odds === '0/0') {
          odds = '0/1'; // betcruncher cannot avoid deleting by 0
      }
      
      return {
          odds, // 12-2 -> 12/2
          terms: '1/4',
          position: 1
      };
    })
    .filter(data => data != null);
    return runners
}


const wagersCruncher = [ 
  { name: 'singles', chruncher: 'single', selection: 1},
  { name: 'doubles', chruncher: 'double', selection: 2},
  { name: 'trebles', chruncher: 'treble', selection: 3},
  { name: 'perm 4-folds', chruncher: 'fourfold', selection: 4},
  { name: 'perm 5-folds', chruncher: 'fivefold', selection: 5},
  { name: 'perm 6-folds', chruncher: 'sixfold', selection: 6},
  { name: 'perm 7-folds', chruncher: 'sevenfold', selection: 7},
  { name: 'perm 8-folds', chruncher: 'eightfold', selection: 8},
  { name: 'patent or twist', chruncher: 'patent', selection: 3},
  { name: 'trixie', chruncher: 'trixie', selection: 3},
  { name: 'lucky 15 sport', chruncher: 'lucky15', selection: 4},
  { name: 'yankee', chruncher: 'yankee', selection: 4},
  { name: 'super yankee', chruncher: 'superyankee', selection: 5},
  { name: 'lucky 31 sport', chruncher: 'lucky31', selection: 5},
  { name: 'heinz', chruncher: 'heinz', selection: 6},
  { name: 'lucky 63 sport', chruncher: 'lucky63', selection: 6},
  { name: 'super heinz', chruncher: 'superheinz', selection: 7},
  { name: 'goliath', chruncher: 'goliath', selection: 8},
]
