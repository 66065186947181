import _ from 'underscore'

//eslint-disable-next-line
export const emailRegEx =  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; 

// not used
export function greyHoundRaceFormat(data, sportcode) {

  var index = data.MeetingName.lastIndexOf(" ");

  data.Name = data.MeetingName.substring(0, index).trim();
  data.Time = data.MeetingName.split(' ').slice(-1)[0]
  data.Info1 = data.EventName;
  data.Info2 = data.MeetingName;
  data.TimeLeft = data.TimeText;
  data.PlText = data.Pl;
  //CHECK IF EVENTS ARE NOT UNDEFINED
  if (data.Events) {
    data.Events.forEach((v, i) => {
      v.DogName = v.SelName;

      if (sportcode === "$") {
        v.Image = i + 1 + ".jpg";
        v.number = parseInt(v.Image.split(".")[0].trim());
      } else {
        v.number = parseInt(v.Image.split(".")[0].trim());
      }

      if (v.Tooltip.toLowerCase() === "best odds guaranteed") {
        v.BestOdds = v.Tooltip;

        v.ToolTipBoardPrice = v.Tooltip;
        v.ToolTip = v.ToolTipBoardPrice;
      }
    });
  }

  data.Events = _.sortBy(data.Events, [
    function (o) {
      return o.number;
    },
  ]);
  return data;
}

// not used
export function liveRaceFormat(data) {
  //data.ELResponse and TimeText is undefined here for vh race IN API

  // if (sportcode == "vh") {
  //   data.MeetingName = data.ElRespose + " " + data.TimeText;
  // }

  var index = data.MeetingName.lastIndexOf(" ");
  data.Time = data.MeetingName.substring(index, data.MeetingName.length).trim();

  data.Name = data.MeetingName.substring(0, index).trim();

  data.Info1 = data.EventName1 == undefined ? data.EventName : data.EventName1; // CHECKING FOR UNDEFINED DATA AND REPLACING WITH DATA AVAILABLE IN VIRTUAL RACE DATA
  data.Info2 = data.MeetingName;
  data.TimeLeft = data.TimeText;
  data.PlText = data.Pl;
  //CHECK IF EVENTS ARE AVAILABLE.
  if (data.Events != undefined) {
    data.Events.forEach((v) => {
      if (v.Tooltip.toLowerCase() == "best odds guaranteed") {
        v.ToolTipBoardPrice = v.Tooltip;
      } else if ((v.Tooltip == "")) {
        delete v.ToolTip;
        delete v.Tooltip;
      }
      v.Price1 = v.PriceLink1;
      v.Price2 = v.PriceLink2;
      v.JockeyName = "";
      delete v.Image;
    });

    data.Races = data.Events;
  }
  delete data.Events;
  return data;
}

export function GetQueryStringValue(key) {
  return decodeURIComponent(
    window.location.search.replace(
      new RegExp(
        "^(?:.*[&\\?]" +
        encodeURIComponent(key).replace(/[\\.\\+\\*]/g, "\\$&") +
        "(?:\\=([^&]*))?)?.*$",
        "i"
      ),
      "$1"
    )
  );
}

export function FilterEvents(events) {
  let eventName = GetQueryStringValue("eventName");
  if (typeof eventName != "undefined" && eventName.length > 0) {
    events = _.filter(events, function (o) {
      return o.EventName == eventName;
    });
  }
  return events;
}

export function GetMeetingTimeOfDay(hours, minutes) {
  const mm = `${minutes}`.padStart(2, '0')
  const hh = `${hours}`.padStart(2, '0')
  return `${hh}:${mm}`
}

// exanple
export function FormatDateTime(date, time, lineBreak = false) {
  return `${FormatDate(date)}${lineBreak ? '\n': ' '}${FormatTime(time)}`
}

// return date ot 'Today'
export function FormatDate(eventDate) {
  let rawDate = new Date(eventDate.split('-').reverse().join('-'))
  let today = new Date()
  if (rawDate.getDate()===today.getDate() && rawDate.getFullYear()===today.getFullYear() && rawDate.getMonth()===today.getMonth()){
    return 'Today'
  } else {
    return eventDate
  }
}

// Example '13:37:00' => '13:37'
export function FormatTime(time) {
  return time.split(':').slice(0,2).join(':');
}

export function IsEnablePlayEvents() {
  return localStorage.getItem("InPlay");
}
export function IsEnablelive_reload_for_inplay() {
  return localStorage.getItem("live_reload_for_inplay");
}

export function IsEnablelive_transaction_bog() {
  return localStorage.getItem("live_transaction_bog");
}

export function IsEnableAutoBalanceUpdate() {
  return localStorage.getItem("enable_auto_balance_update");
}

export function GetDefaultStream() {
  return localStorage.getItem("default_stream");
}

export function isRefreshOddsEnable() {
  return JSON.parse(localStorage.getItem("enable_refresh_odds"));
}

export function isPaypalDepositEnable() {
  return JSON.parse(localStorage.getItem("enable_paypal_deposit"));
}

export function isCacheApiProxyEnable() {
  return JSON.parse(localStorage.getItem("enable_cache_apiproxy"));
}

export function isOnlineDepositEnable() {
  return JSON.parse(localStorage.getItem("enable_online_deposit"));
}