<template>
<div class="sport-header d-flex align-items-center justify-content-center pe-3">
  <div
    class="d-flex justify-content-center align-items-center p-3 cursor-pointer position-absolute start-0"
    @click="backClick"
  >
    <img class="" :src="require('../../assets/to-left-arrow.svg')">
  </div>
  <div class="text-white text-capitalize fs-15 fw-500">{{title}}</div>
  <div></div>
</div>
</template>

<script>
export default {
  name: "SportHeader",
  props: ['title', 'toHome'],
  methods: {
    backClick() {
      if (this.toHome) {
      this.$router.push({name: 'home'})
    }
    else {
      this.$router.back()
    }
    }
  }
}
</script>

<style scoped>
.sport-header{
  position: fixed;
  height: 3.5rem;
  left: 0;
  right: 0;
  top: calc(0px + env(safe-area-inset-top));
  background-color: #09080C;
}
</style>