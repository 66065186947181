<template>
  <!-- <div v-if="!price || ['NO',''].indexOf(price)!==-1" class="blank"></div>
  <div v-else -->
    <div v-if="isHide && showEmptyBtn" class="blank"></div>
    <div v-else-if="isHide && !showEmptyBtn"></div>
    <div v-else
        ref="button"
        class="odd-btn fw-500 fs-16 cursor-pointer"
        :class="{
          'color': color,
          'selected': isSelected.length,
          'best-odds-guaranteed': bog,
          'hoverable': !$store.state.isMobile,
          'is-add-info': showAddInfo && race?.selOption
        }"
        @click="addBet"
    >
      <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span v-else  class="text-center">
        <span v-if="showAddInfo && race?.selOption" class="odd-add-info-val">{{ race.selOption }}</span>
        <span>{{glb.formatPrice(price)}}</span>
      </span>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import {authApiService, betslipApi} from "@/services/betslip/_services";
import {mapActions, mapGetters} from "vuex";

export default {
name: "OddBtnNormalize",
props: {
  color:{
    type: Boolean,
    default: false
  },
  bog:{
    type: Boolean,
    default: false
  },
  sp:{
    type: Boolean,
    default: false
  },
  race: {
    type: Object,
    required: true
  },
  selection:{
    type: Number,
  },
  showEmptyBtn: {
    type: Boolean,
    default: true
  },
  showAddInfo: {
    type: Boolean,
    default: true
  }
},
data:()=>{
  return{
    loading: false,
  }
},
setup(){
  const toast = useToast()
  return { toast }
},
computed:{
  ...mapGetters(['BETSLIP']),
  isSelected(){
    if (!this.BETSLIP) return false
    const vm = this
    let bets = []
    if ((this.race.code || this.race.eventGroup) && this.race.awayTeam){
      let eventGroup = parseInt(vm.race.eventGroup)
      let eventFileName = vm.race.eventFile
      if (vm.race.eventFileName){
        eventFileName = vm.race.eventFileName
      } else if (vm.race.code) {
        eventFileName = vm.race.code
      }
      let eventsNumber = vm.race.eventIdx? parseInt(vm.race.eventIdx) : 0
      bets = this.BETSLIP.bets.filter(b=>{
        return (b.eventFileName === eventFileName &&
        b.selectionNumber === vm.selectionIdx &&
        b.eventsNumber === eventsNumber && // eventIdx || 0
        b.eventGroup === eventGroup)
      })
    } else {
      bets = this.BETSLIP.bets.filter(b=>{
        return (b.eventFileName === vm.race.eventFile &&
        b.selectionNumber === parseInt(vm.race.selectionIdx) &&
        b.eventsNumber === parseInt(vm.race.eventIdx) &&
        b.eventGroup === parseInt(vm.race.eventGroup) &&
        ((b.priceCode==='S' && this.sp) || (b.priceCode!=='S' && !this.sp)))
      })
    }
    return bets
  },
  price(){
    if (this.sp) return  'SP'
    if (this.race.price) return this.race.price
    let price
    switch (this.selection) {
      case 0:
        price = this.race.firstodds?this.race.firstodds:this.race.homeodds
        break
      case 1:
        price = this.race.secondodds?this.race.secondodds:this.race.drawodds
        break
      case 2:
        price = this.race.thirdodds?this.race.thirdodds:this.race.awayodds
        break
    }
    return price
  },
  isHide() {
    return !this.price || ['NO',''].indexOf(this.price)!==-1
  }
},
methods:{
  ...mapActions(['SET_BETSLIP']),
  logout(){
    this.$store.dispatch('LOGOUT')
  },
  addBet(){
    const vm = this
    if (vm.loading || !vm.price || ['NO',''].indexOf(vm.price)!==-1) return
    vm.loading = true
    if (vm.isSelected.length){
      let bet = vm.isSelected[0]
      betslipApi(authApiService.logout).deleteBetFromBetslip(this.BETSLIP.id, bet.id, true)
        .then(data=>vm.SET_BETSLIP(data))
        .catch(err=>{
          vm.toast.error(err.toString())
          console.error(err)
        })
        .finally(()=>vm.loading = false)
    } else {
      let data = {}
      if (this.sp){
        data = {
            eventFile: this.race.eventFile,
            eventGroup: this.race.eventGroup,
            eventIdx: this.race.eventIdx,
            selectionIdx: this.race.selectionIdx,
            coi: this.race.coi,
            pricecode: "S",
            price: this.race.price,
        };
      } else {
        let price = this.race.homeodds || this.race.firstodds;
        let priceCode = this.race.homepricecode;
        let gPrice = this.race.home_gprice;
        if (this.selection === 1) {
            price = this.race.drawodds || this.race.secondodds;
            priceCode = this.race.drawpricecode;
            gPrice = this.race.draw_gprice;
        }
        if (this.selection === 2) {
            price = this.race.awayodds || this.race.thirdodds;
            priceCode = this.race.awaypricecode;
            gPrice = this.race.away_gprice;
        }
        // let eventId = this.race.EventId ? this.race.EventId : this.race.Id;
        let eventId = this.race.eventId ? this.race.eventId : this.race.Id; // ??
        // if (eventId == null || typeof eventId == "undefined") eventId = this.race.eventId; // Useless??
        // if (eventId == null || typeof eventId == "undefined")
        //     eventId = this.race.eventIdx;
        if (eventId == null || typeof eventId == "undefined") eventId = this.race.eventIdx;

        if ( this.race.selName || this.race.ALT) { // casediff
            if (typeof this.race.eventFile != "undefined") {
                gPrice = this.race.gPrice;
                gPrice = gPrice.substring(0, 1);
                data = {
                    eventFile: this.race.eventFile,
                    eventGroup: this.race.eventGroup,
                    eventIdx: this.race.eventIdx,
                    selectionIdx: this.selection,
                    pricecode: this.race.pricecode,
                    price: this.race.price,
                    gPricecode: this.race.gPricecode,
                    gPrice: gPrice,
                    coi: this.race.coi,
                };
            } else {
                gPrice = this.race.gPrice;
                gPrice = gPrice.substring(0, 1);
                data = {
                    eventFile: this.race.eventFile,
                    eventGroup: this.race.eventGroup,
                    eventIdx: this.race.eventIdx,
                    selectionIdx: this.selection,
                    pricecode: this.race.pricecode,
                    price: this.race.price,
                    gPricecode: this.race.gPricecode,
                    gPrice: gPrice,
                };
            }
        }
        else {
            if (eventId == null || undefined) {
                eventId = "0";
            }
            if (gPrice == null || undefined) {
                gPrice = "0";
            }
            data = {
                eventFile: this.race.code,
                eventGroup: this.race.eventGroup,
                eventIdx: "0",
                selectionIdx: this.selection,
                pricecode: priceCode,
                price: price,
                gPricecode: priceCode,
                gPrice: gPrice,
            };
        }
        //FIX EVENS
        if (data.price === "EVENS") data.price = "Evens"; // converts to 1-1 on the server side
      }
      betslipApi(this.logout)
          .addBetToBetslip(
              vm.convertOldFormattedBet(data),
              this.$store.state.betslipStore.backupId,
              true)
          .then(data=>{
            vm.SET_BETSLIP(data)
            if (this.$refs?.button?.getBoundingClientRect && this.isSelected.length){
              this.emitter.emit('odd-btn-click', this.$refs.button.getBoundingClientRect())
            }
          })
          .catch(err =>{
            vm.toast.error(err.toString())
            console.error(err)
          })
          .finally(() => {
            vm.loading = false
          })
    }
  },
  convertOldFormattedBet(oldFormattedBet) {
    if (!oldFormattedBet) {
      return null;
    }
    return {
      coi: oldFormattedBet.coi || "000",
      eventFileName: oldFormattedBet.eventFile,
      eventGroup: !isNaN(Number(oldFormattedBet.eventGroup)) ? Number(oldFormattedBet.eventGroup) : 0,
      eventsNumber: !isNaN(Number(oldFormattedBet.eventIdx)) ? Number(oldFormattedBet.eventIdx) : 0,
      gPrice: !isNaN(Number(oldFormattedBet.gPrice)) ? Number(oldFormattedBet.gPrice) : 0,
      gPriceCode: oldFormattedBet.gPricecode,
      isAvailable: false,
      isEachWay: false,
      price: oldFormattedBet.price,
      priceCode: oldFormattedBet.pricecode,
      selectionNumber: !isNaN(Number(oldFormattedBet.selectionIdx)) ? Number(oldFormattedBet.selectionIdx) : 0,
    };
  }
}
}
</script>

<style scoped lang="scss">
.odd-btn{
  /* width: 4rem; */
  width: 3.125rem;
  height: 3.125rem;
  /* padding: 0.5rem 0.25rem; */
  border-radius: 0.125rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: var(--geoff-bg-btn-1);
  gap: 0.125rem;

  .odd-add-info-val {
    color: var(--geoff-txt-note);
    font-size: 0.825rem;
    display: block;
    text-align: center;
  }
}

.match-odd {
  width: 4rem;
  height: 1.9rem;
}

.slider-odd {
  width: 100%;
  height: 2rem;
  line-height: 1.25;

  &.is-add-info {
    height: 2.5rem;
  }
}

.blank{
  width: 3.125rem;
  height: 100%;
}
.color{
  background: var(--geoff-bg-btn-2);
}
/* .best-odds-guaranteed{
  background: var(--geoff-accent);
  color: var(--geoff-txt-invert);
}

.best-odds-guaranteed.selected, */
.selected{
  /* color: #FFFFFF; */
  color: var(--geoff-text-main);

  background: var(--geoff-bg-btn-3);
  box-shadow: inset 0 0 0 1px var(--geoff-outline-1);
  font-weight: 700;
}
@media (pointer: fine) {
  .odd-btn:active,
  .odd-btn:hover{
    transition: 0.1s;
    background: #FFD60A;
  }
}

</style>