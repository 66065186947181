<template>
<div>
<div v-if="loading" class="h-100 bg-dark"><pre-loader/></div>
<div v-show="!loading">
<main-header/>
  <div class="geoff-bg-main" :class="$store.state.isMobile?'content':'content-desktop'">
    <div v-if="$store.state.isMobile" class="logo-container d-flex justify-content-center align-items-center">
      <div class="logo" :style="{backgroundImage: 'url('+require('../../assets/logo.svg')+')'}"></div>
    </div>
    <div class="d-flex justify-content-center">
      <log-in
        class="p-4 pb-5"
        style="width: 480px; min-width: 320px"
        @start-loading="loading=true"
        @finish-loading="loading=false"
      />
    </div>
  </div>
</div>
</div>
</template>

<script>
import MainHeader from "@/components/MainHeader";
import PreLoader from "@/components/UI/PreLoader";
import LogIn from "@/components/Auth/LogIn";

export default {
  name: "LoginView",
  components: {LogIn, PreLoader, MainHeader},
  data: ()=>{
    return{
      loading: false,
    }
  },
}
</script>

<style scoped>
.content{
  height: 100vh;
  width: 100vw;
  padding-top: 3.4rem;
  overflow: scroll;
}
.content-desktop{
  padding-top: 5rem;
}
.logo-container{
  height: 14rem;
  width: 100%;
  background-color: #09080C;
}
.logo{
  height: 5rem;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>