<template>
  <div class="chart-grid-container">
    
    <div class="chart-col">
      <div class="axis-y" :class="{'negative' : showNegative}">
        <!-- <div>{{ maxY }}</div>
        <div>{{ minY }}</div> -->
        <div class="axis-y-mark" v-for="(val, idx) in axisYData" :key="idx">{{ val }}</div>
      </div>
    </div>

    <div class="chart-col chart-scrollable">
      <div class="chart" :class="{'negative' : showNegative}">
        <div class="help-lines-y">
          <hr class="line-y" v-for="(val, idx) in axisYData" :key="idx">
          <!-- <div class="axis-y-mark" v-for="(val, idx) in axisYData" :key="idx">{{ val }}</div> -->
        </div>
        <!-- up container -->
          <div class="bar-container">
            <div 
              v-for="(barItem, idx) in barData"
              :key="idx"
              class="bar-item"
              :style="{height: `${barItem.isPositive ?  barItem.percent : 0}%`}"
            > 
            </div>
          </div>
          <!-- down container -->
          <div v-if="showNegative" class="bar-container negative">
            <div 
              v-for="(barItem, idx) in barData"
              :key="idx"
              class="bar-item"
              :style="{height: `${!barItem.isPositive ? barItem.percent : 0}%`}"
            >
            </div>
          </div>
      </div>

      <div class="axis-x">
        <div 
          v-for="(barItem, idx) in barData"
          :key="idx"
          class="bar-item"
        > {{ barItem.label }}
        </div>
      </div>
    </div>
    
  </div>

</template>

<script>
export default {
  name: "BarChart",
  props: {
    chartData: {
      type: Object,
      default: null,
      required: true
    },
    showNegative: {
      type: Boolean,
      default: false
    },
  },
  data: () => {
    return {}
  },
 
  computed: {
    barData() {
      return this.chartData.details.map(el => {
        return {
          label: el.dateLegend,
          value: el.amount,
          isPositive: el.amount >= 0,
          percent: (Math.abs(el.amount) / this.maxY) * 100
        }
      })
    },
    axisYData() {
      if (this.showNegative) {
        return [this.maxY, this.maxY/2,  0, this.minY/2, this.minY]
      }
      return [this.maxY, this.maxY/2,  0]
    },
    maxY () {
      let step = 1
      if (this.chartData?.amountMax > 100) {
        step = 10
      }
      if (this.chartData?.amountMax > 1000) {
        step = 100
      }
      return Math.ceil((this.chartData?.amountMax+1)/step)*step
    },
    minY () {
      return this.showNegative ? -this.maxY : 0 // Math.ceil(this.chartData?.amountMin/100)*100
    }

  }
}
</script>

<style scoped lang="scss">

.chart-grid-container {
  display: grid;
  grid-template-columns: auto 1fr;
  position: relative;

  .chart-col {
    display: grid;
    grid-template-rows: 1fr 1.25rem;

    &.chart-scrollable {
      overflow-x: scroll;
    }


    .axis-y {
      border-right: 1px solid white;
      min-width: 2rem;
      font-size: 0.75rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0.5rem 0 0;

      &.negative {
        padding: 0.5rem 0;
      }
      
      .axis-y-mark {
          position: relative;
          padding-right: 0.5rem;
      }
    }

    .axis-x {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      gap: 0.2rem;
      width: 100%;
      font-size: 0.75rem;
      text-align: center;
    }

    .chart {
      position: relative;
      padding: 0.5rem 0 0;

      &.negative {
        padding: 0.5rem 0;

        .help-lines-y {
          bottom: 0.5rem;
        }
      }

      .help-lines-y {
        position: absolute;
        top: 0.5rem;
        right: 0;
        left: -0.25rem;
        bottom: 0rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 1;

        .line-y {
          background-color: white;
          padding: 0;
          margin: 0;
          opacity: 0.25;
        }
      }
    }

  }

}

.bar-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0.2rem;
  height: 100%;
  height: 6.25rem;
  border-bottom: 1px solid white;
  position: relative;
  z-index: 2;

  &.negative {
    align-items: flex-start;
    padding: 0 0.25rem 0 0.25rem;
    border-bottom: unset;

    .bar-item {
      background-color: var(--geoff-accent-negative); //#008080 ;
      border-radius: 0 0 0.25rem 0.25rem;
    }

  }

  .bar-item {
    min-width: 1rem;
    width: 100%;
    min-height: 0px;
    background-color: var(--geoff-accent);
    border-radius: 0.25rem 0.25rem 0 0;
  }
  
}


</style>