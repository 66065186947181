<template>
<vue-modal ref="modal">
  <template v-slot:header>
    <div class="p-3 bg-dark fw-600 text-white text-center">
      20Back Claim
    </div>
  </template>
  <template v-slot:default>
    <div class="claim-container pt-3">
    <pre-loader v-if="loading"></pre-loader>
    <div v-else-if="claimExists" class="d-flex flex-column justify-content-between align-items-center">
      <div class="my-5 fs-18">20Back already claimed!</div>
      <vue-button cls="main" @click="$refs.modal.close">OK</vue-button>
    </div>
      <div v-else>
        <form @submit.prevent="submit">
          <VueInput
            label="Login"
            name="username"
            v-model="username"
            autocomplete="username"
          />
          <VueInput
            label="Password"
            name="password"
            type="password"
            v-model="password"
            autocomplete="current-password"
          />
          <div v-if="error" class="my-3 text-danger">{{error}}</div>
          <div class="d-flex mt-3">
          <vue-button class="me-1" @click="$refs.modal.close()">Cancel</vue-button>
          <vue-button class="ms-1" cls="main">Claim Credit</vue-button>
          </div>
        </form>
      </div>
    </div>
  </template>
</vue-modal>
</template>

<script>

import PreLoader from "@/components/UI/PreLoader";
import VueModal from "@/components/UI/VueModal";
import VueButton from "@/components/UI/VueButton";
import VueInput from "@/components/UI/VueInput";
export default {
  name: "BackClaimModal",
  components: {VueInput, VueButton, VueModal, PreLoader},
  data:()=>{
    return{
      loading: false,
      claimExists: false,
      error: null,
      data:null,

      username: null,
      password: null
    }
  },
  mounted() {
    this.checkClaim()
  },
  methods:{
    checkClaim(){
      const vm = this
      vm.loading = true
      vm.error = null
      vm.API.checkClaim(vm.$store.state.user.cust_Ref)
      .then(res=>{
        if(res.data.length){
          vm.claimExists = true
        }
        vm.data = res.data
      })
      .finally(()=>vm.loading=false)
    },
    submit(){
      const vm = this
      vm.error = null
      if (
          vm.username.toUpperCase() !== JSON.parse(localStorage.getItem('userCreds')).username.toUpperCase() ||
          vm.password !== JSON.parse(localStorage.getItem('userCreds')).password
      ) {
        vm.error = 'Username or password is not correct!'
        return
      }
      vm.loading = true
      const p1 = vm.API.postClaim({status: "published", username: vm.username})
      let formData = new FormData()
      formData.append("username", vm.username);
      formData.append("subject", '20back');
      const p2 = vm.API.sendEmailByClaim()
      Promise.all([p1,p2])
      .then()
      .catch(e=>{vm.error=e})
      .finally(()=>vm.loading=false)

    },
    open() {
      this.$refs.modal.open()
    },

    close() {
      this.$refs.modal.close()
    },
  }
}
</script>

<style scoped>
.claim-container{
  min-height: 10rem;
  max-width: 350px;
  transition: min-height 0.2s ease;
}
</style>