<template>
  <transition name="fade">
  <div v-if="loading" class="sidebar_preloader p-2">
    <div class="position-relative rounded-3">
      <pre-loader sm/></div>
    </div>
  </transition>
  <div v-show="!loading">
    <!-- League matches -->
    <div v-if="leagueOptions?.length" class="league-level  geoff-bg-section rounded-3 py-2 mb-2"> 
      <div class="fw-600 p-2 geoff-border-bottom">{{ leagueName }}</div>
      <div 
        v-for="(event, idx) in leagueOptions"
        :key="idx"
        class="p-2 geoff-border-bottom-separator cursor-pointer event-item"
        :class="{'active_category':eg === event.eventGroup}"
        @click="$router.push({
          name: 'matchEvent', 
          params: {
            sportName:sport.title, 
            ef: ef, 
            eg: event.eventGroup }, 
          query: {
            // title: event.name, //???
            homeParticipant: event.homeTeam,
            awayParticipant: event.awayTeam,
            ...( this.sport.code==='m' && event.secondPlayer && !['draw', 'vs', '@', 'tie'].includes(event.secondPlayer.toLowerCase()) ? {secondParticipant: event.secondPlayer,} : {}),
            date: `${formatDate(event.date)} ${event.kickoff}`}
          })"

        >
        <div>
          <div>{{ event.homeTeam }}</div> 
          <div v-if="sport.code==='m' && event.secondPlayer">{{ event.secondPlayer }}</div>
          <div>{{ event.awayTeam }}</div>
          <div  class="time-stamp">{{formatDate(event.date)}} {{ event.kickoff }}</div>
          <div v-if="event.fullCard?.length">
            <div class="match-results">
              <hr />
              <div>{{ event.fullCard[0]?.elRespose }}</div>
              <hr />
            </div>
            <div>
              <!-- buttons -->
              <div class="bets-wr" @click.stop>
                <odd-btn 
                  v-for="(subEvent, evIdx) in event.fullCard[0]?.events"
                  :key="evIdx"
                  :race="subEvent"
                  :selection="parseInt(subEvent.selection)"
                  :showEmptyButton="false"
                  class="slider-odd"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    Sport Options -->
    <div v-if="options.length && sport.type === 'race'" class="geoff-bg-section rounded-3 py-2 mb-2">
      <div class="fw-600 p-2 geoff-border-bottom">{{sport.title}} Options</div>
      <div
        v-for="(option, index) in options"
        :key="index"
        class="p-2 geoff-border-bottom-separator cursor-pointer"
        @click="selectSportOption(option)"
        :class="{'active_category':isActiveOption(option)}"
      >{{option}}</div>
    </div>

    <!-- Main Categories -->
    <div v-if="categories.length" class="geoff-bg-section rounded-3 py-2 mb-2">
      <div class="fw-600 p-2 geoff-border-bottom">{{sport.title}}</div>
      <div
        v-for="category in categories"
        :key="category.Id"
        class="p-2 geoff-border-bottom-separator cursor-pointer"
        @click="selectEventCategory(category)"
        :class="{'active_category':isActiveCategory(category)}"
      >
        <div>{{(category.EventName || category.eventName)?(category.EventName || category.eventName):category}}</div> <!--casediff-->
      </div>
    </div>

    <!--   AntePost Categories-->
    <div v-if="antepostCategories.length" class="geoff-bg-section rounded-3 py-2">
      <div v-for="category in antepostCategories" :key="category.EventName">
        <div class="fw-600 p-2 geoff-border-bottom">{{category.EventName}}</div> 
        <div
          v-for="ev in category.Meetings"
          :key="ev.EventCode"
          class="p-2 geoff-border-bottom-separator cursor-pointer"
          :class="{'active_category':isActiveAntepost(ev)}"
          @click="$router.push({name: 'antepostEvent', params:{sportName:sport.title, ef: ev.EventCode?ev.EventCode:v.EventFile, eg: ev.EventGroup}, query:{antepostTitle:ev.EventName?ev.EventName:ev.MeetinName}})"
        >
          <div>{{ev.EventName}}</div> 
        </div>
      </div>
    </div>
    <div v-else></div>

  </div>
</template>

<script>
import axios from "axios";
import PreLoader from "@/components/UI/PreLoader";
import OddBtn from "@/components/UI/OddBtn";

export default {
  name: "SideSportBar",
  components: {
    PreLoader,
    OddBtn,
  },
  emits: ['select-event-category'],
  props: ['sport'],
  data: ()=>{
    return{
      categories: [],
      options: [],
      antepostCategories: [],
      loading: false,

      // event params for league section
      ef: null,
      eg: null
    }
  },
  watch:{
    sport(){
      this.antepostCategories = []
      this.loadCategories()
    },
    $route: {
      handler(val, old) {
        if (val.name === 'sport' &&  old.name !== 'sport') {
          // console.log('DEBUG SIDEBAR $route', val.name, old.name)
          this.$nextTick(() => {
            this.loadCategories()
          })
        }
        this.initLeagueOptions(val.name)
      },
      deep: true
    },
    isShowLeagueMenu(val){
      if(val) {
        this.reInitReloadCategoriesInterval()
      }
      else {
        this.clearReloadCategoriesInterval()
      }
    },
    '$route.query.selectedOption'(val){
      if (val){
        this.categories = []
        this.loadCategories(true)
      }
      else {
        this.$nextTick(() => {
          this.loadCategories(true)
        })
      }
    },
    '$route.query.selectedCategoryEventName'(val){
      if (!val) {
        this.$nextTick(() => {
          this.loadCategories(true)
        })
      }
    }
    
  },
  mounted() {
    this.loadCategories(true)
    this.initLeagueOptions(this.$route.name)
  },
  beforeUnmount(){
    this.clearReloadCategoriesInterval()
  },
  computed: {
    leagueOptions() {
      return this.categories.find(el => el.code == this.ef)?.events
    },
    isShowLeagueMenu() {
      return !!this.leagueOptions?.length
    },
    leagueName() {
      return this.categories.find(el => el.code == this.ef)?.eventName
    }
  },
  methods:{
    initLeagueOptions(routeName) {
      if (routeName === 'matchEvent') {
        this.ef = this.$route.params.ef
        this.eg = this.$route.params.eg
      }
      else {
        this.ef = null
        this.eg = null
      }
    },
    loadCategories(setLoading=false){
      const vm = this
      // this.antepostCategories = []
      if (setLoading){
        vm.loading = true
      }
      let selectedSport = null
      if (vm.sport.subcategories && vm.$route.query.selectedOption){
        const selectedOptionName = vm.$route.query.selectedOption
        try {
          selectedSport = this.sport.subcategories.filter(subc => subc.title === selectedOptionName)[0]
        } catch (e) {
          console.log('select sport', e)
        }
      }
      const URL = selectedSport? selectedSport.url : vm.sport.url
      if (URL){

        let promise = this.sport.type === 'competition' ?
          this.API.getSportEventsList(this.sport.code, false) :
          axios.get(URL) // TODO: replace with races
        promise.then(response=>{
          if (this.sport.type === 'competition'){
            
            vm.categories = response.map(cat => {
              let category = cat
              category.events = []
              cat.competitions.map(comp => { // casediff
                comp.details.map(ev => {
                  let event = ev
                  event.date = comp.date
                  event.code = cat.code // casediff
                  event.eventName = comp.eventName // casediff

                  event.fullCard = event.fullCard.map( el => {
                    const parsed = this.API.ParseEventData(el)
                    return {
                      ...el,
                      fullCard: parsed
                    }
                  })

                  category.events.push(event)
                  return event
                })
              })
              return category
            })
          } else {
            vm.categories = response.data === '[{]}]' ? [] : response.data
          }
          if (vm.categories.length && vm.$route.name === 'sport' && !vm.$route.query.selectedCategoryEventName){
            vm.selectEventCategory(vm.categories[0])
          }
          vm.loading=false
        })
        .catch(error=>{console.log(error), vm.loading=false})
      if (vm.sport.antePostCode) {
        this.API.getAntepostEvents(vm.sport.antePostCode)
        .then(response=>{
          vm.antepostCategories = response.data
        })
        .catch(error=>{console.log(error)})
      }
      // else {
      //   vm.antepostCategories = []
      // }
      }
      if (this.sport.subcategories){
          this.options = this.sport.subcategories.map(sc=>sc.title)
          this.currentSportCode = this.sport.subcategories[0].code
      } else if (this.sport.url){
          this.options = ['Races']
      } else {
          this.options = []
      }
      if (this.sport.antePostCode){
          this.options = this.options.concat(['Futures'])
          this.loading = false
      }
      
      if (this.sport.type === 'race' && !this.$route.query.selectedOption){
        if (this.options.length && vm.$route.name === 'sport') this.selectSportOption(this.options[0])
      }
    },
    reInitReloadCategoriesInterval(requestImmediately = true) {
      if (requestImmediately) {
        this.loadCategories()
      }
      this.clearReloadCategoriesInterval()
      this.intervalLoadCategories = setInterval(this.loadCategories, 5000);
    },
    clearReloadCategoriesInterval() {
      if (this.intervalLoadCategories) clearInterval(this.intervalLoadCategories)
    },

    selectEventCategory(category){
      console.log('selectEventCategory', category)
      if (this.$route.params?.sportName === this.sport.title)
      this.$router.push({name: 'sport', params:{sportName: this.sport.title}, query:{selectedOption: this.$route.query.selectedOption, selectedCategoryEventName: (category.eventName || category.EventName)}}) // casediff
    },
    selectSportOption(option){
      console.log('selectSportOption', option)
      if (this.$route.params?.sportName === this.sport.title)
      this.$router.push({name: 'sport', params:{sportName: this.sport.title}, query:{selectedOption: option}})
    },
    isActiveCategory(category){
      return this.$route.query.selectedCategoryEventName === (category.eventName || category.EventName) // casediff
    },
    isActiveOption(option){
      return this.$route.query.selectedOption === option
    },
    isActiveAntepost(ev){
      return this.$route.query.antepostTitle === (ev.eventName || ev.EventName) // casediff
    },
    // move global?
    formatDate(eventDate){
      let rawDate = new Date(eventDate.split('-').reverse().join('-'))
      let today = new Date()
      if (rawDate.getDate()===today.getDate() && rawDate.getFullYear()===today.getFullYear() && rawDate.getMonth()===today.getMonth()){
        return 'Today'
      } else {
        return eventDate
      }
    },
  }
}
</script>

<style scoped lang="scss">
.sidebar_preloader{
  height: 10rem;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
}
.sidebar_preloader > div{
  width: inherit;
  height: inherit;
  /* background-color: #FFFFFF; */
}
.active_category{
  /* background-color: #f9f9f9; */
  // background-color: var(--geoff-bg-menu-item-active);
  box-shadow: inset 2px 0px 0 var(--geoff-accent);

  .slider-odd:not(.blank) {
    // outline: 1px solid var(--geoff-bg-btn-4);
    box-shadow: inset 0 0 0 1px var(--geoff-bg-btn-4);
    &.selected {
      box-shadow: inset 0 0 0 1px var(--geoff-outline-1);
    }
  }
  
}
.event-item {
  line-height: 1.25;
  font-size: 0.875rem;
  
  .time-stamp {
    font-size: .625rem;
    color: var(--geoff-item-timestamp);
  }
}

.league-level {
  min-width: 166px;
  
  .bets-wr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5rem;
  }
}

.match-results {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    grid-gap: 0.5rem;
    color: var(--geoff-item-timestamp);
    font-size: 0.75rem;
    hr {
      margin: 0.75rem 0;
    }
  }
</style>