<template>
  <div v-if="regions?.length">
    <div v-if="!isSingleRegion">
      <!-- Order by default -->
      <div>
        <div class="side-menu">
          <div class="link-item title text-ellipsies">All countries</div>
          <div v-for="(reg, idx) in regions" :key="idx">
            <div 
              class="link-item" 
              :class="{'active': $route.query.region === reg.countryName}"
              @click="toggleClick(reg)">
              <img  class="link-item-logo" :src="reg.countryFlagUrl" >
              <span class="text-ellipsies">{{ reg.countryName }}</span>
              <span>{{ reg.competitions.length }}</span>
              <svg  
                class="icon-expand"
                :class="{'opened': this.expanded.includes(reg.countryName)}"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 5.72376L10.862 0.861827L11.8048 1.80464L6.00005 7.60937L0.195312 1.80464L1.13812 0.861828L6.00005 5.72376Z" fill="currentColor"/>
                </svg>

            </div>
            <collapse-transition :duration="200">
            
            <div v-if="this.expanded.includes(reg.countryName)" class="sub-menu">
              <div 
                class="link-item no-img" 
                :class="{'active': $route.query.region === reg.countryName && $route.query.selectedCategoryEventName === 'All'}"
                v-if="reg.competitions.length > 1" @click="goToItem(reg)"
              >
                <span class="text-ellipsies">All {{ reg.countryName }}</span>
              </div>
              <div 
                class="link-item no-img"
                :class="{'active': $route.query.region === reg.countryName && $route.query.selectedCategoryEventName === lg.competitionName}"
                v-for="(lg, lgIdx) in reg.competitions"
                :key="lgIdx" 
                @click="goToItem(reg, lg)"
              >
                <span class="text-ellipsies">{{ lg.competitionName }}</span>
              </div>

            </div>
            </collapse-transition>
          </div>
        </div>
      </div>

      <!-- AZ regions -->
      <div v-if="!hideAZ">
        <div class="side-menu">
          <div class="link-item title">A-Z countries</div>
          <div v-for="(reg, idx) in azLeagues" :key="idx">
            <div 
              class="link-item" 
              :class="{'active': $route.query.region === reg.countryName}"
              @click="azToggleClick(reg)">
              <img class="link-item-logo" :src="reg.countryFlagUrl" >
              <span class="text-ellipsies">{{ reg.countryName }}</span>
              <span>{{ reg.competitions.length }}</span>
              <svg  
                class="icon-expand"
                :class="{'opened': this.azexpanded.includes(reg.countryName)}"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 5.72376L10.862 0.861827L11.8048 1.80464L6.00005 7.60937L0.195312 1.80464L1.13812 0.861828L6.00005 5.72376Z" fill="currentColor"/>
                </svg>

            </div>
            <collapse-transition :duration="200">
            
            <div v-if="this.azexpanded.includes(reg.countryName)" class="sub-menu">
              <div 
                class="link-item no-img" 
                :class="{'active': $route.query.region === reg.countryName && $route.query.selectedCategoryEventName === 'All'}"
                v-if="reg.competitions.length > 1" @click="goToItem(reg)"
              >
                <span class="text-ellipsies">All {{ reg.countryName }}</span>
              </div>
              <div 
                class="link-item no-img"
                :class="{'active': $route.query.region === reg.countryName && $route.query.selectedCategoryEventName === lg.competitionName}"
                v-for="(lg, lgIdx) in reg.competitions"
                :key="lgIdx" 
                @click="goToItem(reg, lg)"
              >
                <span class="text-ellipsies">{{ lg.competitionName }}</span>
              </div>

            </div>
          
            </collapse-transition>
          </div>
        </div>
      </div>
      
    </div>
    <div v-else  class="side-menu">
      <div 
          class="link-item no-img"
          :class="{'active': $route.query.region === regions[0].countryName && $route.query.selectedCategoryEventName === league.competitionName}"
          v-for="(league, idx) in regions[0].competitions"
          :key="idx" 
          @click="goToItem(regions[0], league)"
        >
          <span>{{ league.competitionName }}</span>
        </div>
    </div>
  </div>
</template>

<script>
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'

export default {
  name: "LeaguesTreeView",
  components: {
    CollapseTransition
  },
  props: {
    sport: {
      type: Object,
      required: true
    },
    hideAZ: {
      type: Boolean,
      default: false
    },
    setDefaultRegion: {
      type: Boolean,
      default: false
    }
  },
  data: ()=>{
    return{
      expanded: [],
      azexpanded: [],

      regionsData:[],
      regions: [],
    }
  },
  watch:{
    '$route.query'(val){
      if (val?.region && val?.selectedCategoryEventName) {
        this.expanded.push(val?.region)
      }
    }
  },
  mounted() {
    this.loadRegions()
  },
  beforeUnmount(){
  },
  computed: {
    azLeagues() {
      const az = [...this.regions]
      return az.sort((a, b) => {
          if(a.countryName < b.countryName) { return -1; }
          if(a.countryName > b.countryName) { return 1; }
          return 0;
      })
    },
    isSingleRegion() {
      return this.regions.length === 1
    }
   
  },
  methods:{
    loadRegions(){
      if (this.sport.url){
        this.API.getSportEventsListCountry(this.sport.code, false)
          .then(response=>{
            this.regions = response
            if (this.setDefaultRegion) {
              this.setDefaultSelect()
            }
          })
          .catch(error=>{
            console.log(error)
          })
      }
    },

    setDefaultSelect(){
      if (this.regions.length && this.$route.name === 'sport' && !this.$route.query.selectedCategoryEventName){
        this.selectEventCategory()

      }
    },
    selectEventCategory(){
      if (this.regions?.length) {
        if (this.$route.params?.sportName === this.sport.title) {
          this.$router.push({
            name: 'sport', 
            params:{
              sportName: this.sport.title
            },
            query:{
              selectedOption: this.$route.query.selectedOption,
              region: this.regions[0].countryName,
              selectedCategoryEventName: 'All' 
            }
          }) 
        }
      }
    },

    toggleClick(reg) {
      if (this.expanded.includes(reg.countryName)) {
        this.expanded = this.expanded.filter(el => el !== reg.countryName)
      }
      else {
        this.expanded.push(reg.countryName)
      }
    },
    azToggleClick(reg) {
      if (this.azexpanded.includes(reg.countryName)) {
        this.azexpanded = this.azexpanded.filter(el => el !== reg.countryName)
      }
      else {
        this.azexpanded.push(reg.countryName)
      }
    },
    goToItem(region, item = null) {
      this.$router.push({
        name: 'sport', 
        params:{
          sportName: this.sport.title}, 
          query:{
            region: region.countryName,
            selectedCategoryEventName: item ? item.competitionName : 'All'
          }
        })
      this.$emit('item-selected')
    }
  }
}
</script>

<style scoped lang="scss">
  .side-menu {
    font-size: 0.875rem;

    .link-item {
      display: grid;
      grid-template-columns: 1.5rem 1fr 1rem 1rem;
      grid-gap: 0.25rem;
      padding: 0 0.5rem;
      align-items: center;
      height: 2.5rem;
      border-top: 1px solid var(--geoff-menu-item-separator);
      cursor: pointer;

      @media (pointer: fine) {
        &:hover {
          background-color: var(--geoff-item-hover);
        }
      }

      &:first-child {
        border-top: none;
      }

      .link-item-logo {
        width: 1rem;
        border-radius: 0.5rem;
      }

      .icon-expand {
        transition: transform 0.2s;
          transform: rotate(-90deg);
        &.opened {
          transform: rotate(0deg);
        }
      }

      &.active{
        box-shadow: inset 2px 0px 0 var(--geoff-accent);
        background-color: var(--geoff-item-active);

        @media (pointer: fine) {
          &:hover {
            background-color: var(--geoff-item-hover);
          }
        }
      }

      &.no-img {
        grid-template-columns: 1fr 1rem;
        background-color: var(--geoff-item-lvl-2);

        &.active {
          background-color: var(--geoff-item-active);
        }

      }

      &.title {
        font-size: 1rem;
        font-weight: 700;
        grid-template-columns: 1fr 1rem;

      }
    }

  }
</style>