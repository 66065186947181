import * as odds from 'odds-converter';

const getValueAsFractional = (value) => {
    return value;
}

const getValueAsDecimal = (value) => {
    if (value && String(value).indexOf('-') !== -1) {
        const parts = String(value).split('-');
        if (!parts[1] || parts[1] === '0' || isNaN(Number(parts[0])) || isNaN(Number(parts[1]))) {
            return '0';
        }
        return odds.fraction
            .toDecimal(parts[0], parts[1])
            .toFixed(2);
    }
    return value;
}

export const getOddFormatted = (odd, oddsFormat) => {
    if (oddsFormat === 'decimal') {
        return getValueAsDecimal(odd);
    }
    return getValueAsFractional(odd);
};