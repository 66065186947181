<template>
<div class="sports-bar-wrap" :class="{'sports-bar-wrap-desktop': !$store.state.isMobile}" ref="tabs">
    <div class="sports-bar-scroll " :class="{'ps-2': !$store.state.isMobile}">
      <div
        v-if="$store.state.isMobile"
        class="sports-bar-scroll__item py-2 px-1"
        :class="{'active-sport': $route.name === 'promos'}"
      >
        <div class="d-flex justify-content-center cursor-pointer" @click="$router.push({name: 'promos'})">
          <!-- <div class="sports-bar-scroll__item_icon" :style="{backgroundImage: 'url('+ require('@/assets/sports-bar/award.svg') +')'}"></div> -->
          <!-- award.svg -->
          <div class="sports-bar-scroll__item_icon">
            <svg width="28" height="28" fill="currentColor" class="bi bi-award" viewBox="0 0 16 16">
              <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z"/>
              <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
            </svg>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div
            class="sports-bar-scroll__item_title fs-8 mt-2 text-uppercase text-center"
            :class="{'active': $route.name === 'promos'}"
          > Promos </div>
        </div>
      </div>
      <div
        v-for="sport in API.SPORTS"
        :key="sport.title"
        class="sports-bar-scroll__item py-2 px-1"
        :class="{'active-sport': sport.title===$route.params.sportName}"
      >
        <div class="d-flex justify-content-center cursor-pointer" @click="$router.push({name: 'sport', params:{sportName: sport.title}, query:{}})">
          <!-- <div class="sports-bar-scroll__item_icon" :style="{backgroundImage: 'url('+sport.img+')'}"></div> -->
          <div class="sports-bar-scroll__item_icon" v-html="sport.svg"></div>
        </div>
        <div class="d-flex justify-content-center">
          <div
            class="sports-bar-scroll__item_title fs-8 mt-2 text-uppercase text-center"
            :class="{'active': sport.title===$route.params.sportName}"
          >{{ sport.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SportsBar",
  props: ['currentSportTitle'],
  mounted() {
    if (this.$route.params.sportName){
      let selectedItem = document.getElementsByClassName('active-sport')[0]
      this.$refs.tabs.scrollLeft=selectedItem.offsetLeft - window.visualViewport.width / 2
    }
  }
}
</script>

<style scoped>
.sports-bar-wrap{
  position: absolute;
  left: 0;
  right: 0;
  /* top: calc(3.5rem + env(safe-area-inset-top)); */
  top: 3.5rem;
  height: 4rem;
  background-color: #09080C;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
  z-index: 100;
}
.sports-bar-wrap-desktop{
  position: fixed;
  top: calc(5rem + env(safe-area-inset-top));
  top: calc(var(--geoff-header-height) + env(safe-area-inset-top));
}
.sports-bar-scroll{
  display: flex;
  align-items: flex-start;
  width: max-content;
}
.sports-bar-scroll__item{
  max-width: 4rem;
  max-height: 3.8rem;
  min-width: 4rem;
}
.sports-bar-scroll__item_icon{
  height: 1.75rem;
  width: 1.75rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: var(--geoff-sport-bar);
}
.active-sport{
  background-color: rgba(255, 197, 73, 0.46);
  border-radius: 0.3rem;
}
.active-sport .sports-bar-scroll__item_title{
  /*color: rgb(222 189 22 / 78%)!important;*/
}
.active-sport .sports-bar-scroll__item_icon{
  transform: scale(1.15);
  transition: 0.2s;
}
.sports-bar-scroll__item_title{
  color: white;
  line-height: 0.5rem;
}
</style>